<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <m-ng-island [paddingSize]="24">
    <div class="section-title" i18n="@@admin.companies.company.image-generation-restricted-words">
      admin.companies.company.image-generation-restricted-words
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.companies.company.image-generation-restricted-words.ru">
        admin.companies.company.image-generation-restricted-words.ru
      </span>

      <m-textarea [rows]="25" [resizable]="true" [maxlength]="1_000_000_000" formControlName="ru" size="l" />
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.companies.company.image-generation-restricted-words.en">
        admin.companies.company.image-generation-restricted-words.en
      </span>

      <m-textarea [rows]="25" [resizable]="true" formControlName="en" size="l" />
    </div>

    <div class="row">
      <m-button
        button-style="primary"
        [is-disabled]="isDisabled()"
        (onClick)="onSubmit()"
        [is-disabled]="!form.valid"
        i18n="@@admin.common.save"
      >
        admin.common.save
      </m-button>
    </div>
  </m-ng-island>
</form>
