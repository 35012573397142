import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import {
  ImageGenerationMjAvailableDiscordServerSettings,
  ImageGenerationMjAvailableDiscordServerSettingsDto,
  ImageGenerationMjAvailableDiscordServerSettingsSaveData,
} from "../../models/image-generation-mj-available-discord-server";
import { AdminCompanyImageGenerationMjAvailableDiscordServerSettingsService } from "../../services/image-generation-mj-available-discord-server-settings/companies-image-generation-mj-available-discord-server-settings.service";
import { AdminCompanyImageGenerationMjAvailableDiscordServerSettingsStore } from "../../services/image-generation-mj-available-discord-server-settings/companies-image-generation-mj-available-discord-server-settings.store";

@Component({
  selector: "m-admin-add-image-generation-mj-available-discord-server-settings-modal",
  templateUrl: "./add-image-generation-mj-available-discord-server-settings.view.html",
  styleUrls: ["./add-image-generation-mj-available-discord-server-settings.view.scss"],
})
export class AddImageGenerationMjAvailableDiscordServerSettingsView implements OnInit, OnDestroy {
  protected activeSettings?: ImageGenerationMjAvailableDiscordServerSettings;

  @Output()
  add = new EventEmitter<ImageGenerationMjAvailableDiscordServerSettingsDto>();
  @Output()
  save = new EventEmitter<ImageGenerationMjAvailableDiscordServerSettingsSaveData>();
  @Output()
  close = new EventEmitter<void>();

  protected form?: FormGroup;

  private sub: Subscription = new Subscription();

  constructor(
    private readonly service: AdminCompanyImageGenerationMjAvailableDiscordServerSettingsService,
    private readonly store: AdminCompanyImageGenerationMjAvailableDiscordServerSettingsStore,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.updateFormValues();

    this.sub.add(
      this.store.getActiveAvailableDiscordServerSettingsObservable().subscribe((activeSettings) => {
        this.activeSettings = activeSettings;
        this.updateFormValues();
      }),
    );
  }

  ngOnDestroy(): void {
    this.store.setActiveAvailableDiscordServerSettings(undefined);
    this.sub.unsubscribe();
  }

  onClose() {
    this.store.setActiveAvailableDiscordServerSettings(undefined);
    this.close.emit();
  }

  createForm() {
    this.form = this.service.createSettingsForm();
  }

  updateFormValues() {
    this.form?.setValue({
      name: this.activeSettings?.name || "",
      discordServerId: this.activeSettings?.discordServerId || "",
      isAvailable: this.activeSettings?.isAvailable || false,
    });
  }

  createSettings() {
    const formData = this.form?.getRawValue();
    this.add.emit(formData);

    this.onClose();
  }

  saveSettings() {
    if (!this.activeSettings) {
      return;
    }

    const formData = this.form?.getRawValue();
    this.save.emit({
      id: this.activeSettings.id,
      data: formData,
    });

    this.onClose();
  }
}
