<div class="wrapper">
  <div class="section main">
    <div class="header-wrapper">
      <div class="caption">
        <span text-left i18n="@@cover-editor.object.create.header.caption">
          cover-editor.object.create.header.caption
        </span>
      </div>
      <div class="text" i18n="@@cover-editor.object.create.header.text">cover-editor.object.create.header.text</div>
    </div>

    <div class="buttons-wrapper">
      <div class="row" *ngFor="let coverSnippetCategoryObject of coverSnippetCategoryObjects">
        <m-cover-object-create-button
          [text]="getCoverSnippetCreateButtonTitle(coverSnippetCategoryObject.category)"
          [iconSrc]="getCoverSnippetCreateButtonIcon(coverSnippetCategoryObject.category)"
          [hasArrowIcon]="true"
          [hasNewMark]="hasCoverSnippetCreateButtonNewMark(coverSnippetCategoryObject.category)"
          (click)="toggleCoverSnippetsSidebar(coverSnippetCategoryObject.category)"
        />
      </div>

      <div class="row" *ngIf="isImagesGenerationAvailable">
        <m-cover-object-create-button
          text="cover-editor.object.create.button.image-ai"
          i18n-text="@@cover-editor.object.create.button.image-ai"
          iconSrc="image-05.svg"
          [hasNewMark]="true"
          (click)="showImageGenerator()"
        />
      </div>
      <div class="row">
        <m-cover-object-create-button
          text="cover-editor.object.create.button.image-upload"
          i18n-text="@@cover-editor.object.create.button.image-upload"
          iconSrc="image-06.svg"
          (click)="fileUpload.openFileSelector()"
        />
      </div>

      @if (user?.isAdmin) {
        <!-- <div class="row">
          <m-button button-style="primary" size="full-width" (click)="migrateSnippets()">
            <span>Migrate objects. SINGLE USE</span>
          </m-button>
        </div> -->

        <div class="row">
          <div class="column">
            <form *ngIf="formCoverConceptualStep2" [formGroup]="formCoverConceptualStep2" class="form-content">
              <m-select
                class="select"
                input-style="normal"
                size="s"
                [options]="getGenreOptions()"
                formControlName="genre"
              />
            </form>

            <m-button button-style="primary" size="full-width" (click)="onGenerateCoverConceptTest()">
              <span>Test concept</span>
            </m-button>
          </div>
        </div>
      }
    </div>
  </div>
  <div class="section bottom">
    <m-button
      text-align="space-between"
      icon-position="right"
      icon="chevron-right-01.svg"
      button-style="secondary"
      size="full-width"
      i18n="@@cover-editor.object.create.button.templates"
      (onClick)="showTemplatesHandle()"
    >
      cover-editor.object.create.button.templates
    </m-button>
  </div>

  <m-cover-conceptual-assistant-generation (showConceptualAssistantMenu)="onShowConceptualAssistantMenu()" />

  @if (isCoverSnippetsOverlayVisible) {
    <m-cover-snippets-sidebar
      clickoutsidedetector
      [selectedCoverSnippetCategoryObjects]="selectedCoverSnippetCategoryObjects"
      [selectedCoverSnippetSubcategoryObjects]="selectedCoverSnippetSubcategoryObjects"
      [coverSnippetSidebarWidth]="coverSnippetSidebarWidth"
      [scrollPosition]="scrollPosition"
      [predefinedSvgObjects]="predefinedSvgObjects"
      (createCoverObject)="onCreateCoverObject($event)"
      (setSubcategoryObjects)="setCoverSnippetSubcategoryObjects($event)"
      (scroll)="onScroll($event)"
      (clickOutside)="clickOutsideCoverSnippetsSidebar($event)"
    />
  }

  <div class="image-generator-host" *ngIf="isImageGeneratorVisible">
    <m-image-generation-view
      [isModal]="true"
      [imageSize]="imageSize"
      (onClose)="hideImageGenerator()"
      (selectImage)="selectGeneratedImage($event)"
    />
  </div>
</div>

<m-file-drop #fileUpload [accept]="imageFileTypes" [multiple]="false" (dropped)="uploadImage($event)"></m-file-drop>
