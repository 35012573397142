import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { CompanyImageGenerationRestrictedWordsSettings } from "../../models/company";
import { AdminCompanyImageGenerationRestrictedWordsSettingsApi } from "./companies-image-generation-restricted-words-settings.api";
import { AdminCompanyImageGenerationRestrictedWordsSettingsStore } from "./companies-image-generation-restricted-words-settings.store";

export type UpdateCompanySettingsResult = {
  status: GeneralResultStatus;
  error?: unknown;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyImageGenerationRestrictedWordsSettingsService {
  constructor(
    private readonly api: AdminCompanyImageGenerationRestrictedWordsSettingsApi,
    private readonly store: AdminCompanyImageGenerationRestrictedWordsSettingsStore,
  ) {}

  async getImageGenerationRestrictedWordsSettings() {
    return await this.api.getImageGenerationRestrictedWordsSettings();
  }

  async loadImageGenerationRestrictedWordsSettings() {
    const settings = await this.api.getImageGenerationRestrictedWordsSettings();
    this.store.setRestrictedWords(settings);
  }

  async updateImageGenerationRestrictedWordsSettings(
    data: CompanyImageGenerationRestrictedWordsSettings,
  ): Promise<UpdateCompanySettingsResult> {
    try {
      const settings = await this.api.updateImageGenerationRestrictedWordsSettings(data);
      this.store.setRestrictedWords(settings);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }
}
