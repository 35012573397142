<div class="wrapper">
  <div *ngIf="!currentObject.isBackgroundRemoved" class="section">
    <m-cover-sidebar-section
      title="cover-editor.image.remove-background"
      i18n-title="@@cover-editor.image.remove-background"
      [hasTooltip]="true"
    >
      <ng-container tooltip>
        <div class="tooltip-group">
          <span i18n="@@cover-editor.image.remove-background.info">cover-editor.image.remove-background.info</span>
        </div>
      </ng-container>

      <m-button
        button-style="secondary"
        [is-disabled]="isRemoveBackgroundDisable()"
        size="full-width"
        (onClick)="onRemoveBackground()"
      >
        <span i18n="@@image-generation.generation.nobg.button-text">image-generation.generation.nobg.button-text</span>
        <div class="price">
          <m-ng-icon class="token-icon" src="/assets/img/app-bar-menu/token-simple-{theme}.svg" [size]="20" />
          <span *ngIf="prices">{{ getPrice('nobg') }}</span>
        </div>
      </m-button>
    </m-cover-sidebar-section>
  </div>

  <div *ngIf="isUpscaleAvailable()" class="section">
    <m-cover-sidebar-section
      title="cover-editor.image.upscale"
      i18n-title="@@cover-editor.image.upscale"
      [hasTooltip]="true"
    >
      <ng-container tooltip>
        <div class="tooltip-group">
          <span i18n="@@cover-editor.image.upscale.info">cover-editor.image.upscale.info</span>
        </div>
      </ng-container>

      <form [formGroup]="formUpscale" class="upscale-settings">
        @if (!currentObject?.imageGenerationStylizationId && !currentObject?.generatedImageStylizationId) {
          <m-slider-selector formControlName="creativityStrength" [points]="getUpscaleStrengthPoints()" />
        }

        <div class="button-container">
          <m-button
            button-style="secondary"
            [is-disabled]="isUpscaleDisable()"
            [show-premium-mark]="!hasPremium"
            size="full-width"
            (onClick)="onUpscale()"
          >
            <span i18n="@@image-generation.generation.upscale.button-text">
              image-generation.generation.upscale.button-text
            </span>
            <div *ngIf="prices" class="price">
              <m-ng-icon class="token-icon" src="/assets/img/app-bar-menu/token-simple-{theme}.svg" [size]="20" />
              <span>{{ getPrice('upscale-book-cover-image') }}</span>
            </div>
          </m-button>
        </div>

        <m-info-block type="warning" [labels]="getUpscaleErrors()" />
      </form>
    </m-cover-sidebar-section>
  </div>
</div>
