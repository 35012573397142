import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CompanyStore } from "@metranpage/company";
import { InfoBlockData } from "@metranpage/components";
import { AnalyticsService, LoadingService, NotificationsPopUpService, filterUndefined } from "@metranpage/core";
import { I18nService } from "@metranpage/i18n";
import {
  ActiveSubscription,
  PaymentCurrency,
  PaymentMethodDataDto,
  PaymentMethodType,
  PromocodeDiscountData,
  PromocodeEnterResult,
  Tariff,
} from "@metranpage/pricing-data";
import { User, UserStore } from "@metranpage/user-data";
import { DateTime } from "luxon";
import { Subscription, debounceTime, distinctUntilChanged } from "rxjs";
import { PricingService } from "../../services/pricing.service";
import { PaymentMethodSelectValue } from "../payment-method-select/payment-method-select.view";
import { PricingViewService } from "../pricing.view-service";

@Component({
  selector: "m-payment-confirmation-modal",
  templateUrl: "./payment-confirmation-modal.view.html",
  styleUrls: ["./payment-confirmation-modal.view.scss"],
})
export class PaymentConfirmationModalView implements OnInit, OnDestroy {
  @Input()
  promocodeDiscountData?: PromocodeDiscountData;
  @Input()
  currency: PaymentCurrency = "RUB";
  @Input()
  tariff!: Tariff;

  @Output()
  close = new EventEmitter();

  protected currentBalance = 0;
  protected newBalance = 0;
  protected nextPaymentDate = "";

  protected user?: User;
  protected activeSubscription?: ActiveSubscription;

  protected paymentMethods: PaymentMethodDataDto[] = [];
  protected paymentMethodsOptions: PaymentMethodSelectValue[] = [];

  protected locale = "en";

  protected paymentMethodForm!: FormGroup;
  protected promocodeForm!: FormGroup;

  protected isPromocodeChanged = true;
  protected promocodeErrors: InfoBlockData[] = [];

  protected priceWithoutDiscount?: string;

  private sub = new Subscription();

  constructor(
    private userStore: UserStore,
    private readonly pricingViewService: PricingViewService,
    private readonly analytics: AnalyticsService,
    private readonly cdr: ChangeDetectorRef,
    readonly i18nService: I18nService,
    private readonly loadingService: LoadingService,
    readonly companyStore: CompanyStore,
    private readonly pricingService: PricingService,
    private readonly notificationsService: NotificationsPopUpService,
  ) {
    this.locale = i18nService.getLocale();

    this.sub.add(
      this.userStore
        .getUserObservable()
        .pipe(filterUndefined())
        .subscribe((user) => {
          this.user = user;
        }),
    );

    this.sub.add(
      this.userStore
        .getActiveSubscriptionObservable()
        .pipe(filterUndefined())
        .subscribe((activeSubscription) => {
          this.activeSubscription = activeSubscription;
        }),
    );

    this.sub.add(
      this.pricingService.payEvent$.subscribe(() => {
        this.closeModal();
      }),
    );
  }

  ngOnInit() {
    this.init();
    this.listenPromocodeEvents();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private async init() {
    this.analytics.event("payment-confirmation-modal");

    this.createPaymentMethodForm();
    this.createPromocodeForm();

    this.currentBalance = this.user?.credits || 0;
    this.newBalance = this.currentBalance + this.tariff.creditsPerMonth * this.tariff.period;
    this.constructDiscountStrings();
    this.updateNextPaymentDate();

    this.loadingService.startLoading({ fullPage: true });

    await this.loadPaymentMethods();

    this.loadingService.stopLoading();
  }

  async deleteLastActiveUserPromocode() {
    this.loadingService.startLoading({ fullPage: true });
    await this.pricingService.deleteLastActiveUserPromocode();
    this.loadingService.stopLoading();
  }

  private createPaymentMethodForm(): void {
    this.paymentMethodForm = new FormGroup({
      card: new FormControl<number | null | undefined>(this.user?.paymentCardId, {
        validators: [],
      }),
    });
  }

  protected async applyPromocode() {
    if (!this.isPromocodeChanged) {
      return;
    }

    if (!this.promocodeForm.valid) {
      this.checkPromocodeFormErrors();
      return;
    }

    this.isPromocodeChanged = false;

    const promocode = this.promocodeForm.get("promocode")?.value.trim();

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.pricingService.activatePromocode(promocode);
    this.loadingService.stopLoading();

    this.updatePromocodeResults(result);

    this.analytics.event("promocode", { promocode, status: result.status, description: result.description });

    this.cdr.detectChanges();
  }

  protected async pay() {
    const cardId = this.paymentMethodForm.get("card")?.value;
    const paymentMethod = this.paymentMethods.find((pm) => pm.id === cardId);

    this.loadingService.startLoading({ fullPage: true });
    if (paymentMethod) {
      await this.pricingService.selectPaymentMethod(paymentMethod);
    } else {
      await this.pricingService.resetPaymentMethod();
    }
    this.loadingService.stopLoading();

    window.open(`payments/await-payment-link?tariffId=${this.tariff.id}`, "_blank");

    this.pricingService.onPay();
  }

  private async loadPaymentMethods() {
    this.paymentMethods = await this.pricingService.getPaymentMethods();
    this.updatePaymentMethodOptions();
  }

  private createPromocodeForm(): void {
    this.promocodeForm = new FormGroup({
      promocode: new FormControl<string>("", {
        nonNullable: false,
        validators: [Validators.required],
      }),
    });

    this.sub.add(
      this.promocodeForm.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((v) => {
        this.isPromocodeChanged = true;
        this.promocodeErrors = [];
        this.cdr.detectChanges();
      }),
    );
  }

  private listenPromocodeEvents() {
    this.sub.add(
      this.pricingService.promocodeEvent$.subscribe((promocodeEvent) => {
        this.updatePromocodeResults(promocodeEvent);
        this.isPromocodeChanged = true;
        this.cdr.detectChanges();
      }),
    );

    this.sub.add(
      this.pricingService.promocodeDiscountDataEvent$.subscribe((promocodeDiscountDataUpdate) => {
        this.isPromocodeChanged = true;
        this.constructDiscountStrings();
        this.cdr.detectChanges();
      }),
    );
  }

  private updatePromocodeResults(promocodeResult: PromocodeEnterResult | undefined) {
    this.promocodeErrors = [];

    if (promocodeResult?.status && promocodeResult?.status !== "success") {
      this.promocodeErrors.push({
        textData: [{ text: this.pricingService.getPromocodeResultText(promocodeResult) }],
      });

      this.promocodeForm.controls["promocode"].setErrors({ incorrect: true });

      return;
    }

    if (promocodeResult?.status && promocodeResult?.status === "success") {
      this.notificationsService.notify({
        type: "success",
        content: $localize`:@@pricing.promocode.activation.success:`,
      });
    }
  }

  private checkPromocodeFormErrors() {
    this.markInvalidPromocodeControlAsDirty();
    this.updatePromocodeErrors();
  }

  private markInvalidPromocodeControlAsDirty(): void {
    const controls = this.promocodeForm?.controls;
    for (const k in controls) {
      const control = controls[k];
      if (!control.valid) {
        control.markAsDirty();
      }
    }
  }

  protected updatePromocodeErrors() {
    this.promocodeErrors = [];

    for (const controlName of Object.keys(this.promocodeForm.controls)) {
      const controlErrors = this.promocodeForm.get(controlName)?.errors;
      if (!controlErrors) {
        continue;
      }
      const processedErrors: { [key: string]: string[] } = {};

      for (const error of Object.keys(controlErrors)) {
        if (!processedErrors[controlName]) {
          processedErrors[controlName] = [];
        }

        if (controlName === "promocode" && error === "required") {
          this.promocodeErrors.push({
            textData: [{ text: $localize`:@@pricing.promocode.activation.error.empty-field:` }],
          });
          processedErrors[controlName].push(error);
        }
      }
    }
  }

  protected getPrice() {
    let price = this.tariff.price;

    const currencySymbol = this.pricingViewService.getCurrencySymbol(this.currency);

    if (this.promocodeDiscountData) {
      const priceWithoutDiscount = this.tariff.price + this.tariff.profit;
      if (priceWithoutDiscount === 0) {
        return this.pricingViewService.priceFormat(priceWithoutDiscount, currencySymbol);
      }
      const discountPercent = 100 * (1 - this.tariff.price / priceWithoutDiscount);
      price =
        priceWithoutDiscount - (priceWithoutDiscount * (discountPercent + this.promocodeDiscountData.discount)) / 100;
    }
    return this.pricingViewService.priceFormat(price, currencySymbol);
  }

  protected constructDiscountStrings(): void {
    const priceWithoutDiscount = this.tariff.price + this.tariff.profit;

    const currencySymbol = this.pricingViewService.getCurrencySymbol(this.currency);

    if (priceWithoutDiscount === 0) {
      return;
    }

    this.priceWithoutDiscount = this.pricingViewService.priceFormat(priceWithoutDiscount, currencySymbol);
  }

  private updateNextPaymentDate() {
    const period = this.tariff.period;
    const dt = DateTime.now();
    this.nextPaymentDate = dt
      .plus({ months: period })
      .toLocaleString({ year: "numeric", month: "long", day: "2-digit" }, { locale: this.locale });
  }

  protected updatePaymentMethodOptions() {
    this.paymentMethodsOptions = [];
    for (const pm of this.paymentMethods) {
      this.paymentMethodsOptions.push({
        id: pm.id,
        value: `${pm.cardNumber}`,
        icon: this.getPaymentMethodTypeIcon(pm.type, pm.cardBrand),
      });
    }
    this.cdr.markForCheck();
  }

  protected getPaymentMethodTypeIcon(type: PaymentMethodType, cardBrand: string | undefined) {
    if (type === "card" && cardBrand) {
      return `/assets/img/cards/${cardBrand.toLowerCase()}.svg`;
    }
    return `/assets/img/cards/${type.toLowerCase()}.svg`;
  }

  protected hasPromocodeDiscount() {
    return this.tariff.price > 0 && !this.tariff.isFree && this.promocodeDiscountData;
  }

  protected onCloseClick() {
    this.analytics.event("payment-confirmation-modal-close");

    this.closeModal();
  }

  protected closeModal() {
    this.close.emit();
  }
}
