import { Injectable } from "@angular/core";
import { FabulaCreateGenerationResultDataDto, FabulaRemoveBackgroundDataDto } from "../../models/fabula/fabula";
import { FabulaImageGenerationApi } from "./fabula-image-generation.api";

export type GeneralResultStatus = "success" | "error";

@Injectable({
  providedIn: "root",
})
export class FabulaImageGenerationService {
  constructor(
    private readonly fabulaImageGenerationApi: FabulaImageGenerationApi,
    // realtimeService: RealtimeService,
  ) {}

  async removeBackground(data: FabulaRemoveBackgroundDataDto): Promise<FabulaCreateGenerationResultDataDto> {
    try {
      return await this.fabulaImageGenerationApi.removeBackground(data);
    } catch (error) {
      return { status: "error" };
    }
  }
}
