<m-modal position="center" (close)="onClose()">
  <form *ngIf="form" [formGroup]="form">
    <span class="title" i18n="@@admin.mj-available-discord-server-settings.add.title">
      admin.mj-available-discord-server-settings.add.title
    </span>

    <div class="controls">
      <div class="control">
        <span class="label" i18n="@@admin.mj-available-discord-server-settings.name">
          admin.mj-available-discord-server-settings.name
        </span>
        <m-input formControlName="name" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.mj-available-discord-server-settings.discord-server-id">
          admin.mj-available-discord-server-settings.discord-server-id
        </span>
        <m-input formControlName="discordServerId" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.mj-available-discord-server-settings.is-available">
          admin.mj-available-discord-server-settings.is-available
        </span>
        <m-switch formControlName="isAvailable" input-style="on-panel" />
      </div>
    </div>

    <div class="buttons">
      <m-button button-style="secondary" (onClick)="onClose()" i18n="@@admin.common.cancel">
        admin.common.cancel
      </m-button>

      @if (activeSettings) {
      <m-button
        button-style="primary"
        [is-disabled]="!form.valid"
        (onClick)="saveSettings()"
        i18n="@@admin.common.save"
      >
        admin.common.save
      </m-button>
      } @else {
      <m-button
        button-style="primary"
        [is-disabled]="!form.valid"
        (onClick)="createSettings()"
        i18n="@@admin.common.create"
      >
        admin.common.create
      </m-button>
      }
    </div>
  </form>
</m-modal>
