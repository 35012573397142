import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GeneralResultStatus } from "@metranpage/core-data";
import { ImageGenerationMjAvailableDiscordServerSettingsDto } from "../../models/image-generation-mj-available-discord-server";
import { AdminCompanyImageGenerationMjAvailableDiscordServerSettingsApi } from "./companies-image-generation-mj-available-discord-server-settings.api";
import { AdminCompanyImageGenerationMjAvailableDiscordServerSettingsStore } from "./companies-image-generation-mj-available-discord-server-settings.store";

export type UpdateCompanySettingsResult = {
  status: GeneralResultStatus;
  error?: unknown;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyImageGenerationMjAvailableDiscordServerSettingsService {
  constructor(
    private readonly api: AdminCompanyImageGenerationMjAvailableDiscordServerSettingsApi,
    private readonly store: AdminCompanyImageGenerationMjAvailableDiscordServerSettingsStore,
  ) {}

  async getImageGenerationMjAvailableDiscordServerSettings() {
    return await this.api.getImageGenerationMjAvailableDiscordServerSettings();
  }

  async loadImageGenerationMjAvailableDiscordServerSettings() {
    const settings = await this.api.getImageGenerationMjAvailableDiscordServerSettings();
    this.store.setAvailableDiscordServerSettings(settings);
  }

  async createImageGenerationMjAvailableDiscordServerSettings(
    data: ImageGenerationMjAvailableDiscordServerSettingsDto,
  ): Promise<UpdateCompanySettingsResult> {
    try {
      const settings = await this.api.createImageGenerationMjAvailableDiscordServerSettings(data);
      this.store.updateSettings(settings);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }

  async updateImageGenerationMjAvailableDiscordServerSettings(
    id: number,
    data: ImageGenerationMjAvailableDiscordServerSettingsDto,
  ): Promise<UpdateCompanySettingsResult> {
    try {
      const settings = await this.api.updateImageGenerationMjAvailableDiscordServerSettings(id, data);
      this.store.updateSettings(settings);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }

  async deleteImageGenerationMjAvailableDiscordServerSettings(id: number): Promise<UpdateCompanySettingsResult> {
    try {
      await this.api.deleteImageGenerationMjAvailableDiscordServerSettings(id);
      this.store.deleteSettings(id);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }

  createSettingsForm() {
    return new FormGroup(
      {
        name: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
        discordServerId: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
        isAvailable: new FormControl(false, { nonNullable: true, validators: [Validators.required] }),
      },
      [],
    );
  }
}
