import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AnalyticsService, FileDropComponent, LoadingService, NotificationsPopUpService } from "@metranpage/core";
import { User } from "@metranpage/user-data";
import { NgxFileDropEntry } from "ngx-file-drop";
import { Subscription } from "rxjs";
import { ImageGenerationStylizationUserStyle } from "../../models/image-generation-stylization-user-style/image-generation-stylization-user-style";
import { ImageGenerationStylizationUserStyleService } from "../../services/image-generation-stylization-user-style/image-generation-stylization-user-style.service";
import { ImageGenerationStylizationUserStyleStore } from "../../services/image-generation-stylization-user-style/image-generation-stylization-user-style.store";

const reFileExtensions = /(\.)(jpg|jpeg|jfif|pjpeg|pjp|png)$/gm;

@Component({
  selector: "m-stylization-user-style-upload-modal",
  templateUrl: "./stylization-user-style-upload-modal.view.html",
  styleUrls: ["./stylization-user-style-upload-modal.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StylizationUserStyleUploadModalView implements OnInit, OnDestroy {
  @Input()
  user?: User;
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;

  @Output()
  onSelect = new EventEmitter<ImageGenerationStylizationUserStyle>();
  @Output()
  onClose = new EventEmitter<void>();
  @Output()
  onDeleteStyle = new EventEmitter<number>();

  @ViewChild("filedrop", { static: false })
  protected fileDropComponent!: FileDropComponent;

  protected stylizationUserStylesPage = 1;
  protected stylizationUserStylesPageCount = 1;

  protected stylizationUserStyles: ImageGenerationStylizationUserStyle[] = [];

  protected stylizationStyleForDelete?: ImageGenerationStylizationUserStyle;

  sub: Subscription = new Subscription();

  constructor(
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly imageGenerationStylizationUserStyleService: ImageGenerationStylizationUserStyleService,
    private readonly imageGenerationStylizationUserStyleStore: ImageGenerationStylizationUserStyleStore,
    private readonly analytics: AnalyticsService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.listenStylizationUserStyleChanges();
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected listenStylizationUserStyleChanges() {
    this.sub.add(
      this.imageGenerationStylizationUserStyleStore.getStylizationUserStylesObservable().subscribe((items) => {
        this.stylizationUserStyles = items.filter((i) => !i.deletedAt);
        this.cdr.markForCheck();
      }),
    );

    this.sub.add(
      this.imageGenerationStylizationUserStyleStore
        .getStylizationUserStylesPageCountObservable()
        .subscribe((pageCount) => {
          this.stylizationUserStylesPageCount = pageCount;
        }),
    );
  }

  protected async loadData() {
    await this.loadStylizationUserStyles();
  }

  protected async loadStylizationUserStyles() {
    if (this.stylizationUserStylesPage > this.stylizationUserStylesPageCount) {
      return;
    }
    await this.imageGenerationStylizationUserStyleService.loadStylizationUserStylesPaginated(
      this.stylizationUserStylesPage,
    );
  }

  protected selectStylizationUserStyle(imageGenerationStylizationUserStyle: ImageGenerationStylizationUserStyle) {
    this.onSelect.emit(imageGenerationStylizationUserStyle);
  }

  protected showConfiramtionDeleteModal(imageGenerationStylizationUserStyle: ImageGenerationStylizationUserStyle) {
    this.stylizationStyleForDelete = imageGenerationStylizationUserStyle;
  }

  protected closeDeleteStylizationUserStyleModal() {
    this.stylizationStyleForDelete = undefined;
  }

  protected async deleteStylizationUserStyle() {
    if (!this.stylizationStyleForDelete) {
      return;
    }

    this.notificationService.closeAll();
    this.loadingService.startLoading({
      fullPage: true,
    });
    const result = await this.imageGenerationStylizationUserStyleService.deleteStylizationUserStyle(
      this.stylizationStyleForDelete.id,
    );
    this.loadingService.stopLoading();
    if (result !== "success") {
      // this.notificationService.error($localize`:@@cover-editor.stylization.upload.request.error:`);
      return;
    }

    const id = this.stylizationStyleForDelete.id;
    this.stylizationStyleForDelete = undefined;
    this.imageGenerationStylizationUserStyleStore.deleteStylizationUserStyle(id);

    await this.checkRemainingItems();

    this.onDeleteStyle.emit(id);

    this.closeDeleteStylizationUserStyleModal();
  }

  protected async createCharacterReference(file: File) {
    this.analytics.event("create-user-stylization-style-reference");

    this.notificationService.closeAll();
    this.loadingService.startLoading({
      fullPage: true,
      description: $localize`:@@cover-editor.stylization.upload.process-hint:`,
    });
    const result = await this.imageGenerationStylizationUserStyleService.createStylizationUserStyle(file);
    this.loadingService.stopLoading();
    if (result?.error) {
      if (result.error === "file-limit-error") {
        this.notificationService.error($localize`:@@cover-editor.stylization.upload.request.error.file-limit:`);
        return;
      }
      this.notificationService.error($localize`:@@cover-editor.stylization.upload.request.error:`);
      return;
    }
    this.notificationService.notify({
      content: $localize`:@@cover-editor.stylization.upload.request.success:`,
      type: "success",
    });
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: File) => {
          if (!file.name.match(reFileExtensions)) {
            this.notificationService.error($localize`:@@cover-editor.stylization.upload.request.error.file-extension:`);
            return;
          }

          this.createCharacterReference(file);
        });
      }
    }
  }

  openFileSelector() {
    this.fileDropComponent.openFileSelector();
  }

  private async checkRemainingItems() {
    const itemsOnPageMin = 10;
    if (this.stylizationUserStyles.length < itemsOnPageMin) {
      this.stylizationUserStylesPage++;
      await this.loadStylizationUserStyles();
    }
  }

  protected onScrolled() {
    this.stylizationUserStylesPage++;
    this.loadStylizationUserStyles();
  }

  trackById(index: number, imageGenerationStylizationUserStyle: ImageGenerationStylizationUserStyle) {
    return imageGenerationStylizationUserStyle.id;
  }

  protected getImageUrl(imageGenerationStylizationUserStyle: ImageGenerationStylizationUserStyle) {
    return this.imageGenerationStylizationUserStyleService.getUrlForImage(imageGenerationStylizationUserStyle, true);
  }

  protected async onCloseClick() {
    this.onClose.emit();
  }

  protected onBackDropClick() {
    if (!this.closeOnBackDropClick) {
      return;
    }
    this.onCloseClick();
  }
}
