import { Injectable } from "@angular/core";
import { RealtimeService } from "@metranpage/core";
import { GeneralResultStatus, MetranpageFile } from "@metranpage/core-data";
import { ImageGenerationStylizationUserStyle } from "../../models/image-generation-stylization-user-style/image-generation-stylization-user-style";
import {
  ImageGenerationStylizationUserStyleApi,
  ImageGenerationStylizationUserStyleFilter,
} from "./image-generation-stylization-user-style.api";
import { ImageGenerationStylizationUserStyleStore } from "./image-generation-stylization-user-style.store";

export type CreateImageGenerationStylizationUserStyleResultStatus = GeneralResultStatus | "file-limit-error";
export type CreateImageGenerationStylizationUserStyleResponse = {
  stylizationUserStyle?: ImageGenerationStylizationUserStyle;
  error?: string;
};

@Injectable({
  providedIn: "root",
})
export class ImageGenerationStylizationUserStyleService {
  constructor(
    private readonly imageGenerationStylizationUserStyleStore: ImageGenerationStylizationUserStyleStore,
    private readonly imageGenerationStylizationUserStyleApi: ImageGenerationStylizationUserStyleApi,
    readonly realtimeService: RealtimeService,
  ) {}

  async loadStylizationUserStylesPaginated(page: number, filters: ImageGenerationStylizationUserStyleFilter = {}) {
    const characterReferenceData =
      await this.imageGenerationStylizationUserStyleApi.getImageGenerationStylizationUserStylesPaginated(page, filters);
    this.imageGenerationStylizationUserStyleStore.addStylizationUserStylesToEnd(characterReferenceData.items);
    this.imageGenerationStylizationUserStyleStore.setStylizationUserStylesPageCount(characterReferenceData.pageCount);
  }

  async createStylizationUserStyle(file: File) {
    try {
      const result = await this.imageGenerationStylizationUserStyleApi.createImageGenerationStylizationUserStyle(file);
      if (result.stylizationUserStyle) {
        this.imageGenerationStylizationUserStyleStore.addStylizationUserStyle(result.stylizationUserStyle);
      }
      return result;
    } catch (error: any) {
      return { error: "request-error" };
    }
  }

  async deleteStylizationUserStyle(id: number) {
    try {
      await this.imageGenerationStylizationUserStyleApi.deleteImageGenerationStylizationUserStyle(id);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  getUrlForImage(imageGenerationStylizationUserStyle: ImageGenerationStylizationUserStyle, isThumbnail = false) {
    if (isThumbnail) {
      return this.imageGenerationStylizationUserStyleApi.getUrlForFile(imageGenerationStylizationUserStyle.previewFile);
    }
    return this.imageGenerationStylizationUserStyleApi.getUrlForFile(imageGenerationStylizationUserStyle.file);
  }

  getUrlForFile(file: MetranpageFile) {
    return this.imageGenerationStylizationUserStyleApi.getUrlForFile(file);
  }
}
