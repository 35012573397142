import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { CompanyImageGenerationRestrictedWordsSettings } from "../../models/company";

@Injectable({
  providedIn: "root",
})
export class AdminCompanyImageGenerationRestrictedWordsSettingsApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getImageGenerationRestrictedWordsSettings() {
    return this.get<CompanyImageGenerationRestrictedWordsSettings>(
      "admin/company-image-generation-restricted-words-settings",
    );
  }

  async updateImageGenerationRestrictedWordsSettings(data: CompanyImageGenerationRestrictedWordsSettings) {
    return this.post<CompanyImageGenerationRestrictedWordsSettings>(
      "admin/company-image-generation-restricted-words-settings",
      data,
    );
  }
}
