import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ImageGenerationRestrictedWords } from "../models/image-generation";

@Injectable({
  providedIn: "root",
})
export class ImageGenerationRestrictedWordsService {
  protected restrictedWords: ImageGenerationRestrictedWords = {
    ru: [],
    en: [],
  };

  protected preparedRestrictedWords = "";

  setRestrictedWords(restrictedWords: ImageGenerationRestrictedWords) {
    this.restrictedWords = restrictedWords;
    this.prepareRestrictedWords();
  }

  getRestrictedWords() {
    return this.restrictedWords;
  }

  hasPartiallyRestrictedText(text: string | undefined): boolean {
    if (!text || !this.preparedRestrictedWords) {
      return false;
    }

    const expr = new RegExp(this.preparedRestrictedWords);
    return expr.test(text.toLowerCase());
  }

  hasFullyRestrictedText(text: string | undefined): boolean {
    if (!text || !this.preparedRestrictedWords) {
      return false;
    }

    const expr = new RegExp(`(^|\\s)(${this.preparedRestrictedWords})(?=\\s|$)`, "gi");
    return expr.test(text.toLowerCase());
  }

  private prepareRestrictedWords() {
    this.preparedRestrictedWords = "";

    const array = Object.values(this.restrictedWords).flat();
    let words = array.filter((w) => w.trim().length > 0);
    words = words.map((w) => w.trim());

    this.preparedRestrictedWords = words.join("|");
  }

  promptValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (this.hasFullyRestrictedText(value.prompt)) {
        return {
          restrictedWord: true,
        };
      }

      return null;
    };
  }
}
