<div class="backdrop" [class.clickable]="false" (click)="close.emit()">
  <div class="close-icon" (click)="close.emit()">
    <m-ng-icon src="close-cross.svg" [size]="16" />
  </div>
</div>

<div class="wrapper">
  <div class="outer-cell left">
    <div class="top">
      <div class="caption" i18n="@@cover-editor.completion-modal.export.caption">
        cover-editor.completion-modal.export.caption
      </div>
      <div class="text" i18n="@@cover-editor.completion-modal.export.description">
        cover-editor.completion-modal.export.description
      </div>
    </div>
    <div class="bottom">
      <m-button
        button-style="primary"
        size="m"
        icon-position="left"
        icon="download.svg"
        (click)="downloadCoverImage()"
        i18n="@@cover-editor.completion-modal.export.button.download"
      >
        cover-editor.completion-modal.export.button.download
      </m-button>

      <m-button
        *ngIf="isLayoutFinished('final')"
        button-style="primary"
        size="m"
        icon-position="left"
        icon="download.svg"
        (onClick)="onDownloadProjectClick()"
        i18n="@@cover-editor.completion-modal.more.button.download-project"
      >
        cover-editor.completion-modal.more.button.download-project
      </m-button>
    </div>
  </div>
  <div class="outer-cell">
    <div class="top">
      <div class="caption" i18n="@@cover-editor.completion-modal.more.caption">
        cover-editor.completion-modal.more.caption
      </div>
      <div class="text" i18n="@@cover-editor.completion-modal.more.description">
        cover-editor.completion-modal.more.description
      </div>
    </div>
    <div class="bottom">
      <ng-container *ngIf="isSettingsAvailable$ | async as isSettingsAvailable">
        <m-button
          *ngIf="book.bookResults!.isOriginalFileUploaded"
          button-style="primary"
          size="m"
          icon-position="left"
          icon="edit-01.svg"
          (onClick)="onProcessBookClick()"
          i18n="@@cover-editor.completion-modal.more.button.edit-book"
        >
          cover-editor.completion-modal.more.button.edit-book
        </m-button>
        <m-button
          *ngIf="!book.bookResults!.isOriginalFileUploaded"
          button-style="primary"
          size="m"
          icon-position="left"
          icon="new-01.svg"
          (onClick)="onProcessBookClick()"
          i18n="@@cover-editor.completion-modal.more.button.upload-book"
        >
          cover-editor.completion-modal.more.button.upload-book
        </m-button>

        <m-button
          *ngIf="isLayoutFinished('final')"
          button-style="primary"
          size="m"
          icon-position="left"
          icon="cover-to-book.svg"
          (onClick)="onAddCoverToBookClick()"
          i18n="@@cover-editor.completion-modal.more.button.add-cover-to-book"
        >
          cover-editor.completion-modal.more.button.add-cover-to-book
        </m-button>
      </ng-container>

      <m-button
        button-style="secondary"
        size="m"
        [routerLink]="['/books']"
        i18n="@@cover-editor.completion-modal.more.button.to-my-projects"
      >
        cover-editor.completion-modal.more.button.to-my-projects
      </m-button>
      <m-button
        button-style="secondary"
        size="m"
        (click)="close.emit()"
        i18n="@@cover-editor.completion-modal.more.button.to-cover-editor"
      >
        cover-editor.completion-modal.more.button.to-cover-editor
      </m-button>
    </div>
  </div>
</div>
