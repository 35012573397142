import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { CompanyStore } from "@metranpage/company";
import { InfoBlockActionData, InfoBlockData, fadeInOutOnEnterLeave, slideInOutVertical } from "@metranpage/components";
import { AnalyticsService, NotificationsPopUpService, RouterService, filterUndefined } from "@metranpage/core";
import { PricingService } from "@metranpage/pricing";
import { ActiveSubscription, PaymentCurrency, Tariff } from "@metranpage/pricing-data";
import { FirstPaymentService, RewardsService } from "@metranpage/user";
import { RewardsStore, User, UserBalance, UserRewardOneTime, UserStore } from "@metranpage/user-data";
import { UserVerificationOverlayServiceInterface } from "@metranpage/user-interfaces";
import { BalanceData } from "@metranpage/user-payment-data";
import * as _ from "lodash-es";
import { Observable, Subscription, combineLatest, first, map } from "rxjs";
import { Character } from "../../models/character/character";
import { DownloadGeneratedImageData, GeneratedImage, SelectGeneratedImageData } from "../../models/generated-image";
import {
  AdvancedGenerationMode,
  FaceSwapImageGenerationDataDto,
  ImageGeneration,
  ImageGenerationAdvancedStyle,
  ImageGenerationCreativeStyle,
  ImageGenerationMode,
  ImageGenerationPrices,
  ImageSize,
  LastImageGenerationsDataDto,
} from "../../models/image-generation";
import { ImageGenerationCharacterReference } from "../../models/image-generation-character-reference/image-generation-character-reference";
import { ImageGenerationUserStyle } from "../../models/image-generation-user-style/image-generation-user-style";
import { CharactersService } from "../../services/characters.service";
import { CharacterStore } from "../../services/characters.store";
import { GeneratedImageService } from "../../services/generated-image.service";
import { GeneratedImageStore } from "../../services/generated-image.store";
import { ImageGenerationDataService } from "../../services/image-generation-data.service";
import {
  AdvancedFormChangeData,
  CreativeFormChangeData,
  ImageGenerationFormService,
} from "../../services/image-generation-form.service";
import { ImageGenerationRealtimeService } from "../../services/image-generation-realtime.service";
import { ImageGenerationRestrictedWordsService } from "../../services/image-generation-restricted-words.service";
import { ImageGenerationUserStyleService } from "../../services/image-generation-user-style/image-generation-user-style.service";
import { ImageGenerationUserStyleStore } from "../../services/image-generation-user-style/image-generation-user-style.store";
import { ImageGenerationService } from "../../services/image-generation.service";
import { ImageGenerationStore } from "../../services/image-generation.store";
import { PublishedImageStore } from "../../services/published-image.store";
import { CharacterReferenceModalMode } from "../character-reference-modal/character-reference-modal.view";
import { PreviewImageData } from "../generation-result/generation-result.view";
import {
  ImageProportionSizeMode,
  ImageProportionValue,
} from "../image-proportion-selector/image-proportion-selector.view";

@Component({
  selector: "m-image-generation-view",
  templateUrl: "./image-generation.view.html",
  styleUrls: ["./image-generation.view.scss"],
  animations: [fadeInOutOnEnterLeave, slideInOutVertical],
})
export class ImageGenerationView implements AfterViewInit, OnDestroy {
  @Input()
  isModal = false;
  @Input()
  closeOnBackDropClick = true;
  @Input()
  closeButtonVisible = true;
  @Input()
  selectedTab = 2;
  @Input()
  showTab?: number;
  @Input()
  imageSize?: ImageSize;

  @Output()
  selectImage = new EventEmitter<SelectGeneratedImageData>();
  @Output()
  onClose = new EventEmitter();
  @Output()
  onTabSelect = new EventEmitter<number>();
  @Output()
  onInit = new EventEmitter<void>();

  // @ViewChild("formBasicElement", { read: ElementRef })
  // formBasicElement?: ElementRef;
  @ViewChild("formAdvancedElement", { read: ElementRef })
  formAdvancedElement?: ElementRef;
  @ViewChild("formCreativeElement", { read: ElementRef })
  formCreativeElement?: ElementRef;

  protected lastImageGenerations?: LastImageGenerationsDataDto;

  protected imageGenerations: ImageGeneration[] = [];
  protected publishedImageSettings?: ImageGeneration;
  protected publishedImageForFaceSwap?: GeneratedImage;

  protected page = 1;
  protected pageCount = 1;

  protected promptMaxLenght = 350;
  protected negativePromptMaxLenght = 200;

  protected isLowBalanceModalVisible = false;
  tariffsForUpgrade$!: Observable<Tariff[]>;
  protected activeSubscription?: ActiveSubscription;
  protected higherTariff?: Tariff;
  protected hasPaidTariff = false;
  protected hasTrialPeriod = false;
  protected balance!: UserBalance;

  // protected formBasic?: FormGroup;
  protected formAdvanced?: FormGroup;
  protected formCreative?: FormGroup;

  protected user!: User;
  protected imageGenerationPaymentData!: BalanceData;
  protected tariff?: Tariff;
  // protected igBasicImagesCount = 4;
  // protected igBasicSelectedStyleId = "0";
  // protected igBasicPrice?: number;
  protected igAdvancedImagesCount = 2;
  protected igAdvancedSelectedStyleId = "0";
  protected igAdvancedSelectedStyleReferenceId: number | undefined = undefined;
  protected igAdvancedPrice?: number;
  protected igCreativeImagesCount = 4;
  protected igCreativeSelectedStyleId = "0";
  protected igCreativeSelectedStyleReferenceId: number | undefined = undefined;
  protected igCreativePrice?: number;
  protected prices!: ImageGenerationPrices;
  protected igFaceSwapPrice?: number;

  protected isLoading = true;
  protected isImageGenerationsLoading = true;

  sub: Subscription = new Subscription();

  protected previewImageGeneration?: ImageGeneration;
  protected previewGeneratedImage?: GeneratedImage;
  protected previewGeneratedImageIndex?: number;

  protected imageGenerationForDelete?: ImageGeneration;
  protected generatedImageForDelete?: GeneratedImage;

  protected modalConfirmationDeleteImageText = "";

  protected generatedImageForShare?: GeneratedImage;

  protected isAdvancedAgeSelectVisible = false;
  protected isAdvancedSetAgeYearInputVisible = false;
  protected isAdvancedColorSchemeVisible = false;
  protected isAdvancedNegativePromptVisible = false;

  protected advancedMoodErrors: InfoBlockData[] = [];

  protected advancedGenerationMode: AdvancedGenerationMode = "quality";
  protected advancedGenerationProportion?: ImageProportionValue;
  private advancedModeUsedWithCharacterReference: AdvancedGenerationMode = "quality";

  protected isCreativeAgeSelectVisible = false;
  protected isCreativeSetAgeYearInputVisible = false;
  protected isCreativeColorSchemeVisible = false;
  protected isCreativeNegativePromptVisible = false;

  protected creativeMoodErrors: InfoBlockData[] = [];

  protected creativeGenerationProportion?: ImageProportionValue;

  protected rewardsOneTime: UserRewardOneTime[] = [];
  protected currency: PaymentCurrency = "RUB";

  protected characters: Character[] = [];
  protected isCharactersModalVisible = false;
  protected isCharacterChanging = false;
  protected addedCharactersIds: number[] = [];
  protected charactersInPromptLimit = 2;

  protected isStyleReferenceUploadModalVisible = false;
  protected styleReference?: ImageGenerationUserStyle;
  protected userStyleReferencePrefix = "user-style-";

  protected hasAdvancedCharacterReference = false;
  protected advancedCharacterReference?: ImageGenerationCharacterReference;
  protected hasCreativeCharacterReference = false;
  protected creativeCharacterReference?: ImageGenerationCharacterReference;
  protected isCharacterReferenceModalVisible = false;
  protected characterReferenceModalMode: CharacterReferenceModalMode = "select";
  protected hasFaceReference = true;
  protected hasCharacterReference = true;
  protected isAdvancedStyleFluxModel = false;
  protected hasAdvancedStyleReference = true;

  protected faceSwapCharacterReference?: ImageGenerationCharacterReference;

  protected isIGBasicMaintenance = false;
  protected isIGAdvancedMaintenance = false;

  protected hasPremium = true;
  protected isPremiumModalVisible = false;

  protected infoBlockData: InfoBlockData[] = [];

  protected knowledgeBaseUrl?: string;

  protected advancedFilteredStyles: ImageGenerationAdvancedStyle[] = [];
  protected creativeFilteredStyles: ImageGenerationCreativeStyle[] = [];

  constructor(
    userStore: UserStore,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly notificationService: NotificationsPopUpService,
    private readonly imageGenerationStore: ImageGenerationStore,
    private readonly imageGenerationService: ImageGenerationService,
    private readonly imageGenerationRealtimeService: ImageGenerationRealtimeService,
    private readonly imageGenerationDataService: ImageGenerationDataService,
    private readonly imageGenerationFormService: ImageGenerationFormService,
    private readonly generatedImageService: GeneratedImageService,
    private readonly generatedImageStore: GeneratedImageStore,
    private readonly pricingService: PricingService,
    private readonly publishedImageStore: PublishedImageStore,
    private readonly routerService: RouterService,
    private readonly firstPaymentService: FirstPaymentService,
    companyStore: CompanyStore,
    rewardsStore: RewardsStore,
    private readonly rewardsService: RewardsService,
    @Inject("UserVerificationOverlayService")
    protected readonly userVerificationOverlayService: UserVerificationOverlayServiceInterface,
    private readonly characterService: CharactersService,
    private readonly analytics: AnalyticsService,
    readonly characterStore: CharacterStore,
    readonly imageGenerationUserStyleStore: ImageGenerationUserStyleStore,
    private readonly imageGenerationUserStyleService: ImageGenerationUserStyleService,
    private readonly imageGenerationRestrictedWordsService: ImageGenerationRestrictedWordsService,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        if (!user) {
          return;
        }
        this.user = user;
      }),
    );

    this.sub.add(
      userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        this.activeSubscription = activeSubscription;
        this.hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
        this.hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;

        if (!this.activeSubscription) {
          return;
        }
        this.getHigherTariff();

        this.hasPremium = this.pricingService.hasPaidTariff(this.activeSubscription);
      }),
    );

    this.sub.add(
      userStore.getBalanceObservable().subscribe((balance) => {
        if (!balance) {
          return;
        }
        this.balance = balance;
      }),
    );

    this.tariffsForUpgrade$ = combineLatest([
      userStore.getActiveSubscriptionObservable(),
      pricingService.getTariffsForCompany(),
    ]).pipe(
      map(([subscription, tariffs]) => ({ subscription, tariffs: tariffs.filter((v) => v.isFree === false) })),
      map((info) => {
        if (!info.subscription || info.subscription.tariff.isFree) {
          return info.tariffs.filter((t) => t.period === 1);
        }
        return info.tariffs.filter((t) => t.period === info.subscription?.tariff.period);
      }),
    );

    // this.sub.add(
    //   this.imageGenerationFormService.basicFormValueChangesEvent$.pipe(filterUndefined()).subscribe((value) => {
    //     this.igBasicImagesCount = value.imagesCount;

    //     let hasElements = false;
    //     const styleReferenceId = this.getStyleReferenceId(value.styleId);
    //     if (!styleReferenceId) {
    //       if (this.imageGenerationFormService.isBasicStylePremium(Number.parseInt(value.styleId)) && !this.hasPremium) {
    //         this.showPremiumModal();
    //         this.formBasic?.patchValue({ styleId: this.igBasicSelectedStyleId });
    //       } else {
    //         this.igBasicSelectedStyleId = value.styleId;
    //       }
    //       hasElements = this.isStyleHasElements(Number.parseInt(this.igBasicSelectedStyleId));
    //     } else {
    //       this.igBasicSelectedStyleId = value.styleId;
    //     }

    //     this.updatePrice(this.igBasicImagesCount, "basic", hasElements, undefined);
    //   }),
    // );

    this.sub.add(
      this.imageGenerationFormService.advancedFormValueChangesEvent$.pipe(filterUndefined()).subscribe((value) => {
        this.igAdvancedImagesCount = value.imagesCount;

        this.hasAdvancedCharacterReference = value.userCharacterReferenceId > 0;
        this.isAdvancedStyleFluxModel = this.isAdvancedStyleModel(value, "Flux", "include");

        const advancedGenerationMode = this.updateAdvancedGenerationMode(value.advancedGenerationMode);

        this.updatePrice(
          this.igAdvancedImagesCount,
          "advanced",
          false,
          advancedGenerationMode,
          this.hasAdvancedCharacterReference,
        );

        this.isAdvancedAgeSelectVisible = value.isAgeEnable;
        this.isAdvancedColorSchemeVisible = value.isColorSchemeEnable;
        this.isAdvancedNegativePromptVisible = value.isNegativePromptEnable;

        if (this.isAdvancedAgeSelectVisible && value.age === "set-year") {
          this.isAdvancedSetAgeYearInputVisible = true;
        } else {
          this.isAdvancedSetAgeYearInputVisible = false;
        }

        if (value.mood) {
          this.advancedMoodErrors = [];
        }

        this.checkAdvancedStyleReference(value);
        this.checkAdvancedCharacterReference(value, this.hasAdvancedCharacterReference);

        if (value.advancedGenerationMode === "ultra" && this.igAdvancedSelectedStyleReferenceId) {
          this.advancedGenerationMode = "quality";
        }

        if (value.advancedGenerationMode === "ultra" && !this.hasPremium) {
          this.showPremiumModal();
          this.formAdvanced?.patchValue({ advancedGenerationMode: this.advancedGenerationMode });
        } else {
          this.advancedGenerationMode = value.advancedGenerationMode;
        }

        this.updateInfoBlockData();

        this.cdr.markForCheck();
      }),
    );

    this.sub.add(
      this.imageGenerationFormService.creativeFormValueChangesEvent$.pipe(filterUndefined()).subscribe((value) => {
        this.igCreativeImagesCount = value.imagesCount;

        this.hasCreativeCharacterReference = value.userCharacterReferenceId > 0;

        this.updatePrice(this.igCreativeImagesCount, "creative", false, undefined, this.hasCreativeCharacterReference);

        this.isCreativeAgeSelectVisible = value.isAgeEnable;
        this.isCreativeColorSchemeVisible = value.isColorSchemeEnable;
        this.isCreativeNegativePromptVisible = value.isNegativePromptEnable;

        if (this.isCreativeAgeSelectVisible && value.age === "set-year") {
          this.isCreativeSetAgeYearInputVisible = true;
        } else {
          this.isCreativeSetAgeYearInputVisible = false;
        }

        if (value.mood) {
          this.creativeMoodErrors = [];
        }

        this.checkCreativeStyleReference(value);
        this.checkCreativeCharacterReference(value, this.hasCreativeCharacterReference);

        this.updateInfoBlockData();

        this.cdr.markForCheck();
      }),
    );

    this.sub.add(
      this.publishedImageStore
        .getPublishedImageSettingsObservable()
        .pipe(filterUndefined())
        .pipe(first())
        .subscribe(async (settings) => {
          this.publishedImageSettings = settings;

          this.publishedImageStore.setPublishedImageSettings(undefined);
        }),
    );

    this.sub.add(
      this.imageGenerationFormService.advancedFormProportionsValueChangesEvent$
        .pipe(filterUndefined())
        .subscribe((value) => {
          this.advancedGenerationProportion = value;
        }),
    );

    this.sub.add(
      this.imageGenerationFormService.creativeFormProportionsValueChangesEvent$
        .pipe(filterUndefined())
        .subscribe((value) => {
          this.creativeGenerationProportion = value;
        }),
    );

    this.sub.add(
      rewardsStore.getRewardsOneTimeObservable().subscribe((rewards) => {
        this.rewardsOneTime = rewards;
      }),
    );

    this.sub.add(
      companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.currency = company.currency;

          this.isIGBasicMaintenance = company.maintenanceSettings?.imageGenerationBasic ?? false;
          this.isIGAdvancedMaintenance = company.maintenanceSettings?.imageGenerationAdvanced ?? false;

          this.knowledgeBaseUrl = company.knowledgeBaseUrl;

          this.cdr.markForCheck();
        }),
    );

    this.sub.add(
      characterStore
        .getCharactersObservable()
        .pipe(filterUndefined())
        .subscribe((characters) => {
          this.characters = characters.filter((ig) => !ig.deletedAt);

          this.cdr.markForCheck();
        }),
    );

    this.sub.add(
      imageGenerationUserStyleStore.getUserStyleObservable().subscribe((styleReference) => {
        this.styleReference = styleReference;

        this.cdr.markForCheck();
      }),
    );

    this.sub.add(
      imageGenerationUserStyleService.onChangeUserStyleReference$.subscribe((userStyleReferenceId) => {
        this.setUserStyleReference(userStyleReferenceId);

        this.cdr.markForCheck();
      }),
    );
  }

  async ngAfterViewInit() {
    // this.loadingService.startLoading({ fullPage: true });
    this.isLoading = true;

    this.lastImageGenerations = await this.imageGenerationService.getLastImageGenerations();

    await this.loadImageGenerationUserStyle();

    await this.loadImageGenerationsPaginated();

    await this.characterService.loadCharacters();

    this.prices = await this.imageGenerationService.loadPrices();
    const stylesBasic = await this.imageGenerationService.loadImageGenerationBasicStyles();
    const stylesAdvanced = await this.imageGenerationService.loadImageGenerationAdvancedStyles();
    const stylesCreative = await this.imageGenerationService.loadImageGenerationCreativeStyles();

    const restrictedWords = await this.imageGenerationService.loadRestrictedWords();
    this.imageGenerationRestrictedWordsService.setRestrictedWords(restrictedWords);

    this.imageGenerationFormService.setBasicStyles(stylesBasic);
    // this.formBasic = this.imageGenerationFormService.initBasicForm(
    //   this.igBasicImagesCount,
    //   stylesBasic,
    //   lastImageGenerations.lastBasicIG,
    //   this.imageSize,
    //   this.promptMaxLenght,
    //   this.userStyleReferencePrefix,
    //   this.styleReference,
    // );
    this.formAdvanced = this.imageGenerationFormService.initAdvancedForm(
      this.igAdvancedImagesCount,
      stylesAdvanced,
      this.lastImageGenerations.lastAdvancedIG,
      this.imageSize,
      this.promptMaxLenght,
      this.negativePromptMaxLenght,
      this.userStyleReferencePrefix,
      this.styleReference,
      this.hasPremium,
    );
    this.formCreative = this.imageGenerationFormService.initCreativeForm(
      this.igCreativeImagesCount,
      stylesCreative,
      this.lastImageGenerations.lastCreativeIG,
      this.imageSize,
      this.promptMaxLenght,
      this.negativePromptMaxLenght,
      this.userStyleReferencePrefix,
      this.styleReference,
      this.hasPremium,
    );

    this.updateDefaultPrice(this.lastImageGenerations);

    this.updateHiddenFields(this.lastImageGenerations.lastAdvancedIG, "advanced");
    this.updateHiddenFields(this.lastImageGenerations.lastCreativeIG, "creative");

    this.updateDefaultAdvancedImageProportionSizeMode(this.lastImageGenerations.lastAdvancedIG);

    this.updateSelectedTabFromLastIG(this.lastImageGenerations);

    this.updateUserCharacterReferenceFromLastIG(this.lastImageGenerations);

    this.setPublishedImageSettings();

    this.selectTab(this.selectedTab);
    if (this.showTab) {
      this.selectTab(this.showTab);
    }
    this.onInit.emit();

    this.sub.add(
      this.imageGenerationStore
        .getImageGenerationsObservable()
        .pipe(filterUndefined())
        .subscribe(async (imageGenerations) => {
          this.imageGenerations = imageGenerations.filter((ig) => !ig.deletedAt && !ig.rootGeneratedImageId);

          if (this.page < this.pageCount && this.imageGenerations.length < 5) {
            await this.onScroll();
          }

          this.updatePreviewGeneratedImage();

          this.cdr.markForCheck();
        }),
    );

    this.sub.add(
      this.imageGenerationStore
        .getImageGenerationsPageCountObservable()
        .pipe(filterUndefined())
        .subscribe((count) => {
          this.pageCount = count;
        }),
    );

    this.sub.add(
      this.generatedImageStore
        .getPreviewGeneratedImageObservable()
        .pipe(filterUndefined())
        .subscribe((previewGeneratedImage) => {
          this.previewGeneratedImage = previewGeneratedImage;
        }),
    );

    this.sub.add(
      this.publishedImageStore
        .getPublishedImageForFaceSwapObservable()
        .pipe(filterUndefined())
        .subscribe(async (image) => {
          this.setPublishedImageForFaceSwap(image);
          this.publishedImageStore.setPublishedImageForFaceSwap(undefined);
        }),
    );

    // this.loadingService.stopLoading();
    this.isLoading = false;

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.imageGenerationFormService.destroyForm();
    this.sub.unsubscribe();
  }

  protected onCloseClick() {
    this.onClose.emit();
  }

  onBackClick() {
    this.router.navigate(["/"]);
  }

  protected setPublishedImageSettings() {
    this.imageGenerationFormService.setPublishedImageSettings(
      this.publishedImageSettings,
      this.lastImageGenerations,
      this.userStyleReferencePrefix,
      this.styleReference,
    );
    this.updateSelectedTabFromPublishedImageSettings(this.publishedImageSettings);
    this.updateCharacterReferenceFromPublishedImageSettings(this.publishedImageSettings);
  }

  protected setPublishedImageForFaceSwap(image: GeneratedImage | undefined) {
    this.publishedImageForFaceSwap = image;
    if (!this.publishedImageForFaceSwap) {
      return;
    }
    this.showCharacterReferenceModal("generation");
  }

  protected checkAdvancedStyleReference(value: AdvancedFormChangeData) {
    this.igAdvancedSelectedStyleReferenceId = this.getStyleReferenceId(value.styleId);
    if (!this.igAdvancedSelectedStyleReferenceId) {
      if (this.imageGenerationFormService.isAdvancedStylePremium(Number.parseInt(value.styleId)) && !this.hasPremium) {
        this.showPremiumModal();
        this.formAdvanced?.patchValue({ styleId: this.igAdvancedSelectedStyleId });
      } else {
        this.igAdvancedSelectedStyleId = value.styleId;
      }
    } else {
      this.igAdvancedSelectedStyleId = value.styleId;
    }
  }

  protected checkAdvancedCharacterReference(value: AdvancedFormChangeData, hasCharacterReference: boolean) {
    if (!hasCharacterReference) {
      return;
    }

    const advancedStylesFiltered = this.getAdvancedStyles(false);
    const selectedStyle = advancedStylesFiltered.find((s) => s.id === Number.parseInt(value.styleId));

    if ((selectedStyle && !selectedStyle.isCompatibleWithFaceSwap) || this.igAdvancedSelectedStyleReferenceId) {
      const advancedStyles = this.getAdvancedStyles(true);
      this.igAdvancedSelectedStyleId = `${advancedStyles[0]?.id || 0}`;
      this.formAdvanced?.patchValue({ styleId: this.igAdvancedSelectedStyleId });
    }
  }

  protected checkCreativeStyleReference(value: CreativeFormChangeData) {
    this.igCreativeSelectedStyleReferenceId = this.getStyleReferenceId(value.styleId);
    if (!this.igCreativeSelectedStyleReferenceId) {
      if (this.imageGenerationFormService.isCreativeStylePremium(Number.parseInt(value.styleId)) && !this.hasPremium) {
        this.showPremiumModal();
        this.formCreative?.patchValue({ styleId: this.igCreativeSelectedStyleId });
      } else {
        this.igCreativeSelectedStyleId = value.styleId;
      }
    } else {
      this.igCreativeSelectedStyleId = value.styleId;
    }
  }

  protected checkCreativeCharacterReference(value: CreativeFormChangeData, hasCharacterReference: boolean) {
    if (!hasCharacterReference) {
      return;
    }

    const creativeStylesFiltered = this.getCreativeStyles(false);
    const selectedStyle = creativeStylesFiltered.find((s) => s.id === Number.parseInt(value.styleId));

    if ((selectedStyle && !selectedStyle.isCompatibleWithFaceSwap) || this.igCreativeSelectedStyleReferenceId) {
      const creativeStyles = this.getCreativeStyles(true);
      this.igCreativeSelectedStyleId = `${creativeStyles[0]?.id || 0}`;
      this.formCreative?.patchValue({ styleId: this.igCreativeSelectedStyleId });
    }
  }

  private updateHiddenFields(lastImageGeneration: ImageGeneration | undefined, mode: "advanced" | "creative") {
    if (!lastImageGeneration) {
      return;
    }

    if (mode === "advanced") {
      if (lastImageGeneration.age && /^[0-9]{1,4}$/.test(lastImageGeneration.age)) {
        this.isAdvancedSetAgeYearInputVisible = true;
      }
      if (lastImageGeneration.isAgeEnable !== undefined) {
        this.isAdvancedAgeSelectVisible = lastImageGeneration.isAgeEnable;
      }
      if (lastImageGeneration.isColorSchemeEnable !== undefined) {
        this.isAdvancedColorSchemeVisible = lastImageGeneration.isColorSchemeEnable;
      }
      if (lastImageGeneration.isNegativePromptEnable !== undefined) {
        this.isAdvancedNegativePromptVisible = lastImageGeneration.isNegativePromptEnable;
      }
    }
    if (mode === "creative") {
      if (lastImageGeneration.age && /^[0-9]{1,4}$/.test(lastImageGeneration.age)) {
        this.isCreativeSetAgeYearInputVisible = true;
      }
      if (lastImageGeneration.isAgeEnable !== undefined) {
        this.isCreativeAgeSelectVisible = lastImageGeneration.isAgeEnable;
      }
      if (lastImageGeneration.isColorSchemeEnable !== undefined) {
        this.isCreativeColorSchemeVisible = lastImageGeneration.isColorSchemeEnable;
      }
      if (lastImageGeneration.isNegativePromptEnable !== undefined) {
        this.isCreativeNegativePromptVisible = lastImageGeneration.isNegativePromptEnable;
      }
    }
  }

  private updateDefaultPrice(lastImageGenerations: LastImageGenerationsDataDto) {
    // let basicIGImagesCount = this.igBasicImagesCount;
    // const basicIGHasElements = false;
    // if (lastImageGenerations.lastBasicIG) {
    //   basicIGImagesCount = lastImageGenerations.lastBasicIG.imagesCount;
    //   basicIGHasElements = this.isStyleHasElements(lastImageGenerations.lastBasicIG.styleId);
    // }
    // this.updatePrice(basicIGImagesCount, "basic", basicIGHasElements, undefined);

    let advancedIGImagesCount = this.igAdvancedImagesCount;
    let advancedGenerationMode: AdvancedGenerationMode = "quality";
    if (lastImageGenerations.lastAdvancedIG) {
      advancedIGImagesCount = lastImageGenerations.lastAdvancedIG.imagesCount;
      advancedGenerationMode = lastImageGenerations.lastAdvancedIG.advancedGenerationMode ?? "quality";
    }
    this.updatePrice(advancedIGImagesCount, "advanced", false, advancedGenerationMode, false);

    this.updatePrice(this.igCreativeImagesCount, "creative", false, advancedGenerationMode, false);
  }

  private updatePrice(
    imagesCount: number,
    mode: /*"basic" |*/ "advanced" | "creative",
    hasElements: boolean,
    advancedGenerationMode: AdvancedGenerationMode | undefined,
    hasCharacterReference: boolean,
  ) {
    if (!this.prices) {
      return;
    }

    let price = 1;

    // if (mode === "basic") {
    //   price = this.prices.basic[`${imagesCount}`];
    //   if (hasElements) {
    //     price *= this.prices.elementsCoeficient;
    //   }
    //   this.igBasicPrice = Math.ceil(imagesCount * price);
    // }
    if (mode === "advanced") {
      price = this.prices.advancedFast[`${imagesCount}`];
      if (advancedGenerationMode === "quality") {
        price = this.prices.advancedQuality[`${imagesCount}`];
      }
      if (advancedGenerationMode === "ultra") {
        price = this.prices.advancedUltra[`${imagesCount}`];
      }

      if (hasCharacterReference) {
        price += this.prices.advancedFabula[`${imagesCount}`];
      }

      if (hasElements) {
        price *= this.prices.elementsCoeficient;
      }
      this.igAdvancedPrice = Math.ceil(imagesCount * price);
    }

    if (mode === "creative") {
      price = this.prices.creative[`${imagesCount}`];
      if (hasCharacterReference) {
        price *= this.prices.mjCharacterReferenceCoeficient;
      }
      if (hasElements) {
        price *= this.prices.elementsCoeficient;
      }
      this.igCreativePrice = Math.ceil(imagesCount * price);
    }

    this.igFaceSwapPrice = this.prices.advancedFabula["1"];
  }

  private updateDefaultAdvancedImageProportionSizeMode(lastImageGeneration: ImageGeneration | undefined) {
    let advancedGenerationMode: AdvancedGenerationMode = "quality";
    if (lastImageGeneration) {
      advancedGenerationMode = lastImageGeneration.advancedGenerationMode ?? "quality";
    }
    this.advancedGenerationMode = advancedGenerationMode;
  }

  protected isAdvancedStyleModel(
    value: AdvancedFormChangeData,
    model: string,
    compareMode: "equal" | "include" = "equal",
  ): boolean {
    const styleReferenceId = this.getStyleReferenceId(value.styleId);
    if (styleReferenceId) {
      return false;
    }
    const styles = this.getAdvancedStyles();
    const style = styles.find((s) => s.id === Number.parseInt(value.styleId));

    if (compareMode === "include") {
      return !!style?.model?.toLowerCase().includes(model.toLowerCase());
    }

    return style?.model?.toLowerCase() === model.toLowerCase();
  }

  // isStyleHasElements(styleId: number) {
  //   const styles = this.getBasicStyles();
  //   const style = styles.find((s) => s.id === styleId);
  //   return !!style?.elements && style.elements.length > 0;
  // }

  async onGenerationClick() {
    if (this.isModeOnMaintenance()) {
      this.notificationService.error($localize`:@@image-generation.maintenance.error:`);
      return;
    }

    if (!this.user?.isVerified) {
      this.userVerificationOverlayService.open();
      return;
    }

    if (this.isGenerateDisabled()) {
      this.checkFormErrors();
      return;
    }

    if (!this.isEnoughtTokens()) {
      await this.calculatePaymentData();
      return;
    }

    await this.firstPaymentService.onPayment();

    if (this.selectedTab === 1) {
      await this.advancedImageGeneration();
      return;
    }

    await this.creativeImageGeneration();
  }

  private checkFormErrors() {
    this.updateMoodErrors();
    this.markInvalidControlAsDirty();
    this.scrollToFirstInvalidControl();
    this.notifyOnFormError();
  }

  private markInvalidControlAsDirty(): void {
    // let controls = this.formBasic?.controls;
    let controls = this.formAdvanced?.controls;
    if (this.selectedTab === 2) {
      // controls = this.formAdvanced?.controls;
      controls = this.formCreative?.controls;
    }
    for (const k in controls) {
      const control = controls[k];
      if (!control.valid) {
        control.markAsDirty();
      }
    }
  }

  private scrollToFirstInvalidControl(): void {
    // let formElement = this.formBasicElement?.nativeElement;
    let formElement = this.formAdvancedElement?.nativeElement;
    if (this.selectedTab === 2) {
      // formElement = this.formAdvancedElement?.nativeElement;
      formElement = this.formCreativeElement?.nativeElement;
    }
    if (formElement) {
      const firstErrorElement = formElement.querySelector(".ng-invalid");
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      }
    }
  }

  isEnoughtTokens(): boolean {
    // if (this.selectedTab === 1) {
    //   if (this.igBasicPrice && this.balance && this.igBasicPrice <= this.balance.credits) {
    //     return true;
    //   }
    // }

    if (this.selectedTab === 1) {
      if (this.igAdvancedPrice && this.balance && this.igAdvancedPrice <= this.balance.credits) {
        return true;
      }
    }

    if (this.selectedTab === 2) {
      if (this.igCreativePrice && this.balance && this.igCreativePrice <= this.balance.credits) {
        return true;
      }
    }

    return false;
  }

  // async basicImageGeneration() {
  //   if (!this.formBasic || !this.formBasic.valid) {
  //     return;
  //   }

  //   let data = this.formBasic.getRawValue();

  //   const proportion = data.proportion;
  //   data = { ...data, ...proportion };
  //   data.prompt = data.prompt.replaceAll("<br>", " ");
  //   data = _.omit(data, "proportion");

  //   const styleReferenceId = this.getStyleReferenceId(data.styleId);
  //   if (styleReferenceId) {
  //     data.styleId = "-1";
  //     data.userStyleId = styleReferenceId;
  //   }

  //   data.styleId = Number.parseInt(data.styleId);

  //   this.notificationService.closeAll();

  //   const result = await this.imageGenerationService.basicImageGeneration(data);
  //   if (result === "error") {
  //     this.notificationService.error($localize`:@@image-generation.generation.cant-start-processing-error:`);
  //   }

  //   this.analytics.event("ai-generate", { mode: "basic" });

  //   // this.setDefaultBasicFormValue();
  //   this.scrollGenerationRelultsToTop();
  // }

  async advancedImageGeneration() {
    if (!this.formAdvanced || !this.formAdvanced.valid) {
      return;
    }

    let data = this.formAdvanced.getRawValue();
    const aspectRatio = `${data.proportion.wp || 1}:${data.proportion.hp || 1}`;
    const size: ImageSize = {
      width: data.proportion.width,
      height: data.proportion.height,
    };
    data = { ...data, ...size, aspectRatio };
    data.colorScheme = this.imageGenerationService.convertColorSchemeToString(data.colorScheme);
    data.prompt = data.prompt.replaceAll("<br>", " ");
    data.negativePrompt = data.negativePrompt.replaceAll("<br>", " ");
    if (data.age === "set-year") {
      data.age = `${data.ageYear}`;
    }
    data.advancedGenerationMode = this.updateAdvancedGenerationMode(data.advancedGenerationMode);

    data = _.omit(data, ["proportion", "ageYear", "hasNotExistingCharacter", "isCharactersLimitReached"]);

    const styleReferenceId = this.getStyleReferenceId(data.styleId);
    if (styleReferenceId) {
      data.styleId = "-1";
      data.userStyleId = styleReferenceId;
      data.advancedGenerationMode = "fast";
    }
    data.styleId = Number.parseInt(data.styleId);

    if (data.userCharacterReferenceId <= 0) {
      data.userCharacterReferenceId = undefined;
    }

    this.notificationService.closeAll();

    const result = await this.imageGenerationService.advancedImageGeneration(data);
    if (result === "error") {
      this.notificationService.error($localize`:@@image-generation.generation.cant-start-processing-error:`);
    }

    this.analytics.event("ai-generate", { mode: "advanced", charactersCount: this.addedCharactersIds.length });

    // this.setDefaultAdvancedFormValue();
    this.scrollGenerationRelultsToTop();
  }

  async creativeImageGeneration() {
    if (!this.formCreative || !this.formCreative.valid) {
      return;
    }

    let data = this.formCreative.getRawValue();
    const aspectRatio = `${data.proportion.wp || 1}:${data.proportion.hp || 1}`;
    const size: ImageSize = {
      width: data.proportion.width,
      height: data.proportion.height,
    };
    data = { ...data, ...size, aspectRatio };
    data.colorScheme = this.imageGenerationService.convertColorSchemeToString(data.colorScheme);
    data.prompt = data.prompt.replaceAll("<br>", " ");
    data.negativePrompt = data.negativePrompt.replaceAll("<br>", " ");
    if (data.age === "set-year") {
      data.age = `${data.ageYear}`;
    }
    data = _.omit(data, ["proportion", "ageYear", "hasNotExistingCharacter", "isCharactersLimitReached"]);

    const styleReferenceId = this.getStyleReferenceId(data.styleId);
    if (styleReferenceId) {
      data.styleId = "-1";
      data.userStyleId = styleReferenceId;
    }
    data.styleId = Number.parseInt(data.styleId);

    if (data.userCharacterReferenceId <= 0) {
      data.userCharacterReferenceId = undefined;
    }

    this.notificationService.closeAll();

    const result = await this.imageGenerationService.creativeImageGeneration(data);
    if (result === "error") {
      this.notificationService.error($localize`:@@image-generation.generation.cant-start-processing-error:`);
    }

    this.analytics.event("ai-generate", { mode: "creative", charactersCount: this.addedCharactersIds.length });

    // this.setDefaultAdvancedFormValue();
    this.scrollGenerationRelultsToTop();
  }

  async faceSwapImageGeneration() {
    if (!this.faceSwapCharacterReference || !this.publishedImageForFaceSwap) {
      return;
    }

    const generationSettings = this.publishedImageForFaceSwap.generationSettings;

    const data: FaceSwapImageGenerationDataDto = {
      width: generationSettings.width,
      height: generationSettings.height,
      prompt: $localize`:@@image-generation.generation.face-swap.prompt:`,
      negativePrompt: "",
      imagesCount: 1,
      styleId: -1,
      detalization: "",
      mood: "",
      colorScheme: generationSettings.colorScheme,
      isNegativePromptEnable: false,
      isAgeEnable: generationSettings.isAgeEnable,
      isColorSchemeEnable: false,
      age: generationSettings.age,
      contrast: generationSettings.contrast,
      dynamicComposition: generationSettings.dynamicComposition,
      userStyleId: undefined,
      userCharacterReferenceId: this.faceSwapCharacterReference.id,
      generatedImageIdForFaceSwap: this.publishedImageForFaceSwap.id,
    };

    this.notificationService.closeAll();

    const result = await this.imageGenerationService.faceSwapImageGeneration(data);
    if (result === "error") {
      this.notificationService.error($localize`:@@image-generation.generation.cant-start-processing-error:`);
    }

    this.setPublishedImageForFaceSwap(undefined);
    this.faceSwapCharacterReference = undefined;

    this.analytics.event("ai-generate", { mode: "face-swap" });

    this.scrollGenerationRelultsToTop();
  }

  private async calculatePaymentData() {
    // this.loadingService.startLoading({ fullPage: true });
    this.isLoading = true;

    let imagesCount = this.igAdvancedImagesCount;
    let mode: ImageGenerationMode = "advanced";
    let hasElements = false;
    let hasCharacterReference = this.hasAdvancedCharacterReference;

    if (this.selectedTab === 2) {
      imagesCount = this.igCreativeImagesCount;
      mode = "creative";
      hasElements = false;
      hasCharacterReference = this.hasCreativeCharacterReference;
    }

    const imageGenerationPaymentData = await this.imageGenerationService.checkCredits({
      imagesCount,
      mode,
      hasElements,
      hasAdditionalRemoveBackground: false,
      advancedGenerationMode: this.advancedGenerationMode,
      hasCharacterReference: hasCharacterReference,
      hasUserStyle: false,
    });

    if (imageGenerationPaymentData === "error") {
      this.notificationService.error($localize`:@@image-generation.generation.cant-start-processing-error:`);
      // this.loadingService.stopLoading();
      this.isLoading = false;

      return;
    }

    this.imageGenerationPaymentData = imageGenerationPaymentData;
    this.balance = imageGenerationPaymentData.userBalance;

    // this.loadingService.stopLoading();
    this.isLoading = false;

    this.isLowBalanceModalVisible = true;

    this.cdr.markForCheck();
  }

  private async calculatePaymentDataForFaceSwap() {
    // this.loadingService.startLoading({ fullPage: true });
    this.isLoading = true;

    const imagesCount = 1;
    const mode: ImageGenerationMode = "face-swap";
    const hasElements = false;
    const hasAdditionalRemoveBackground = false;
    const hasCharacterReference = true;

    const imageGenerationPaymentData = await this.imageGenerationService.checkCredits({
      imagesCount,
      mode,
      hasElements,
      hasAdditionalRemoveBackground,
      advancedGenerationMode: this.advancedGenerationMode,
      hasCharacterReference: hasCharacterReference,
      hasUserStyle: false,
    });

    if (imageGenerationPaymentData === "error") {
      this.notificationService.error($localize`:@@image-generation.generation.cant-start-processing-error:`);
      // this.loadingService.stopLoading();
      this.isLoading = false;

      return;
    }

    this.imageGenerationPaymentData = imageGenerationPaymentData;
    this.balance = imageGenerationPaymentData.userBalance;

    // this.loadingService.stopLoading();
    this.isLoading = false;

    this.isLowBalanceModalVisible = true;

    this.cdr.markForCheck();
  }

  protected closePricingModal() {
    this.isLowBalanceModalVisible = false;
  }

  protected async getHigherTariff() {
    this.higherTariff = await this.pricingService.getHigherTariff();
  }

  protected onShowImagePreviewClick(value: PreviewImageData) {
    if (!this.isModal) {
      this.routerService.showInModal([value.imageGeneration.id, value.generatedImage.id]);
      return;
    }

    this.previewImageGeneration = value.imageGeneration;
    this.previewGeneratedImage = value.generatedImage;
    this.previewGeneratedImageIndex = this.generatedImageService.getGeneratedImageIndex(
      value.generatedImage,
      this.previewImageGeneration?.generatedImages,
    );

    this.generatedImageStore.setPreviewGeneratedImage(this.previewGeneratedImage);
  }

  protected onCloseImagePreviewClick() {
    this.resetPreview();
  }

  protected onPreviousClick() {
    if (this.previewGeneratedImageIndex === undefined || !this.hasPreviousImage()) {
      return;
    }

    this.previewGeneratedImageIndex--;
    this.previewGeneratedImage = this.previewImageGeneration?.generatedImages[this.previewGeneratedImageIndex];
  }

  protected onNextClick() {
    if (this.previewGeneratedImageIndex === undefined || !this.hasNextImage()) {
      return;
    }

    this.previewGeneratedImageIndex++;
    this.previewGeneratedImage = this.previewImageGeneration?.generatedImages[this.previewGeneratedImageIndex];
  }

  protected hasPreviousImage() {
    if (!this.previewImageGeneration || this.previewGeneratedImageIndex === undefined) {
      return false;
    }
    return this.previewGeneratedImageIndex - 1 >= 0;
  }

  protected hasNextImage() {
    if (!this.previewImageGeneration || this.previewGeneratedImageIndex === undefined) {
      return false;
    }
    return this.previewGeneratedImageIndex + 1 < this.previewImageGeneration?.generatedImages.length;
  }

  protected onShowDeleteImageGenerationModal(imageGeneration: ImageGeneration) {
    this.imageGenerationForDelete = imageGeneration;
  }

  protected onCloseDeleteImageGenerationModal() {
    this.imageGenerationForDelete = undefined;
    this.cdr.markForCheck();
  }

  protected async deleteImageGeneration() {
    if (!this.imageGenerationForDelete) {
      return;
    }
    await this.imageGenerationService.deleteImageGeneration(this.imageGenerationForDelete.id);

    this.resetPreview();
    this.onCloseDeleteImageGenerationModal();
  }

  protected onShowDeleteGeneratedImageModal(generatedImage: GeneratedImage) {
    this.modalConfirmationDeleteImageText = $localize`:@@image-generation.generation.delete-generated-image-with-child-generations-confirmation:`;
    if (this.isGeneratedImageVariant(generatedImage)) {
      this.modalConfirmationDeleteImageText = $localize`:@@image-generation.generation.delete-generated-image-confirmation:`;
    }
    this.generatedImageForDelete = generatedImage;
  }

  protected isGeneratedImageVariant(generatedImage: GeneratedImage) {
    return this.previewGeneratedImage && this.previewGeneratedImage.id !== generatedImage.id;
  }

  protected onMadeUpscaleImageClick(data: PreviewImageData) {
    this.onShowImagePreviewClick(data);
  }

  protected onMadeUnzoomImageClick(data: PreviewImageData) {
    this.onShowImagePreviewClick(data);
  }

  protected onEditImageGenerationClick(imageGeneration: ImageGeneration) {
    this.notificationService.notify({
      content: $localize`:@@soon-text-notification:`,
      type: "success",
      showOptsTimeout: 2,
    });
  }

  protected onCloseDeleteGeneratedImageModal() {
    this.generatedImageForDelete = undefined;
    this.cdr.markForCheck();
  }

  private updatePreviewGeneratedImage() {
    for (const ig of this.imageGenerations) {
      let isPreviewGeneratedImageFound = false;
      for (const gi of ig.generatedImages) {
        if (this.previewGeneratedImage?.id === gi.id) {
          this.previewGeneratedImage = gi;
          isPreviewGeneratedImageFound = true;
          break;
        }
      }
      if (isPreviewGeneratedImageFound) {
        break;
      }
    }
  }

  protected async deleteImage() {
    if (!this.generatedImageForDelete) {
      return;
    }
    await this.generatedImageService.deleteGeneratedImage(this.generatedImageForDelete.id);

    if (this.isGeneratedImageVariant(this.generatedImageForDelete)) {
      this.onCloseDeleteGeneratedImageModal();
      return;
    }

    this.resetPreview();
    this.onCloseDeleteGeneratedImageModal();
  }

  protected onSelectImage(data: SelectGeneratedImageData) {
    this.selectImage.emit(data);
  }

  protected onSelectImageFromModalClick(generatedImage: GeneratedImage) {
    this.onSelectImage({
      generationId: generatedImage.imageGenerationId,
      generatedImageId: generatedImage.id,
      imageUrl: generatedImage.imageUrl,
    });
    this.resetPreview();
  }

  protected resetPreview() {
    this.previewImageGeneration = undefined;
    this.previewGeneratedImage = undefined;
    this.previewGeneratedImageIndex = undefined;

    this.generatedImageStore.setPreviewGeneratedImage(this.previewGeneratedImage);
    this.cdr.markForCheck();
  }

  protected onBackDropClick() {
    if (!this.closeOnBackDropClick) {
      return;
    }
    this.onCloseClick();
  }

  protected async onDownloadImageClick(downloadGeneratedImageData: DownloadGeneratedImageData) {
    await this.generatedImageService.downloadGeneratedImage(downloadGeneratedImageData);
  }

  protected async onDownloadImageFromModalClick(generatedImage: GeneratedImage) {
    await this.generatedImageService.downloadGeneratedImage({
      imageGenerationId: generatedImage.imageGenerationId,
      imageUrl: generatedImage.imageUrl,
    });
  }

  private async loadImageGenerationsPaginated() {
    this.isImageGenerationsLoading = true;
    await this.imageGenerationService.loadImageGenerationsPaginated(this.page);
    this.isImageGenerationsLoading = false;
  }

  private async loadImageGenerationUserStyle() {
    await this.imageGenerationUserStyleService.loadUserStyle();
  }

  protected async onScroll() {
    this.page++;
    await this.loadImageGenerationsPaginated();
    this.cdr.markForCheck();
  }

  protected isGenerateDisabled(): boolean {
    // if (this.selectedTab === 1) {
    //   return !this.formBasic?.valid || !this.igBasicPrice || !this.balance;
    // }
    // if (this.selectedTab === 2) {
    //   return !this.formAdvanced?.valid || !this.igAdvancedPrice || !this.balance;
    // }
    if (this.selectedTab === 1) {
      return !this.formAdvanced?.valid || !this.igAdvancedPrice || !this.balance;
    }
    if (this.selectedTab === 2) {
      return !this.formCreative?.valid || !this.igCreativePrice || !this.balance;
    }
    return false;
  }

  protected onTabClick(selectedTab: number) {
    if (this.selectedTab === selectedTab) {
      return;
    }
    this.selectTab(selectedTab);
  }

  private selectTab(selectedTab: number) {
    this.selectedTab = selectedTab;

    this.updateCharacterReferenceOptions();
    this.updateInfoBlockData();

    this.onTabSelect.emit(this.selectedTab);
  }

  private updateCharacterReferenceOptions() {
    this.hasCharacterReference = true;
    if (this.selectedTab === 1) {
      this.hasCharacterReference = false;
    }
  }

  getDetalizationPoints() {
    return this.imageGenerationDataService.getDetalizationPoints();
  }

  getMoodOptions() {
    return this.imageGenerationDataService.getMoodOptions();
  }

  getAgeOptions() {
    return this.imageGenerationDataService.getAgeOptions();
  }

  getContrastPoints() {
    return this.imageGenerationDataService.getContrastPoints();
  }

  getModelOptions() {
    return this.imageGenerationDataService.getModelOptions();
  }

  getModeOptions() {
    return this.imageGenerationDataService.getModeOptions();
  }

  getBasicStyles() {
    return this.imageGenerationFormService.getBasicStyles();
  }

  updateAdvancedStyles() {
    this.advancedFilteredStyles = this.getAdvancedStyles(true);
  }

  getAdvancedStyles(needFilter = false) {
    let styles = this.imageGenerationFormService.getAdvancedStyles();
    if (!needFilter) {
      return styles;
    }
    if (this.advancedCharacterReference) {
      styles = styles.filter((s) => s.isCompatibleWithFaceSwap);
    }
    return styles;
  }

  updateCreativeStyles() {
    this.creativeFilteredStyles = this.getCreativeStyles(true);
  }

  getCreativeStyles(needFilter = false) {
    let styles = this.imageGenerationFormService.getCreativeStyles();
    if (!needFilter) {
      return styles;
    }
    if (this.creativeCharacterReference) {
      styles = styles.filter((s) => s.isCompatibleWithFaceSwap);
    }
    return styles;
  }

  getAdvancedGenerationModeOptions() {
    return this.imageGenerationDataService.getAdvancedGenerationModeOptions();
  }

  private scrollGenerationRelultsToTop() {
    this.imageGenerationService.onScrollToTopImageGenerationResults();
  }

  // protected onHelperClick() {
  //   const prompt = this.formBasic?.get("prompt")?.value;
  //   if (prompt) {
  //     this.formAdvanced?.patchValue({ prompt });
  //   }
  //   this.onTabClick(2);
  // }

  protected notifyOnFormError() {
    let form: FormGroup | undefined = undefined;
    if (this.selectedTab === 1) {
      form = this.formAdvanced;
    }
    if (this.selectedTab === 2) {
      form = this.formCreative;
    }
    if (!form) {
      return;
    }

    const minLengthErrors = ["required", "minLength"];

    for (const controlName of Object.keys(form.controls)) {
      const controlErrors = form?.get(controlName)?.errors;
      if (!controlErrors) {
        continue;
      }
      const processedErrors: { [key: string]: string[] } = {};

      for (const error of Object.keys(controlErrors)) {
        if (!processedErrors[controlName]) {
          processedErrors[controlName] = [];
        }

        const processedError = processedErrors[controlName].some((v) => minLengthErrors.includes(v));
        if (processedErrors[controlName]?.includes(error) || processedError) {
          continue;
        }

        if (error === "hasNotExistingCharacter") {
          this.notificationService.error($localize`:@@characters.not-existing.error.notification:`);
          processedErrors[controlName].push(error);
        }

        if (error === "isCharactersLimitReached") {
          this.notificationService.error(
            `${$localize`:@@characters.limit.error.notification:`} ${this.charactersInPromptLimit}`,
          );
          processedErrors[controlName].push(error);
        }

        if (controlName === "prompt" && (error === "minLength" || error === "required")) {
          this.notificationService.error($localize`:@@image-generation.form.prompt.min-length-limit.error:`);
          processedErrors[controlName].push(error);
        }
        if (controlName === "prompt" && error === "maxLength") {
          this.notificationService.error(
            `${$localize`:@@image-generation.form.prompt.max-length-limit.error:`}: ${this.promptMaxLenght}`,
          );
          processedErrors[controlName].push(error);
        }

        if (controlName === "negativePrompt" && error === "maxLength") {
          this.notificationService.error(
            `${$localize`:@@image-generation.form.negative-prompt.max-length-limit.error:`}: ${this.negativePromptMaxLenght}`,
          );
          processedErrors[controlName].push(error);
        }

        const processedErrorDefault = processedErrors[controlName].some((v) => v === error);
        if (!processedErrorDefault) {
          this.notificationService.error($localize`:@@image-generation.form.error:`);
          processedErrors[controlName].push(error);
        }
      }
    }
  }

  protected updateMoodErrors() {
    if (this.selectedTab === 1) {
      this.advancedMoodErrors = [];
      if (!this.formAdvanced?.get("mood")?.valid) {
        this.advancedMoodErrors.push({
          textData: [{ text: $localize`:@@image-generation.mood.error:` }],
        });
      }
    }

    if (this.selectedTab === 2) {
      this.creativeMoodErrors = [];
      if (!this.formAdvanced?.get("mood")?.valid) {
        this.advancedMoodErrors.push({
          textData: [{ text: $localize`:@@image-generation.mood.error:` }],
        });
      }
    }

    this.cdr.markForCheck();
  }

  private updateSelectedTabFromLastIG(lastImageGenerations: LastImageGenerationsDataDto | undefined) {
    if (!lastImageGenerations) {
      return;
    }
    if (lastImageGenerations.lastIGMode === "advanced") {
      this.selectedTab = 1;
    }
    if (lastImageGenerations.lastIGMode === "creative") {
      this.selectedTab = 2;
    }
    this.cdr.markForCheck();
  }

  private updateSelectedTabFromPublishedImageSettings(publishedImageSettings: ImageGeneration | undefined) {
    if (!publishedImageSettings) {
      return;
    }
    this.selectedTab = 1;
    if (this.imageGenerationService.isCreativeGeneration(publishedImageSettings)) {
      this.selectedTab = 2;
    }
    this.cdr.markForCheck();
  }

  private updateCharacterReferenceFromPublishedImageSettings(publishedImageSettings: ImageGeneration | undefined) {
    if (!publishedImageSettings) {
      return;
    }
    if (this.selectedTab === 1) {
      this.advancedCharacterReference = publishedImageSettings.userCharacterReference?.deletedAt
        ? undefined
        : publishedImageSettings.userCharacterReference;
    }
    if (this.selectedTab === 2) {
      this.creativeCharacterReference = publishedImageSettings.userCharacterReference?.deletedAt
        ? undefined
        : publishedImageSettings.userCharacterReference;
    }

    this.cdr.markForCheck();
  }

  protected getSubscribeToTelegramChannelReward() {
    return this.rewardsService.getSubscribeToTelegramChannelReward(this.rewardsOneTime);
  }

  protected selectCharacter(character: Character) {
    this.characterService.onSelectCharacter(character);
  }

  protected showCharactersModal(isCharacterChanging: boolean) {
    // if (this.selectedTab === 1) {
    //   this.notificationService.notify({
    //     content: $localize`:@@characters.notofication.only-in-advanced-generation:`,
    //     type: "info",
    //     showOptsTimeout: 2,
    //   });
    //   return;
    // }
    this.isCharactersModalVisible = true;
    this.isCharacterChanging = isCharacterChanging;
  }

  protected hideCharactersModal() {
    this.isCharactersModalVisible = false;
  }

  protected onCharactersIdsChanged(addedCharactersIds: number[]) {
    this.addedCharactersIds = addedCharactersIds;
  }

  protected showStyleReferenceUploadModal() {
    this.isStyleReferenceUploadModalVisible = true;
  }

  protected closeStyleReferenceUploadModal() {
    this.isStyleReferenceUploadModalVisible = false;
  }

  protected onChangeStyleReference() {
    this.showStyleReferenceUploadModal();
  }

  protected getStyleReferenceId(styleId: string) {
    if (!styleId.includes(this.userStyleReferencePrefix)) {
      return undefined;
    }

    return Number.parseInt(styleId.replace(this.userStyleReferencePrefix, ""));
  }

  protected setUserStyleReference(userStyleReferenceId: number) {
    const styleId = `${this.userStyleReferencePrefix}${userStyleReferenceId}`;
    // if (this.selectedTab === 1) {
    //   this.formBasic?.patchValue({ styleId });
    // }
    if (this.selectedTab === 1) {
      this.formAdvanced?.patchValue({ styleId });
    }
    if (this.selectedTab === 2) {
      this.formCreative?.patchValue({ styleId });
    }
  }

  private isModeOnMaintenance(): boolean {
    return (
      (this.selectedTab === 1 && this.isIGBasicMaintenance) || (this.selectedTab === 2 && this.isIGAdvancedMaintenance)
    );
  }

  protected showPremiumModal() {
    this.isPremiumModalVisible = true;
  }

  protected closePremiumModal() {
    this.isPremiumModalVisible = false;
  }

  protected updateAdvancedGenerationMode(advancedGenerationMode: AdvancedGenerationMode) {
    if (this.isAdvancedStyleFluxModel) {
      return this.advancedModeUsedWithCharacterReference;
    }
    return advancedGenerationMode;
  }

  protected getAdvancedGenerationMode(): ImageProportionSizeMode {
    if (this.igAdvancedSelectedStyleReferenceId) {
      return "basic";
    }
    if (this.isAdvancedStyleFluxModel) {
      return this.advancedModeUsedWithCharacterReference;
    }
    return this.advancedGenerationMode;
  }

  async onGenerateFaceSwapClick(imageGenerationCharacterReference: ImageGenerationCharacterReference) {
    if (!this.user?.isVerified) {
      this.userVerificationOverlayService.open();
      return;
    }

    if (!this.igFaceSwapPrice || !this.balance) {
      return;
    }

    if (this.igFaceSwapPrice && this.balance && this.igFaceSwapPrice > this.balance.credits) {
      await this.calculatePaymentDataForFaceSwap();
      return;
    }

    await this.firstPaymentService.onPayment();

    this.faceSwapCharacterReference = imageGenerationCharacterReference;

    await this.faceSwapImageGeneration();
  }

  protected selectCharacterReference(characterReference: ImageGenerationCharacterReference) {
    if (this.selectedTab === 1) {
      this.advancedCharacterReference = characterReference;
      this.formAdvanced?.patchValue({ userCharacterReferenceId: characterReference.id });

      this.updateAdvancedStyles();
    }
    if (this.selectedTab === 2) {
      this.creativeCharacterReference = characterReference;

      this.formCreative?.patchValue({ userCharacterReferenceId: characterReference.id });

      this.updateCreativeStyles();
    }
    this.hideCharacterReferenceModal();
  }

  protected removeCharacterReference() {
    if (this.selectedTab === 1) {
      this.advancedCharacterReference = undefined;
      this.formAdvanced?.patchValue({ userCharacterReferenceId: -1 });

      this.updateAdvancedStyles();
    }
    if (this.selectedTab === 2) {
      this.creativeCharacterReference = undefined;
      this.formCreative?.patchValue({ userCharacterReferenceId: -1 });

      this.updateCreativeStyles();
    }
    this.cdr.markForCheck();
  }

  private updateUserCharacterReferenceFromLastIG(lastImageGenerations: LastImageGenerationsDataDto | undefined) {
    this.advancedCharacterReference = lastImageGenerations?.lastAdvancedIG?.userCharacterReference;
    this.creativeCharacterReference = lastImageGenerations?.lastCreativeIG?.userCharacterReference;

    this.updateAdvancedStyles();
    this.updateCreativeStyles();

    this.cdr.markForCheck();
  }

  protected showCharacterReferenceModal(mode: CharacterReferenceModalMode = "select") {
    this.isCharacterReferenceModalVisible = true;
    this.characterReferenceModalMode = mode;
    this.cdr.markForCheck();
  }

  protected hideCharacterReferenceModal() {
    this.isCharacterReferenceModalVisible = false;
    this.characterReferenceModalMode = "select";
    this.cdr.markForCheck();
  }

  protected updateInfoBlockData() {
    this.infoBlockData = [];

    if (this.selectedTab === 1) {
      if (this.advancedCharacterReference?.type === "face") {
        this.infoBlockData.push({
          textData: [
            { text: $localize`:@@image-generation.styles-restriction.advanced.error-text-1:` },
            {
              text: $localize`:@@image-generation.styles-restriction.advanced.error-action:`,
              action: { controlName: "select-tab", value: 2 },
            },
            { text: $localize`:@@image-generation.styles-restriction.advanced.error-text-2:` },
          ],
        });
      }
    }

    if (this.selectedTab === 2) {
      if (this.formCreative?.errors?.restrictedWord) {
        const infoBlockData: InfoBlockData = {
          textData: [{ text: $localize`:@@image-generation.generation.prompt-restriction.info:` }],
        };
        if (this.knowledgeBaseUrl) {
          infoBlockData.textData.push({
            text: $localize`:@@image-generation.generation.prompt-restriction.action:`,
            action: { controlName: "prompt-restriction", value: "" },
          });
        }
        this.infoBlockData.push(infoBlockData);
      }

      if (this.creativeCharacterReference?.type === "face") {
        this.infoBlockData.push({
          textData: [
            { text: $localize`:@@image-generation.styles-restriction.creative.error-text-1:` },
            {
              text: $localize`:@@image-generation.styles-restriction.creative.error-action:`,
              action: { controlName: "select-tab", value: 1 },
            },
            { text: $localize`:@@image-generation.styles-restriction.creative.error-text-2:` },
          ],
        });
      }
    }

    this.cdr.markForCheck();
  }

  protected infoBlockActionClick(data: InfoBlockActionData) {
    if (data.controlName === "select-tab") {
      const tabIndex = Number.parseInt(`${data.value}`);
      if (this.selectedTab === tabIndex) {
        return;
      }
      this.onTabClick(tabIndex);
    }

    if (data.controlName === "prompt-restriction") {
      if (!this.knowledgeBaseUrl) {
        return;
      }

      const url = `${this.knowledgeBaseUrl}image-generation/#_11`;
      window.open(url, "_blank");
      return;
    }

    this.cdr.markForCheck();
  }

  protected onRepeatImageGeneration(imageGeneration: ImageGeneration) {
    if (imageGeneration.generationMode === "face-swap") {
      const image = imageGeneration.generatedImages[0];
      this.setPublishedImageForFaceSwap(image);
      this.publishedImageStore.setPublishedImageForFaceSwap(undefined);
      return;
    }
    this.publishedImageSettings = imageGeneration;
    this.setPublishedImageSettings();
  }

  @HostBinding("class")
  get cssClassList() {
    const classes: string[] = [];

    if (this.isModal) {
      classes.push("modal");
    }

    return classes.join(" ");
  }

  @HostListener("window:keydown.escape")
  protected handleKeyDown() {
    this.onCloseClick();
  }
}
