import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export type PaymentMethodSelectValue = {
  id: number;
  value: string;
  icon: string;
};

@Component({
  selector: "m-payment-method-select",
  templateUrl: "./payment-method-select.view.html",
  styleUrls: ["./payment-method-select.view.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaymentMethodSelectView),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodSelectView implements ControlValueAccessor, OnChanges {
  @Input()
  options: PaymentMethodSelectValue[] = [];

  public touched = false;

  protected optionDropdownHeight = 48;
  protected optionsDropdownHeight = 48;
  protected maxOptionsCountInDropdown = 5;

  protected isMenuOpened = false;
  protected value?: number | null | undefined;

  protected selectedOption?: PaymentMethodSelectValue;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  private isDisabled = false;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.selectOptionById(this.value);
      this.updateOptionsDropdownHeight();
    }
  }

  writeValue(value: number | null | undefined): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    if (this.isMenuOpened) {
      result.push("menu-opened");
    }

    if (this.isDisabled) {
      result.push("disabled");
    }

    return result;
  }

  protected onFieldClick() {
    if (this.isDisabled) {
      return;
    }

    this.isMenuOpened = !this.isMenuOpened;
    if (this.isMenuOpened) {
      this.touched = true;
      this.onTouched();
    }
    this.cdr.markForCheck();
  }

  protected onSelect(value: PaymentMethodSelectValue | undefined) {
    this.selectedOption = value;
    this.isMenuOpened = false;
    this.onChange(value?.id);
  }

  protected selectOptionById(value: number | null | undefined) {
    this.selectedOption = this.options.find((o) => o.id === value);
  }

  protected updateOptionsDropdownHeight() {
    const maxHeight = this.optionDropdownHeight * this.maxOptionsCountInDropdown;
    let height = this.options.length * this.optionDropdownHeight;
    if (height > maxHeight) {
      height = maxHeight;
    }
    this.optionsDropdownHeight = height;
  }

  protected getArrowIconClassList(): string[] {
    if (this.isMenuOpened) {
      return ["reversed"];
    }
    return [];
  }

  protected trackByFn(index: number, item: PaymentMethodSelectValue) {
    return item.id;
  }

  @HostListener("document:click", ["$event"])
  onClickOutside(event: Event) {
    if (this.elementRef.nativeElement.contains(event.target)) {
      // click in component
    } else {
      if (this.isMenuOpened) {
        this.isMenuOpened = false;
        this.cdr.markForCheck();
      }
    }
  }
}
