<m-modal [paddingSize]="0" (close)="onCloseClick()">
  <div class="container">
    <div class="header-wrapper">
      <div class="header" i18n="@@payment-confirmation-modal.title">payment-confirmation-modal.title</div>
      <div class="description" i18n="@@payment-confirmation-modal.description">
        payment-confirmation-modal.description
      </div>
    </div>

    <div class="divider-hor"></div>

    <div class="payment-info-wrapper">
      <div class="payment-info-group">
        <span class="payment-info-title" i18n="@@payment-confirmation-modal.payment-info.tariff">
          payment-confirmation-modal.payment-info.tariff
        </span>

        <span class="payment-info-value">{{ tariff.title }}</span>
      </div>

      <div class="payment-info-group">
        <span class="payment-info-title" i18n="@@payment-confirmation-modal.payment-info.balance">
          payment-confirmation-modal.payment-info.balance
        </span>

        <div class="balance-group">
          <div class="balance-before">
            <m-ng-icon src="coins-01.svg" [size]="16" />
            <span>{{ currentBalance }}</span>
          </div>
          /
          <div class="balance-after">
            <m-ng-icon src="coins-01.svg" [size]="16" />
            <span>{{ newBalance }}</span>
          </div>
        </div>
      </div>

      <div class="payment-info-group">
        <span class="payment-info-title" i18n="@@payment-confirmation-modal.payment-info.next-payment-date">
          payment-confirmation-modal.payment-info.next-payment-date
        </span>

        <span class="payment-info-value">{{ nextPaymentDate }}</span>
      </div>
    </div>

    <form class="payment-methods-wrapper" [formGroup]="paymentMethodForm">
      <span class="payment-methods-title" i18n="@@payment-confirmation-modal.payment-info.method">
        payment-confirmation-modal.payment-info.method
      </span>

      <m-payment-method-select formControlName="card" [options]="paymentMethodsOptions" />
    </form>

    <form class="promocode-wrapper" [formGroup]="promocodeForm">
      <div class="promocode-group">
        <span class="payment-methods-title" i18n="@@payment-confirmation-modal.promocode.title">
          payment-confirmation-modal.promocode.title
        </span>

        <m-ng-input
          formControlName="promocode"
          placeholder="payment-confirmation-modal.promocode.placeholder"
          i18n-placeholder="@@payment-confirmation-modal.promocode.placeholder"
        />

        <m-info-block type="error" [iconSize]="24" [labels]="promocodeErrors" />
      </div>

      <div class="button-wrapper-32">
        <m-button
          button-style="secondary"
          size="fill"
          (onClick)="applyPromocode()"
          i18n="@@payment-confirmation-modal.promocode.apply"
        >
          payment-confirmation-modal.promocode.apply
        </m-button>
      </div>

      @if(promocodeDiscountData) {
      <div class="active-promocode">
        <div class="text">
          <span i18n="@@payment-confirmation-modal.promocode.discont-active-1">
            payment-confirmation-modal.promocode.discont-active-1
          </span>
          <span class="text discount" i18n="@@payment-confirmation-modal.promocode.discont-active-2">
            payment-confirmation-modal.promocode.discont-active-2
          </span>
          <span class="text discount">{{ promocodeDiscountData.discount }}%</span>
        </div>

        <m-ng-icon src="cross-16.svg" [size]="16" (click)="deleteLastActiveUserPromocode()" />
      </div>
      }
    </form>

    <div class="button-wrapper">
      <m-button button-style="primary" size="full-width" (onClick)="pay()">
        <div class="price-group">
          <span i18n="@@payment-confirmation-modal.pay">payment-confirmation-modal.pay</span>

          @if(tariff.profit > 0 || hasPromocodeDiscount()) {
          <div class="price-without-discount">{{ priceWithoutDiscount }}</div>
          }

          <div class="price">{{ getPrice() }}</div>
        </div>
      </m-button>
    </div>
  </div>
</m-modal>
