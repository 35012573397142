<m-modal-backdrop
  [closeButtonVisible]="closeButtonVisible"
  [closeOnBackDropClick]="closeOnBackDropClick"
  (close)="onCloseClick()"
>
  <m-ng-island [paddingSize]="0">
    <div class="container">
      <div class="title" i18n="@@cover-editor.stylization.modal.generation.title">
        cover-editor.stylization.modal.generation.title
      </div>

      <div class="divider-wrapper">
        <div class="divider-hor"></div>
      </div>

      <div class="items">
        <m-ng-scrollbars (onScrolled)="onScrolled()">
          <div class="cards">
            <div class="card no-background">
              <ng-container *ngTemplateOutlet="fileDropTemplate"></ng-container>
            </div>
            <div *ngFor="let item of stylizationUserStyles; trackBy: trackById" class="card">
              <ng-container *ngTemplateOutlet="cardTemplate; context: { reference: item }"></ng-container>
            </div>
          </div>
        </m-ng-scrollbars>
      </div>
    </div>
  </m-ng-island>
</m-modal-backdrop>

<ng-template #fileDropTemplate>
  <m-file-drop
    #filedrop
    accept="image/png, image/jpeg"
    [multiple]="false"
    (dropped)="dropped($event)"
    style="--custom-height: 100%"
  >
    <div class="file-drop-container">
      <div class="drop-placeholder" (click)="openFileSelector()">
        <m-icon src="upload-2.svg" size="original" color="--color-font-main"></m-icon>

        <div>
          <div class="description-text">
            <ng-container i18n="@@stylization-upload-modal.upload-button.text-1">
              stylization-upload-modal.upload-button.text-1
            </ng-container>
          </div>
          <div class="description-text">
            <ng-container i18n="@@stylization-upload-modal.upload-button.text-2">
              stylization-upload-modal.upload-button.text-2
            </ng-container>
            <span class="mark" i18n="@@stylization-upload-modal.upload-button.text-2-upload">
              stylization-upload-modal.upload-button.text-2-upload
            </span>
            <ng-container i18n="@@stylization-upload-modal.upload-button.text-3">
              stylization-upload-modal.upload-button.text-3
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </m-file-drop>
</ng-template>

<ng-template #cardTemplate let-reference="reference">
  <m-skeleton-image [url]="getImageUrl(reference)" width="200" height="200" unit="px" imgObjectFit="contain" />

  <div class="controlls-wrapper">
    <div class="controlls">
      <div class="button-wrapper">
        <m-button
          button-style="tertiary"
          size="fill"
          icon-position="left"
          icon="checkmark-02.svg"
          i18n="@@image-generation.character-reference.modal.card.select-button"
          (onClick)="selectStylizationUserStyle(reference)"
        >
          image-generation.character-reference.modal.card.select-button
        </m-button>
      </div>

      <div class="divider"></div>

      <m-button
        button-style="tertiary"
        size="full-width"
        color-theme="danger"
        icon-position="left"
        icon="delete-02.svg"
        i18n="@@image-generation.character-reference.modal.delete-button"
        (onClick)="showConfiramtionDeleteModal(reference)"
      >
        image-generation.character-reference.modal.delete-button
      </m-button>
    </div>
  </div>
</ng-template>

@if(stylizationStyleForDelete) {
<m-confirmation-modal
  text="image-generation.character-reference.delete-confirmation"
  i18n-text="@@image-generation.character-reference.delete-confirmation"
  confirmText="dialog.delete-button"
  i18n-confirmText="@@dialog.delete-button"
  cancelText="dialog.cancel-button"
  i18n-cancelText="@@dialog.cancel-button"
  [closeButtonVisible]="true"
  (onConfirm)="deleteStylizationUserStyle()"
  (onClose)="closeDeleteStylizationUserStyleModal()"
/>
}
