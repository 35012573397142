<m-modal-backdrop [closeButtonVisible]="true" [closeOnBackDropClick]="true" (close)="onCloseClick()">
  <div class="container" @fade-scale>
    <div class="header-wrapper">
      <div class="cell">
        <img src="/assets/img/coins-01.png" />
        <div class="column-group">
          <div class="header" i18n="@@books.preview.payments-modal.not-enough-tokens">
            books.preview.payments-modal.not-enough-tokens
          </div>
          <div class="text">
            <m-localized-text class="text" [text]="upgradeRecommendations" />
          </div>
        </div>
      </div>
      <div class="cell">
        <div class="price-and-balance">
          <div class="price-group">
            <span *ngIf="!isImageGeneration" i18n="@@books.preview.payments-modal.header-01">
              books.preview.payments-modal.header-01
            </span>
            <span *ngIf="isImageGeneration">
              {{ getImageGenerationText() }}
            </span>
            <div class="price">
              <m-ng-icon class="price-icon" src="coins-01.svg" [size]="16" />
              <span>{{ balanceData.price }}</span>
            </div>
          </div>
          <div class="price-group">
            <span i18n="@@pricing.balance">pricing.balance</span>
            <div class="price not-enough-tokens">
              <m-ng-icon class="price-icon" src="coins-01.svg" [size]="16" />
              <span>{{ balanceData.userBalance.credits }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <m-tariff-grid-2
      [tariffs]="tariffs"
      [currency]="currency"
      cardAppearance="compact"
      [activeSubscription]="activeSubscription"
      [promocodeDiscountData]="promocodeDiscountData"
      (buySubscription)="showPaymentConfirmationModal($event)"
    />

    <div *ngIf="hasGoldCredits()" class="footer-wrapper">
      <div class="cell">
        <img src="/assets/img/gold-credit-01.png" />
        <div class="column-group">
          <div class="header" i18n="@@books.preview.payments-modal.use-gold-token-01">
            books.preview.payments-modal.use-gold-token-01
          </div>
          <div class="text">
            <span i18n="@@books.preview.payments-modal.what-is-gold-credits-text">
              books.preview.payments-modal.what-is-gold-credits-text
            </span>
          </div>
        </div>
      </div>
      <div class="cell right-wrapper">
        <m-button class="button" button-style="primary" color-theme="gold" (click)="payWithGoldCredits.emit()">
          <span class="button-text" i18n="@@books.preview.payments-modal.use-gold-token-01">
            books.preview.payments-modal.use-gold-token-01
          </span>
        </m-button>
      </div>
    </div>

    <m-reward-task-connect-telegram-2-view
      *ngIf="hasTelegramReward()"
      [user]="user"
      [userRewardOneTime]="userRewardOneTime"
    />
  </div>
</m-modal-backdrop>

@if (selectedTariff) {
  <m-payment-confirmation-modal
    [tariff]="selectedTariff"
    [promocodeDiscountData]="promocodeDiscountData"
    (close)="closePaymentConfirmationModal()"
  />
}

@if (isLoading) {
  <m-loading-spinner class="layout-process-loading" />
}
