import { CompanyConfig, flagsForPublishers } from "@metranpage/core-interfaces";

const config: CompanyConfig = {
  deployedUrl: "https://author-today-test.metranpage.com/",
  ga: "G-7XRT391W56",
  companyUuid: "0f502c64-301e-46e2-bc82-d5b1052767c4",
  availableLanguages: [
    { visibleName: "Русский", iconSrc: "/assets/img/flag-ru.svg", value: "ru" },
    { visibleName: "English", iconSrc: "/assets/img/flag-gb.svg", value: "en" },
  ],
  downloadFileTemplate: "{filename}",
  color: "#4582af",
  socials: [],
  flags: {
    ...flagsForPublishers,
    isPurchasesAvailable: false,
    isEditionNoticeAvailable: false,
    isCoverEditorEnabled: true,
  },
};
export { config };
