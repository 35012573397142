import { Injectable } from "@angular/core";
import { CoverSnippetCategory, CoverSnippetSubcategory } from "@metranpage/book-data";
import { SelectValue } from "@metranpage/components";

@Injectable({
  providedIn: "root",
})
export class CoverSnippetDataService {
  protected categoryOptions: SelectValue[] = [
    {
      id: "text",
      value: $localize`:@@cover-editor.create-snippet-modal.category.text:`,
    },
    // {
    //   id: "objects",
    //   value: $localize`:@@cover-editor.create-snippet-modal.category.object:`,
    // },
  ];

  protected subcategoryTextOptions: SelectValue[] = [
    {
      id: "headersAndText",
      value: $localize`:@@cover-editor.cover-snippet.subcategory.text.headers-and-text.title:`,
    },
    // {
    //   id: "lists",
    //   value: $localize`:@@cover-editor.cover-snippet.subcategory.text.lists.title:`,
    // },
    {
      id: "quotes",
      value: $localize`:@@cover-editor.cover-snippet.subcategory.text.quotes.title:`,
    },
  ];

  protected subcategoryObjectOptions: SelectValue[] = [
    {
      id: "basicShapes",
      value: $localize`:@@cover-editor.cover-snippet.subcategory.objects.basic-shapes.title:`,
    },
    {
      id: "ageConstraints",
      value: $localize`:@@cover-editor.cover-snippet.subcategory.objects.age-constraints.title:`,
    },
    {
      id: "arrows",
      value: $localize`:@@cover-editor.cover-snippet.subcategory.objects.arrows.title:`,
    },
    {
      id: "splashes",
      value: $localize`:@@cover-editor.cover-snippet.subcategory.objects.splashes.title:`,
    },
  ];

  protected languageOptions: SelectValue[] = [
    {
      id: "ru",
      value: "ru",
    },
    {
      id: "en",
      value: "en",
    },
  ];

  protected coverSnippetCategoryOrder = {
    text: 1,
    objects: 2,
  };

  protected coverSnippetSubcategoryOrder = {
    headersAndText: 1,
    lists: 2,
    quotes: 3,

    basicShapes: 1,
    ageConstraints: 2,
    arrows: 3,
    splashes: 4,
  };

  getCategoryOptions() {
    return this.categoryOptions;
  }

  getSubcategoryOptions(category: CoverSnippetCategory | undefined) {
    switch (category) {
      case "text":
        return this.subcategoryTextOptions;
      case "objects":
        return this.subcategoryObjectOptions;
      default:
        return [];
    }
  }

  getCoverSnippetCategoryTitle(category: CoverSnippetCategory | undefined): string {
    switch (category) {
      case "text":
        return $localize`:@@cover-editor.object.create.button.text:`;
      case "objects":
        return $localize`:@@cover-editor.object.create.button.object:`;
      default:
        return "";
    }
  }

  getCoverSnippetCategoryIcon(category: CoverSnippetCategory | undefined): string {
    switch (category) {
      case "text":
        return "text-01.svg";
      case "objects":
        return "shape-01.svg";
      default:
        return "";
    }
  }

  hasCoverSnippetCategoryNewMark(category: CoverSnippetCategory | undefined): boolean {
    if (category === "text") {
      return true;
    }
    return false;
  }

  getCoverSnippetSubcategoryTitle(subcategory: CoverSnippetSubcategory | undefined): string | undefined {
    switch (subcategory) {
      case "headersAndText":
        return $localize`:@@cover-editor.cover-snippet.subcategory.text.headers-and-text.title:`;
      case "lists":
        return $localize`:@@cover-editor.cover-snippet.subcategory.text.lists.title:`;
      case "quotes":
        return $localize`:@@cover-editor.cover-snippet.subcategory.text.quotes.title:`;

      case "basicShapes":
        return $localize`:@@cover-editor.cover-snippet.subcategory.objects.basic-shapes.title:`;
      case "ageConstraints":
        return $localize`:@@cover-editor.cover-snippet.subcategory.objects.age-constraints.title:`;
      case "arrows":
        return $localize`:@@cover-editor.cover-snippet.subcategory.objects.arrows.title:`;
      case "splashes":
        return $localize`:@@cover-editor.cover-snippet.subcategory.objects.splashes.title:`;
      default:
        return undefined;
    }
  }

  getCoverSnippetCategoryOrder(category: CoverSnippetCategory | undefined) {
    if (!category) {
      return 0;
    }
    return this.coverSnippetCategoryOrder[category];
  }

  getCoverSnippetSubcategoryOrder(subcategory: CoverSnippetSubcategory | undefined) {
    if (!subcategory) {
      return 0;
    }
    return this.coverSnippetSubcategoryOrder[subcategory];
  }

  getCoverSnippetObjectTypeTitle(type: string | undefined): string {
    switch (type?.toLowerCase()) {
      case "text":
        return $localize`:@@cover-editor.cover-snippet.default.text.title:`;
      case "image":
        return $localize`:@@cover-editor.cover-snippet.default.image.title:`;
      case "rectangle":
        return $localize`:@@cover-editor.cover-snippet.default.rectangle.title:`;
      case "ellipse":
        return $localize`:@@cover-editor.cover-snippet.default.ellipse.title:`;
      case "shape":
        return $localize`:@@cover-editor.cover-snippet.default.shape.title:`;
      case "group":
        return $localize`:@@cover-editor.cover-snippet.default.group.title:`;
      case "snippet":
        return $localize`:@@cover-editor.cover-snippet.default.snippet.title:`;
      default:
        return "";
    }
  }

  getLanguageOptions(category: CoverSnippetCategory | undefined) {
    switch (category) {
      case "text":
        return this.languageOptions;
      default:
        return [];
    }
  }
}
