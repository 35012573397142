import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { ImageGenerationMjAvailableDiscordServerSettings } from "../../models/image-generation-mj-available-discord-server";

export type State = {
  availableDiscordServersSettings: ImageGenerationMjAvailableDiscordServerSettings[];
  activeAvailableDiscordServerSettings: ImageGenerationMjAvailableDiscordServerSettings | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyImageGenerationMjAvailableDiscordServerSettingsStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      availableDiscordServersSettings: [],
      activeAvailableDiscordServerSettings: undefined,
    };
  }

  getAvailableDiscordServerSettings() {
    return this.getField("availableDiscordServersSettings");
  }

  getAvailableDiscordServerSettingsObservable() {
    return this.getFieldObservable("availableDiscordServersSettings");
  }

  setAvailableDiscordServerSettings(availableDiscordServers: ImageGenerationMjAvailableDiscordServerSettings[]) {
    this.update((state) => ({ ...state, availableDiscordServersSettings: availableDiscordServers }));
  }

  getActiveAvailableDiscordServerSettings() {
    return this.getField("activeAvailableDiscordServerSettings");
  }

  getActiveAvailableDiscordServerSettingsObservable() {
    return this.getFieldObservable("activeAvailableDiscordServerSettings");
  }

  setActiveAvailableDiscordServerSettings(
    activeAvailableDiscordServerSettings: ImageGenerationMjAvailableDiscordServerSettings | undefined,
  ) {
    this.update((state) => ({ ...state, activeAvailableDiscordServerSettings: activeAvailableDiscordServerSettings }));
  }

  addSettings(settings: ImageGenerationMjAvailableDiscordServerSettings) {
    this.update((state) => {
      const storeAvailableDiscordServersSettings = state.availableDiscordServersSettings.find(
        (s) => s.id === settings.id,
      );
      if (storeAvailableDiscordServersSettings) {
        return state;
      }

      return {
        ...state,
        availableDiscordServersSettings: [settings].concat(state.availableDiscordServersSettings),
      };
    });
  }

  updateSettings(newSettings: ImageGenerationMjAvailableDiscordServerSettings) {
    const availableDiscordServersSettings = this.getAvailableDiscordServerSettings();
    const settings = availableDiscordServersSettings.find((s) => s.id === newSettings.id);
    if (settings) {
      this.update((state) => {
        const updatedSettings = state.availableDiscordServersSettings.map((s) => {
          if (s.id === newSettings.id) {
            return newSettings;
          }
          return s;
        });

        return {
          ...state,
          availableDiscordServersSettings: updatedSettings,
        };
      });
    } else {
      this.addSettings(newSettings);
    }

    const activeAvailableDiscordServersSettings = this.getActiveAvailableDiscordServerSettings();
    if (activeAvailableDiscordServersSettings?.id === newSettings.id) {
      this.update((state) => {
        return {
          ...state,
          activeAvailableDiscordServerSettings: newSettings,
        };
      });
    }
  }

  deleteSettings(id: number) {
    const availableDiscordServersSettings = this.getAvailableDiscordServerSettings();
    const settings = availableDiscordServersSettings.filter((s) => s.id !== id);
    this.update((state) => {
      return {
        ...state,
        availableDiscordServersSettings: settings,
      };
    });

    const activeAvailableDiscordServersSettings = this.getActiveAvailableDiscordServerSettings();
    if (activeAvailableDiscordServersSettings?.id === id) {
      this.update((state) => {
        return {
          ...state,
          activeAvailableDiscordServerSettings: undefined,
        };
      });
    }
  }
}
