import { Injectable } from "@angular/core";
import { RealtimeService } from "@metranpage/core";
import { ImageGenerationResultUpdate } from "../models/image-generation-update";
import { ImageGenerationService } from "./image-generation.service";
import { ImageGenerationStore } from "./image-generation.store";
import { VariantImageGenerationStore } from "./variant-image-generation.store";

@Injectable({
  providedIn: "root",
})
export class ImageGenerationRealtimeService {
  constructor(
    private readonly imageGenerationStore: ImageGenerationStore,
    private readonly variantImageGenerationStore: VariantImageGenerationStore,
    private readonly imageGenerationService: ImageGenerationService,
    realtimeService: RealtimeService,
  ) {
    realtimeService
      .getEvents<ImageGenerationResultUpdate>("image-generation-result")
      .subscribe((imageGenerationUpdate: ImageGenerationResultUpdate | undefined) => {
        if (imageGenerationUpdate) {
          const newImageGeneration = imageGenerationUpdate.imageGeneration;
          newImageGeneration.generatedImages = this.imageGenerationService.orderGeneratedImages(
            newImageGeneration?.generatedImages,
          );

          if (!newImageGeneration.rootGenerationId) {
            if (
              !["", "basic", "advanced", "creative", "face-swap"].includes(
                imageGenerationUpdate.imageGeneration.generationMode,
              )
            ) {
              return;
            }

            this.imageGenerationStore.updateImageGeneration(newImageGeneration);
          } else {
            this.variantImageGenerationStore.updateVariantImageGeneration(newImageGeneration);
          }

          if (imageGenerationUpdate.isError) {
            this.imageGenerationService.notifyOnImageGenerationError(
              imageGenerationUpdate.imageGeneration.generationMode,
            );
          }
        }
      });
  }
}
