import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CoverSnippetCategory, CoverSnippetCreateMetaData } from "@metranpage/book-data";
import { NotificationsPopUpService } from "@metranpage/core";
import { Subscription } from "rxjs";
import { CoverSnippetDataService } from "../../services/cover-snippet/cover-snippet-data.service";

@Component({
  selector: "m-cover-snippet-publishing-modal",
  templateUrl: "./cover-snippet-publishing-modal.view.html",
  styleUrls: ["./cover-snippet-publishing-modal.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverSnippetPublishingModal implements OnDestroy {
  @Output() close = new EventEmitter();
  @Output() create: EventEmitter<CoverSnippetCreateMetaData> = new EventEmitter<CoverSnippetCreateMetaData>();

  protected form!: FormGroup;

  sub: Subscription = new Subscription();

  protected selectedCategory?: CoverSnippetCategory;

  constructor(
    private readonly coverSnippetDataService: CoverSnippetDataService,
    private readonly formBuilder: FormBuilder,
    private readonly notificationService: NotificationsPopUpService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.createForm();
    this.watchFormChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  createForm() {
    this.form = this.formBuilder.group(
      {
        category: this.formBuilder.control<string>("", { nonNullable: true, validators: [Validators.required] }),
        subcategory: this.formBuilder.control<string>("", { nonNullable: true, validators: [Validators.required] }),
        isPremium: this.formBuilder.control<boolean>(false, { nonNullable: true, validators: [Validators.required] }),
        isVisibleToUsers: this.formBuilder.control<boolean>(true, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        isVisibleToOtherCompanies: this.formBuilder.control<boolean>(false, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        order: this.formBuilder.control<number>(0, { nonNullable: true, validators: [Validators.required] }),
        language: this.formBuilder.control<string>("", { nonNullable: true, validators: [Validators.required] }),
      },
      // { validator: [] },
    );
  }

  watchFormChanges() {
    this.sub.add(
      this.form.valueChanges.subscribe((v) => {
        this.selectedCategory = v.category;
      }),
    );
  }

  protected getCategoryOptions() {
    return this.coverSnippetDataService.getCategoryOptions();
  }

  protected getSubcategoryOptions() {
    return this.coverSnippetDataService.getSubcategoryOptions(this.selectedCategory);
  }

  protected getLanguageOptions() {
    return this.coverSnippetDataService.getLanguageOptions(this.selectedCategory);
  }

  protected onClose() {
    this.close.emit();
  }

  protected onCreate() {
    if (!this.form.valid) {
      this.checkFormErrors();
      return;
    }
    const formData = this.form.getRawValue();
    this.create.emit(formData);
  }

  private checkFormErrors() {
    this.markInvalidControlAsDirty();
    this.notifyOnFormError();
  }

  private markInvalidControlAsDirty(): void {
    const controls = this.form.controls;
    for (const k in controls) {
      const control = controls[k];
      if (!control.valid) {
        control.markAsDirty();
      }
    }
  }

  protected notifyOnFormError() {
    if (!this.form) {
      return;
    }

    this.notificationService.error($localize`:@@cover-editor.create-snippet-modal.form.error.notification:`);
  }
}
