import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import {
  ImageGenerationMjAvailableDiscordServerSettings,
  ImageGenerationMjAvailableDiscordServerSettingsDto,
} from "../../models/image-generation-mj-available-discord-server";

@Injectable({
  providedIn: "root",
})
export class AdminCompanyImageGenerationMjAvailableDiscordServerSettingsApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getImageGenerationMjAvailableDiscordServerSettings() {
    return this.get<ImageGenerationMjAvailableDiscordServerSettings[]>(
      "admin/company-image-generation-mj-available-discord-server-settings",
    );
  }

  async createImageGenerationMjAvailableDiscordServerSettings(
    data: ImageGenerationMjAvailableDiscordServerSettingsDto,
  ) {
    return this.post<ImageGenerationMjAvailableDiscordServerSettings>(
      "admin/company-image-generation-mj-available-discord-server-settings/create",
      data,
    );
  }

  async updateImageGenerationMjAvailableDiscordServerSettings(
    id: number,
    data: ImageGenerationMjAvailableDiscordServerSettingsDto,
  ) {
    return this.post<ImageGenerationMjAvailableDiscordServerSettings>(
      `admin/company-image-generation-mj-available-discord-server-settings/update/${id}`,
      data,
    );
  }

  async deleteImageGenerationMjAvailableDiscordServerSettings(id: number) {
    return this.delete<ImageGenerationMjAvailableDiscordServerSettings>(
      `admin/company-image-generation-mj-available-discord-server-settings/${id}`,
    );
  }
}
