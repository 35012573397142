import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Book, BookPrice, BookUpdate, UpdateBookTypes } from "@metranpage/book-data";
import { CompanyStore } from "@metranpage/company";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import {
  AnalyticsService,
  BreadcrumbsService,
  IS_PURCHASES_AVAILABLE,
  LoadingService,
  LoadingState,
  NotificationsPopUpService,
  RealtimeService,
  filterUndefined,
} from "@metranpage/core";
import { FormatService } from "@metranpage/format-data";
import { OnboardingService } from "@metranpage/onboarding";
import { PricingService, PricingViewService } from "@metranpage/pricing";
import { ActiveSubscription, PaymentCurrency, Tariff } from "@metranpage/pricing-data";
import { ThemeService } from "@metranpage/theme";
import { RewardsService } from "@metranpage/user";
import { RewardsStore, User, UserRewardOneTime, UserStore } from "@metranpage/user-data";
import { UserVerificationOverlayServiceInterface } from "@metranpage/user-interfaces";
import { BalanceData, PaymentData } from "@metranpage/user-payment-data";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Observable, Subscription, combineLatest, map } from "rxjs";
import { filter, first, skip } from "rxjs/operators";
import { BookRouterService } from "../../services/book-router.service";
import { BookService } from "../../services/book.service";
import { BooksApi } from "../../services/books.api";
import { BooksStore } from "../../services/books.store";
import { CreateProjectEvent, NewProjectService } from "../../services/new-project.service";

@UntilDestroy()
@Component({
  selector: "m-preview-page",
  templateUrl: "./preview.page.html",
  styleUrls: ["./preview.page.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class PreviewPage implements OnInit, OnDestroy {
  books$: Observable<Book[]>;
  books: Book[] = [];
  book?: Book;
  previews$!: Observable<string[]>;
  previewsThumbnails$!: Observable<string[]>;
  previews: string[] = [];
  loadingState$: Observable<LoadingState>;

  redirectOnLayoutFinishUrl?: string;

  protected user?: User;
  protected selectedLeftPage = 0;
  protected isPaymentsModalVisible = false;
  protected isLowBalanceModalVisible = false;
  protected isFreemiumModalVisible = false;
  protected isVerificationEmailModalVisible = false;
  protected isChangeTariffModalVisible = false;
  protected isPreviewsActual = false;
  protected isPrintPreviewsReady = false;
  protected isEpubPreviewsReady = false;
  protected isFinalsActual = false;
  protected isPrintFinalsReady = false;
  protected isEpubFinalsReady = false;
  protected layoutStep = "start";
  protected showLinksSidebar = false;
  protected showLayoutStepsSidebar = false;
  protected shouldSendReadyEmail = false;
  protected paymentData!: PaymentData;
  protected balanceData!: BalanceData;
  protected activeSubscription?: ActiveSubscription;
  protected higherTariff?: Tariff;
  protected hasPaidTariff = false;
  protected hasTrialPeriod = false;
  protected hasUnlimitedTariff = false;
  protected onboardingStarted = false;

  protected rewardsOneTime: UserRewardOneTime[] = [];
  protected currency: PaymentCurrency = "RUB";

  private sub = new Subscription();

  tariffsForUpgrade$!: Observable<Tariff[]>;
  private previewUpdating = false;

  protected isBookPreviewError = false;
  protected isBookFinalError = false;

  protected hasPremium = true;
  protected isPremiumLabelVisible = false;
  protected isPremiumModalVisible = false;

  protected form!: FormGroup;

  protected bookPrice?: BookPrice;
  protected amount = 0;
  protected selectedTypes?: UpdateBookTypes;

  protected isUpdatePreviewModalVisible = false;

  protected canProduceEpub = true;

  constructor(
    private readonly bookService: BookService,
    private readonly booksStore: BooksStore,
    private readonly bookRouterService: BookRouterService,
    private readonly router: Router,
    private readonly notificationService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private readonly booksApi: BooksApi,
    private readonly analytics: AnalyticsService,
    private readonly breadcrumbsService: BreadcrumbsService,
    private readonly pricingService: PricingService,
    private readonly onboardingService: OnboardingService,
    private readonly themeService: ThemeService,
    private readonly formatService: FormatService,
    private readonly companyStore: CompanyStore,
    private readonly rewardsService: RewardsService,
    private readonly pricingViewService: PricingViewService,
    @Inject(IS_PURCHASES_AVAILABLE) protected readonly isPurchasesAvailable: boolean,
    @Inject("UserVerificationOverlayService")
    protected readonly userVerificationOverlayService: UserVerificationOverlayServiceInterface,
    rewardsStore: RewardsStore,
    userStore: UserStore,
    private readonly realtimeService: RealtimeService,
    private readonly newProjectService: NewProjectService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.createForm();
    this.watchFormChanges();

    this.loadingState$ = this.loadingService.loadingState$;

    this.sub.add(
      this.onboardingService.onStartOnboarding$.pipe().subscribe(() => {
        this.startOnboarding(true);
      }),
    );

    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        this.user = user;
      }),
    );

    this.books$ = booksStore.getBooksObservable().pipe(map((bl) => bl.filter((b) => b.isVisibleToUser)));
    this.sub.add(
      this.books$.subscribe((books) => {
        this.books = books;
        this.updatePremiumLabel();
      }),
    );

    this.sub.add(
      userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        this.activeSubscription = activeSubscription;
        this.hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
        this.hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;
        this.hasUnlimitedTariff = activeSubscription?.tariff?.isUnlimited ?? false;

        if (!this.activeSubscription) {
          return;
        }
        this.getHigherTariff();

        this.hasPremium = this.pricingService.hasPremium(this.activeSubscription);
        this.updatePremiumLabel();
      }),
    );

    this.sub.add(
      companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.redirectOnLayoutFinishUrl = company?.redirectOnLayoutFinishUrl;
          this.currency = company.currency;
          this.canProduceEpub = company.canProduceEpub;
        }),
    );

    this.tariffsForUpgrade$ = combineLatest([
      userStore.getActiveSubscriptionObservable(),
      pricingService.getTariffsForCompany(),
    ]).pipe(
      map(([subscription, tariffs]) => ({ subscription, tariffs: tariffs.filter((v) => v.isFree === false) })),
      map((info) => {
        if (!info.subscription || info.subscription.tariff.isFree) {
          return info.tariffs.filter((t) => t.period === 1);
        }
        return info.tariffs.filter((t) => t.period === info.subscription?.tariff.period);
      }),
    );

    this.sub.add(
      rewardsStore.getRewardsOneTimeObservable().subscribe((rewards) => {
        this.rewardsOneTime = rewards;
      }),
    );

    this.sub.add(
      this.realtimeService
        .getEvents<BookUpdate>("book-state")
        .pipe(filter((state) => state !== undefined && state?.bookId === this.book?.id))
        .subscribe((state) => {
          if (state.errorReason === "preview") {
            this.isBookPreviewError = true;
          }
        }),
    );

    this.sub.add(
      newProjectService.createProjectEvent$.pipe(filterUndefined()).subscribe((event) => {
        newProjectService.resetNewProjectModal();
        this.onAddNewProjectClick(event);
      }),
    );
  }

  ngOnInit(): void {
    this.analytics.event("book-preview");

    this.loadingService.startLoading({ fullPage: true });

    this.previews$ = this.booksStore.getActiveBookObservable().pipe(
      filter((b) => !!b),
      map((book) => {
        if (book?.bookResults?.previews) {
          this.previews = book?.bookResults?.previews;
          this.startOnboarding();

          book?.bookResults.previews.sort((a, b) => a.localeCompare(b, "en", { numeric: true }));
          return book?.bookResults.previews.map((url) => {
            return this.booksApi.getUrlForBookFile(book?.id, `previews/${url}`);
          });
        }
        return [];
      }),
    );

    this.previewsThumbnails$ = this.booksStore.getActiveBookObservable().pipe(
      filter((b) => !!b),
      map((book) => {
        if (book?.bookResults?.previewsThumbnails) {
          book?.bookResults.previewsThumbnails.sort((a, b) => a.localeCompare(b, "en", { numeric: true }));
          return book?.bookResults.previewsThumbnails.map((url) => {
            return this.booksApi.getUrlForBookFile(book?.id, `previews-thumbnails/${url}`);
          });
        }
        return [];
      }),
    );

    this.sub.add(
      this.booksStore
        .getActiveBookObservable()
        .pipe(filterUndefined(), first())
        .subscribe(async (book) => {
          this.book = book;
          this.updateBookResultState(book);

          await this.updateBookPrice(book);
          this.updateExportVersions(book);

          // stop fullpage loading
          this.loadingService.stopLoading();
          this.breadcrumbsService.enableBreadcrumbs();

          if (book.actionKey !== "preview" && book.actionKey !== "final" && !this.isPreviewsActual) {
            // if no current action and previews not actual - make preview
            await this.makePreview();
          } else if (book?.actionKey === "preview") {
            this.loadingService.startLoading({
              description: $localize`:@@books.styles.action-preview-hint:`,
            });
            this.breadcrumbsService.disableBreadcrumbs();
          } else if (book?.actionKey === "final") {
            this.loadingService.startLoading({
              description: $localize`:@@books.styles.action-final-hint:`,
            });
            this.breadcrumbsService.disableBreadcrumbs();
          } else if (!book.actionKey && this.isLayoutFinished("final")) {
            if (this.redirectOnLayoutFinishUrl) {
              window.location.href = this.redirectOnLayoutFinishUrl;
            }
          }
        }),
    );

    this.sub.add(
      this.booksStore
        .getActiveBookObservable()
        .pipe(filterUndefined(), skip(1))
        .subscribe(async (book) => {
          this.book = book;
          this.updateBookResultState(book);

          await this.updateBookPrice(book);
          this.updateExportVersions(book);

          this.loadingService.stopLoading();

          // adding flag previewUpdating and reset its params
          // because sometimes observable triggered before get
          // updated book.actionKey
          this.resetPreviewFlag(book?.actionKey);

          if (!book?.actionKey) {
            this.checkPreviewUpdate();

            // if (this.isPreviewsActual || this.isFinalsActual) {
            this.breadcrumbsService.enableBreadcrumbs();
            // }

            if (this.isLayoutFinished("final")) {
              const message = {
                type: "final-done",
                payload: { bookId: book?.id, integrationProjectId: book?.integrationProjectId },
              };
              window.postMessage(JSON.stringify(message), "*");
              window.parent.postMessage(JSON.stringify(message), "*");
              console.log("Final version is done, posted message", message);

              if (this.redirectOnLayoutFinishUrl) {
                window.location.href = this.redirectOnLayoutFinishUrl;
              }
            }
          } else if (book?.actionKey === "preview") {
            this.loadingService.startLoading({
              description: $localize`:@@books.styles.action-preview-hint:`,
            });
            this.breadcrumbsService.disableBreadcrumbs();
          } else if (book?.actionKey === "final") {
            this.loadingService.startLoading({
              description: $localize`:@@books.styles.action-final-hint:`,
            });
            this.breadcrumbsService.disableBreadcrumbs();
          }
        }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  startOnboarding(showForced = false) {
    if (this.previews.length && (!this.onboardingStarted || showForced)) {
      this.onboardingStarted = true;
      this.onboardingService.startOnboarding("preview-page", showForced, 500);
    }
  }

  private checkPreviewUpdate(): void {
    if (
      ((this.book?.exportPrint && !this.isPrintPreviewsReady) ||
        (this.book?.exportEpub && !this.isEpubPreviewsReady)) &&
      !this.previewUpdating &&
      !this.isBookPreviewError
    ) {
      this.makePreview();
    }
  }

  private resetPreviewFlag(actionKey?: string): void {
    if (!actionKey) {
      return;
    }

    if (actionKey === "preview" || actionKey === "final") {
      this.previewUpdating = false;
    }
  }

  getThemeSpecificImageSource(): string {
    const themeSuffix = this.themeService.getThemeSuffix();
    return `/assets/img/info-modal-free-layout-${themeSuffix}.png`;
  }

  getThemeEpub(): string {
    const themeSuffix = this.themeService.getThemeSuffix();
    return `/assets/img/epub-version-${themeSuffix}.png`;
  }

  // getText() {
  //   if (this.direction === "column") {
  //     return $localize`:@@books.preview.preview-info-text:`;
  //   }
  //   return;
  // }

  private updateBookResultState(book: Book) {
    this.isPrintPreviewsReady = book?.bookResults?.isPrintPreviewsReady ?? false;
    this.isEpubPreviewsReady = book?.bookResults?.isEpubPreviewsReady ?? false;
    this.isPrintFinalsReady = book?.bookResults?.isPrintFinalsReady ?? false;
    this.isEpubFinalsReady = book?.bookResults?.isEpubFinalsReady ?? false;
    this.layoutStep = book?.bookResults?.layoutStep ?? "start";

    if (book?.bookResults?.previewTime && book?.bookSettings?.updatedAt) {
      const isPreviewsActual = book?.bookResults?.previewTime > book?.bookSettings?.updatedAt;
      this.isPreviewsActual = isPreviewsActual;
      if (book.exportPrint && book.exportEpub) {
        this.isPreviewsActual =
          isPreviewsActual && book?.bookResults?.isPrintPreviewsReady && book?.bookResults?.isEpubPreviewsReady;
      } else if (book.exportPrint) {
        this.isPreviewsActual = isPreviewsActual && book?.bookResults?.isPrintPreviewsReady;
      } else if (book.exportEpub) {
        this.isPreviewsActual = isPreviewsActual && book?.bookResults?.isEpubPreviewsReady;
      }
    }
    if (book?.bookResults?.finalTime && book?.bookSettings?.updatedAt) {
      const isFinalsActual = book?.bookResults?.finalTime > book?.bookSettings?.updatedAt;
      this.isFinalsActual = isFinalsActual;
      if (book.exportPrint && book.exportEpub) {
        this.isFinalsActual =
          isFinalsActual && book?.bookResults?.isPrintFinalsReady && book?.bookResults?.isEpubFinalsReady;
      } else if (book.exportPrint) {
        this.isFinalsActual = isFinalsActual && book?.bookResults?.isPrintFinalsReady;
      } else if (book.exportEpub) {
        this.isFinalsActual = isFinalsActual && book?.bookResults?.isEpubFinalsReady;
      }
    }

    this.showLinksSidebar = false;
    this.showLayoutStepsSidebar = false;

    if (!book?.actionKey && this.isPreviewsActual && !this.isFinalsActual) {
      this.showLinksSidebar = true;
    } else if (
      (!book?.actionKey && this.isPreviewsActual && this.isFinalsActual) ||
      book?.actionKey === "preview" ||
      book?.actionKey === "final" ||
      (!this.isPreviewsActual && !this.isFinalsActual)
    ) {
      this.showLayoutStepsSidebar = true;
    }
  }

  protected onSelectLeftPage(value: number) {
    this.selectedLeftPage = value;
  }

  protected async onProduceClick() {
    this.notificationService.closeAll();
    const activeBook = this.booksStore.getActiveBook()!;

    if (!this.user?.isVerified) {
      this.userVerificationOverlayService.open();
      return;
    }

    const types = this.form.value;
    if (!types.exportPrint && !types.exportEpub) {
      this.notificationService.error($localize`:@@books.error.select-type-before-finals:`);
      return;
    }

    if (this.hasUnlimitedTariff) {
      await this.makeFinalAfterPayment(false);
    } else {
      await this.calculatePaymentData(activeBook);
    }
  }

  protected onEditClick(step: string) {
    const formatData = this.formatService.getFormatData(
      this.book?.bookSettings?.width,
      this.book?.bookSettings?.height,
    );
    if (step === "upload") {
      this.bookRouterService.showModal(this.book!, "upload", "edit");
      return;
    }
    if (step === "template") {
      this.bookRouterService.showModal(this.book!, "templates", "edit");
      return;
    }
    if (step === "book-data") {
      this.bookRouterService.showModal(this.book!, "book-data", "edit");
      return;
    }

    this.router.navigate(["books", this.booksStore.getActiveBook()?.id, step]);
  }

  protected showPricingModal() {
    if (this.paymentData.bookPrice.total === 0) {
      this.isFreemiumModalVisible = true;
      return;
    }

    if (this.paymentData.userBalance.credits >= this.paymentData.bookPrice.total) {
      this.isPaymentsModalVisible = true;
      return;
    }

    this.isLowBalanceModalVisible = true;
  }

  protected closePricingModal() {
    this.isPaymentsModalVisible = false;
    this.isLowBalanceModalVisible = false;
  }

  protected async onLayoutFreeBook() {
    this.closeFreemiumModal();
    await this.makeFinalAfterPayment(false);
  }

  protected closeFreemiumModal() {
    this.isFreemiumModalVisible = false;
  }

  protected async onPayGoldCredits(value: number) {
    this.analytics.event("book-layout-final", { goldCredits: value });

    this.closePricingModal();
    await this.makeFinalAfterPayment(true);
  }

  protected async onPayCredits(value: number) {
    this.analytics.event("book-layout-final", { credits: value });

    this.closePricingModal();
    await this.makeFinalAfterPayment(false);
  }

  protected onCreditsBuy(credits: number) {
    window.open(`payments/await-payment-link?creditsCount=${credits}`, "_blank");
  }

  async makePreview() {
    this.analytics.event("book-layout-create-preview");

    this.previewUpdating = true;

    this.notificationService.closeAll();

    this.loadingService.startLoading({ description: $localize`:@@books.styles.action-preview-hint:` });
    this.breadcrumbsService.disableBreadcrumbs();

    this.showLayoutStepsSidebar = true;
    this.layoutStep = "start";

    const activeBook = this.booksStore.getActiveBook()!;
    const result = await this.bookService.producePreview(activeBook);
    if (result === "success") {
      this.shouldSendReadyEmail = true;
    } else {
      this.isBookPreviewError = true;
      this.notificationService.error($localize`:@@books.build.cant-start-preview-error:`);
      this.breadcrumbsService.enableBreadcrumbs();
      this.loadingService.stopLoading();
    }
  }

  private async calculatePaymentData(book: Book) {
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.bookService.setBookType(this.book!, this.selectedTypes!);
    if (result !== "success") {
      this.notificationService.error($localize`:@@books.build.cant-start-processing-error:`);
      this.loadingService.stopLoading();
      return;
    }

    const paymentData = await this.bookService.checkCredits(book);
    if (paymentData === "error") {
      this.notificationService.error($localize`:@@books.build.cant-start-processing-error:`);
      this.loadingService.stopLoading();
      return;
    }

    this.paymentData = paymentData;
    this.balanceData = {
      price: paymentData.bookPrice.total,
      userBalance: paymentData.userBalance,
    };
    this.showPricingModal();
    this.loadingService.stopLoading();
  }

  private async makeFinalAfterPayment(withGoldCredit: boolean) {
    this.analytics.event("book-layout-create-final");

    this.notificationService.closeAll();
    this.loadingService.startLoading({ description: $localize`:@@books.book.action-final:` });
    this.breadcrumbsService.disableBreadcrumbs();

    this.showLayoutStepsSidebar = true;
    this.layoutStep = "start";

    const activeBook = this.booksStore.getActiveBook()!;
    const result = await this.bookService.produceFinal(activeBook, withGoldCredit);
    if (result === "success") {
      // this.loadingService.stopLoading();
      // this.router.navigateByUrl('/');
    } else {
      this.notificationService.error($localize`:@@books.build.cant-start-processing-error:`);
      this.breadcrumbsService.enableBreadcrumbs();
      this.loadingService.stopLoading();
    }
  }

  protected async showNewProjectModal() {
    if (this.books.length >= 1 && !this.hasPremium) {
      this.showPremiumModal();
      return;
    }

    if (
      this.books.length >= (this.activeSubscription?.tariff.activeProjects || 1) &&
      !this.activeSubscription?.tariff.isUnlimited
    ) {
      this.isChangeTariffModalVisible = true;
      return;
    }

    this.newProjectService.selectNewProjectType();
  }

  protected async onAddNewProjectClick(event: CreateProjectEvent) {
    if (
      this.books.length >= (this.activeSubscription?.tariff.activeProjects || 1) &&
      !this.activeSubscription?.tariff.isUnlimited
    ) {
      this.isChangeTariffModalVisible = true;
      return;
    }
    this.analytics.event("preview-new-project");

    await this.bookService.onAddNewProjectClick(event);
  }

  protected async onDownloadProjectClick() {
    this.analytics.event("book-save-zip");

    const activeBook = this.booksStore.getActiveBook()!;
    const fileName = activeBook?.bookResults?.finalPackageUrl;
    if (fileName) {
      await this.bookService.downloadFinal(activeBook, fileName, "");
    }
  }

  protected async onDownloadPreviewEpubClick() {
    this.analytics.event("book-save-epub");

    const activeBook = this.booksStore.getActiveBook()!;
    // const fileName = activeBook?.bookResults?.finalPackageUrl;
    const fileName = activeBook?.bookResults?.epubUrl;
    if (fileName) {
      await this.bookService.downloadEpub(activeBook, fileName);
    }
  }

  protected onClickChangeTariffModal() {
    this.router.navigate(["payments", "subscription"]);
  }

  protected onCloseChangeTariffModal() {
    this.isChangeTariffModalVisible = false;
  }

  protected async getHigherTariff() {
    this.higherTariff = await this.pricingService.getHigherTariff();
  }

  protected getInfoModalTitle() {
    return this.bookService.getInfoModalTitle(this.higherTariff);
  }

  protected getInfoModalText() {
    return this.bookService.getInfoModalText(this.activeSubscription, this.higherTariff);
  }

  protected isLayoutFinished(mode: "preview" | "final") {
    return this.bookService.isLayoutFinished(this.book, mode);
  }

  protected getSubscribeToTelegramChannelReward() {
    return this.rewardsService.getSubscribeToTelegramChannelReward(this.rewardsOneTime);
  }

  protected showPremiumModal() {
    this.isPremiumModalVisible = true;
  }

  protected closePremiumModal() {
    this.isPremiumModalVisible = false;
  }

  private updatePremiumLabel() {
    this.isPremiumLabelVisible = !this.hasPremium && !!this.books.length;
  }

  private createForm() {
    this.form = new FormGroup({
      exportPrint: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.required] }),
      exportEpub: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.required] }),
    });
  }

  private updateExportVersions(book: Book) {
    this.selectedTypes = {
      exportPrint: book.exportPrint,
      exportEpub: book.exportEpub,
    };
    if (!this.canProduceEpub) {
      this.selectedTypes.exportEpub = false;
    }
    this.updateAmount(this.selectedTypes);
    this.form.patchValue(this.selectedTypes, { emitEvent: false });
    this.cdr.markForCheck();
  }

  private watchFormChanges() {
    this.form?.valueChanges
      .pipe(
        // debounceTime(300),
        // distinctUntilChanged((previous, current) => JSON.stringify(previous) === JSON.stringify(current)),
      )
      .subscribe(async (types) => {
        this.updateAmount(types);

        if (
          (types.exportPrint && !this.book?.bookResults?.isPrintPreviewsReady) ||
          (types.exportEpub && !this.book?.bookResults?.isEpubPreviewsReady)
        ) {
          this.showUpdatePreviewModal();
          this.cdr.markForCheck();
          return;
        }

        this.selectedTypes = types;
        this.cdr.markForCheck();
      });
  }

  private updateAmount(types: UpdateBookTypes) {
    if (!this.bookPrice) {
      return;
    }

    this.amount = 0;
    if (types.exportPrint && types.exportEpub) {
      this.amount = this.bookPrice.printAndEpub;
      return;
    }
    if (types.exportPrint) {
      this.amount = this.bookPrice.print;
      return;
    }
    if (types.exportEpub) {
      this.amount = this.bookPrice.epub;
      return;
    }
  }

  private async updateBookPrice(book: Book) {
    const bookPriceData = await this.bookService.getBookPrice(book);
    if (bookPriceData !== "error") {
      this.bookPrice = bookPriceData;
    }
  }

  protected async onSubmitUpdatePreview() {
    this.closeUpdatePreviewModal();
    this.selectedTypes = this.form.value;
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.bookService.setBookType(this.book!, this.selectedTypes!);
    if (result !== "success") {
      this.notificationService.error($localize`:@@books.build.cant-start-processing-error:`);
    }
    this.loadingService.stopLoading();
  }

  protected onCancelUpdatePreview() {
    if (!this.selectedTypes) {
      return;
    }
    this.updateAmount(this.selectedTypes);
    this.form.patchValue(this.selectedTypes, { emitEvent: false });
    this.closeUpdatePreviewModal();
  }

  protected showUpdatePreviewModal() {
    this.isUpdatePreviewModalVisible = true;
  }

  protected closeUpdatePreviewModal() {
    this.isUpdatePreviewModalVisible = false;
  }

  @HostListener("click", ["$event", "$event.target.tagName"])
  onClick(_event: MouseEvent | KeyboardEvent, _targetTag: unknown) {
    const activeBook = this.booksStore.getActiveBook()!;
    if (activeBook.bookResults?.isPrintPreviewsReady && this.shouldSendReadyEmail) {
      this.shouldSendReadyEmail = false;
      this.bookService.trackActiveBook(activeBook);
    }
  }
}
