import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { fadeInOutOnEnterLeave, slideInOutVertical } from "@metranpage/components";
import {
  ImageGenerationPrices,
  ImageGenerationStylizationData,
  ImageGenerationStylizationService,
  ImageGenerationStylizationStyle,
  ImageGenerationStylizationStylesData,
  ImageGenerationStylizationUserStyleService,
} from "@metranpage/image-generation";
import { UserBalance } from "@metranpage/user-data";
import { CoverService } from "../../services/cover/cover.service";

@Component({
  selector: "m-cover-object-stylization-settings",
  templateUrl: "./cover-object-stylization.component.html",
  styleUrl: "./cover-object-stylization.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutOnEnterLeave, slideInOutVertical],
})
export class CoverObjectStylizationComponent implements OnChanges {
  @Input() stylizationStyles: ImageGenerationStylizationStyle[] = [];
  @Input() isStylizationCollapsed = true;
  @Input() prices?: ImageGenerationPrices;
  @Input() balance?: UserBalance;
  @Input() stylizationStylesData: ImageGenerationStylizationStylesData = {
    stylizationStyleId: 1,
    stylizationUserStyleId: undefined,
    stylizationUserStyle: undefined,
  };
  @Input() hasTooltip = true;

  @Output() generateStylization = new EventEmitter<ImageGenerationStylizationData>();
  @Output() showSelectStylizationStylesModal = new EventEmitter<void>();

  protected selectedStylizationStyleId?: number;
  protected selectedStylizationUserStyleId?: number;

  protected stylizationUserStyleImageUrl?: string;
  protected isStylizationUserStyleHovered = false;

  constructor(
    private readonly coverService: CoverService,
    private readonly imageGenerationStylizationService: ImageGenerationStylizationService,
    private readonly imageGenerationStylizationUserStyleService: ImageGenerationStylizationUserStyleService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stylizationStylesData) {
      this.updateStyles();
    }
  }

  protected async onGenerateStylizeClick() {
    if (!this.selectedStylizationStyleId && !this.selectedStylizationUserStyleId) {
      return;
    }
    this.generateStylization.emit({
      styleId: this.selectedStylizationStyleId,
      userStyleId: this.selectedStylizationUserStyleId,
    });
  }

  protected onShowSelectStylizationStylesModalClick() {
    this.showSelectStylizationStylesModal.emit();
  }

  protected selectStylizationUserStyle() {
    this.selectedStylizationUserStyleId = this.stylizationStylesData?.stylizationUserStyle?.id;
    this.selectedStylizationStyleId = undefined;
  }

  protected selectStylizationStyleClick(stylizationStyle: ImageGenerationStylizationStyle) {
    this.selectedStylizationUserStyleId = undefined;
    this.selectedStylizationStyleId = stylizationStyle.id;
  }

  trackById(index: number, stylizationStyle: ImageGenerationStylizationStyle) {
    return stylizationStyle.id;
  }

  protected isGenerationDisabled(): boolean {
    return !this.prices || (!this.selectedStylizationStyleId && !this.selectedStylizationUserStyleId);
  }

  protected updateStyles() {
    this.selectedStylizationStyleId = this.checkSelectedStyle(this.stylizationStylesData.stylizationStyleId);
    this.selectedStylizationUserStyleId = this.stylizationStylesData.stylizationUserStyleId;

    if (!this.selectedStylizationStyleId && !this.selectedStylizationUserStyleId) {
      this.selectedStylizationStyleId = this.stylizationStyles[0]?.id;
    }

    this.updateUserStyleImage();

    this.cdr.detectChanges();
  }

  protected updateUserStyleImage() {
    if (!this.stylizationStylesData?.stylizationUserStyle) {
      this.stylizationUserStyleImageUrl = undefined;
      return;
    }

    this.stylizationUserStyleImageUrl = this.imageGenerationStylizationUserStyleService.getUrlForImage(
      this.stylizationStylesData.stylizationUserStyle,
      true,
    );
  }

  protected isStylizationUserStyleActive(): boolean {
    return (
      !!this.stylizationStylesData?.stylizationUserStyle &&
      this.selectedStylizationUserStyleId === this.stylizationStylesData.stylizationUserStyle?.id
    );
  }

  getPrice() {
    let hasElements = false;
    let hasAdditionalRemoveBackground = false;
    let hasUserStyle = false;
    if (this.selectedStylizationStyleId) {
      const stylizationStylePaymentData = this.imageGenerationStylizationService.getStylizationStylePaymentData(
        this.selectedStylizationStyleId,
      );
      hasElements = stylizationStylePaymentData.hasElements;
      hasAdditionalRemoveBackground = stylizationStylePaymentData.hasAdditionalRemoveBackground;
    }
    if (this.selectedStylizationUserStyleId) {
      hasUserStyle = true;
    }
    return this.coverService.getPrice(
      this.prices,
      "stylization",
      hasElements,
      hasAdditionalRemoveBackground,
      hasUserStyle,
    );
  }

  checkSelectedStyle(stylizationStyleId: number | undefined): number | undefined {
    const style = this.stylizationStyles.find((s) => s.id === stylizationStyleId);
    return style?.id;
  }
}
