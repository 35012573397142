<m-modal position="center" [closeButtonVisible]="true" (close)="onClose()">
  <div class="container">
    <div class="header" i18n="@@cover-editor.create-snippet-modal.title">cover-editor.create-snippet-modal.title</div>

    <div class="divider-hor"></div>

    <div class="content">
      <form [formGroup]="form" class="content">
        <div class="section">
          <span class="label" i18n="@@cover-editor.create-snippet-modal.category.title">
            cover-editor.create-snippet-modal.category.title
          </span>
          <m-select formControlName="category" size="s-plus" [options]="getCategoryOptions()" />
        </div>

        <div class="section">
          <span class="label" i18n="@@cover-editor.create-snippet-modal.subcategory.title">
            cover-editor.create-snippet-modal.subcategory.title
          </span>
          <m-select formControlName="subcategory" size="s-plus" [options]="getSubcategoryOptions()" />
        </div>

        <div class="section">
          <span class="label" i18n="@@cover-editor.create-snippet-modal.language.title">
            cover-editor.create-snippet-modal.language.title
          </span>
          <m-select formControlName="language" size="s-plus" [options]="getLanguageOptions()" />
        </div>

        <!-- <div class="section">
          <m-switch formControlName="isPremium">
            <span class="label" i18n="@@cover-editor.create-snippet-modal.is-premium.title">
              cover-editor.create-snippet-modal.is-premium.title
            </span>
          </m-switch>
        </div> -->

        <div class="section">
          <m-switch formControlName="isVisibleToUsers">
            <span class="label" i18n="@@cover-editor.create-snippet-modal.is-visible-to-users.title">
              cover-editor.create-snippet-modal.is-visible-to-users.title
            </span>
          </m-switch>
        </div>

        <div class="section">
          <m-switch formControlName="isVisibleToOtherCompanies">
            <span class="label" i18n="@@cover-editor.create-snippet-modal.is-visible-to-other-companies.title">
              cover-editor.create-snippet-modal.is-visible-to-other-companies.title
            </span>
          </m-switch>
        </div>
      </form>

      <div>
        <m-button
          button-style="primary"
          size="full-width"
          (onClick)="onCreate()"
          i18n="@@cover-editor.create-snippet-modal.send-for-moderation.button"
        >
          cover-editor.create-snippet-modal.send-for-moderation.button
        </m-button>
      </div>
    </div>
  </div>
</m-modal>
