import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import {
  ImageGenerationStylization,
  ImageGenerationStylizationDataDto,
  ImageGenerationStylizationStyleDto,
  ImageGenerationStylizationStylesData,
} from "../../models/image-generation-stylization/image-generation-stylization";

@Injectable({
  providedIn: "root",
})
export class ImageGenerationStylizationApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async stylizationImageGeneration(data: ImageGenerationStylizationDataDto, file: File) {
    const formData: FormData = new FormData();

    formData.append("file", file, file.name);

    for (const [key, value] of Object.entries(data)) {
      if (typeof value !== "string") {
        formData.append(key, JSON.stringify(value));
        continue;
      }
      formData.append(key, value);
    }

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post<ImageGenerationStylization | undefined>(
      "image-generation-stylization/stylization-image-generation",
      formData,
      {
        headers,
      },
    );
  }

  async getImageGenerationStylizationStyles() {
    return this.get<ImageGenerationStylizationStyleDto[]>("image-generation-stylization/styles-stylization");
  }

  async getImageGenerationStylizationStylesData(bookId: number) {
    return this.get<ImageGenerationStylizationStylesData>(`image-generation-stylization/styles-data/${bookId}`);
  }
}
