import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { IslandComponent } from "@metranpage/design-system";
import { AdminMenuItemComponent } from "../../../common/components/menu-item.component";

@Component({
  selector: "m-admin-nav-view",
  template: `
    <m-ng-island [paddingSize]="16">
      <div class="container">
        <m-admin-menu-item-component
          routerLink="/admin/company"
          routerLinkActive="active"
          title="admin.nav.company"
          i18n-title="@@admin.nav.company"
          icon="/assets/icons/admin-nav-company.svg"
        />

        <div class="divider"></div>

        <m-admin-menu-item-component
          routerLink="/admin/users"
          routerLinkActive="active"
          title="admin.nav.users"
          i18n-title="@@admin.nav.users"
          icon="/assets/icons/admin-nav-user.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/referal-links"
          routerLinkActive="active"
          title="admin.nav.referal-links"
          i18n-title="@@admin.nav.referal-links"
          icon="/assets/icons/admin-nav-referal-links.svg"
        />

        <div class="divider"></div>

        <m-admin-menu-item-component
          routerLink="/admin/tariffs"
          routerLinkActive="active"
          title="admin.nav.tariffs"
          i18n-title="@@admin.nav.tariffs"
          icon="/assets/icons/admin-nav-tariffs.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/promocodes"
          routerLinkActive="active"
          title="admin.nav.promocodes"
          i18n-title="@@admin.nav.promocodes"
          icon="/assets/icons/admin-nav-promocode.svg"
        />

        <div class="divider"></div>

        <m-admin-menu-item-component
          routerLink="/admin/rewards-onetime"
          routerLinkActive="active"
          title="admin.nav.rewards-onetime"
          i18n-title="@@admin.nav.rewards-onetime"
          icon="/assets/icons/admin-nav-api.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/rewards-daily"
          routerLinkActive="active"
          title="admin.nav.rewards-daily"
          i18n-title="@@admin.nav.rewards-daily"
          icon="/assets/icons/admin-nav-api.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/telegram-settings"
          routerLinkActive="active"
          title="admin.nav.telegram-settings"
          i18n-title="@@admin.nav.telegram-settings"
          icon="/assets/icons/admin-nav-api.svg"
        />

        <div class="divider"></div>

        <m-admin-menu-item-component
          routerLink="/admin/fonts"
          routerLinkActive="active"
          title="admin.nav.fonts"
          i18n-title="@@admin.nav.fonts"
          icon="/assets/icons/admin-nav-fonts.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/social-network-covers"
          routerLinkActive="active"
          title="admin.nav.social-network-covers"
          i18n-title="@@admin.nav.social-network-covers"
          icon="/assets/icons/admin-nav-fonts.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/templates"
          routerLinkActive="active"
          title="admin.nav.templates"
          i18n-title="@@admin.nav.templates"
          icon="/assets/icons/admin-nav-template.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/non-editable-templates"
          routerLinkActive="active"
          title="admin.nav.non-editable-templates"
          i18n-title="@@admin.nav.non-editable-templates"
          icon="/assets/icons/admin-nav-non-editable-template.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/edition-notice-settings"
          routerLinkActive="active"
          title="admin.nav.edition-notice-settings"
          i18n-title="@@admin.nav.edition-notice-settings"
          icon="/assets/icons/admin-nav-api.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/print-settings"
          routerLinkActive="active"
          title="admin.nav.print-settings"
          i18n-title="@@admin.nav.print-settings"
          icon="/assets/icons/admin-nav-api.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/formats"
          routerLinkActive="active"
          title="admin.nav.formats"
          i18n-title="@@admin.nav.formats"
          icon="/assets/icons/admin-nav-api.svg"
        />

        <div class="divider"></div>

        <m-admin-menu-item-component
          routerLink="/admin/cover-templates"
          routerLinkActive="active"
          title='Шаблоны обложек'
          i18n-title="@@admin.nav.cover-templates"
          icon="/assets/icons/admin-nav-template.svg"
        />

        
        <m-admin-menu-item-component
          routerLink="/admin/cover-snippets"
          routerLinkActive="active"
          title="admin.nav.cover-snippets"
          i18n-title="@@admin.nav.cover-snippets"
          icon="/assets/icons/admin-nav-template.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/moderation/images"
          routerLinkActive="active"
          title="admin.nav.moderation.images"
          i18n-title="@@admin.nav.moderation.images"
          icon="/assets/icons/admin-nav-moderation.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/moderation/covers"
          routerLinkActive="active"
          title="admin.nav.moderation.covers"
          i18n-title="@@admin.nav.moderation.covers"
          icon="/assets/icons/admin-nav-moderation.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/image-generation-price"
          routerLinkActive="active"
          title="admin.nav.image-generation-price"
          i18n-title="@@admin.nav.image-generation-price"
          icon="/assets/icons/admin-nav-image-generation-pricing.svg"
        />
        
        <m-admin-menu-item-component
          routerLink="/admin/image-generation-watermark"
          routerLinkActive="active"
          title="admin.nav.image-generation-watermark"
          i18n-title="@@admin.nav.image-generation-watermark"
          icon="/assets/icons/admin-nav-image-generation-watermark.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/image-generation-restricted-words"
          routerLinkActive="active"
          title="admin.nav.image-generation-restricted-words"
          i18n-title="@@admin.nav.image-generation-restricted-words"
          icon="/assets/icons/admin-nav-image-generation-watermark.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/settings-maintenance"
          routerLinkActive="active"
          title="admin.nav.maintenance-settings"
          i18n-title="@@admin.nav.maintenance-settings"
          icon="/assets/icons/admin-nav-api.svg"
        />
        
        <m-admin-menu-item-component
          routerLink="/admin/image-generation-mj-available-discord-server"
          routerLinkActive="active"
          title="admin.nav.image-generation-mj-available-discord-server"
          i18n-title="@@admin.nav.image-generation-mj-available-discord-server"
          icon="/assets/icons/admin-nav-image-generation-watermark.svg"
        />

        <div class="divider"></div>

        <m-admin-menu-item-component
          routerLink="/admin/api"
          routerLinkActive="active"
          title="admin.nav.api"
          i18n-title="@@admin.nav.api"
          icon="/assets/icons/admin-nav-api.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/s3"
          routerLinkActive="active"
          title="admin.nav.s3"
          i18n-title="@@admin.nav.s3"
          icon="/assets/icons/admin-nav-api.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/payments"
          routerLinkActive="active"
          title="admin.nav.payments"
          i18n-title="@@admin.nav.payments"
          icon="/assets/icons/admin-nav-api.svg"
        />

        <m-admin-menu-item-component
          routerLink="/admin/unisender"
          routerLinkActive="active"
          title="admin.nav.unisender"
          i18n-title="@@admin.nav.unisender"
          icon="/assets/icons/admin-nav-api.svg"
        />

        <div class="divider"></div>

      </div>
    </m-ng-island>
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 280px;
        flex-shrink: 0;
      }

      m-ng-island {
        width: 100%;
        height: 100%;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        gap: 4px;
        overflow: hidden auto;
      }

      .divider {
        display: flex;
        width: 100%;
        height: 1px;
        flex-shrink: 0;
        background: var(--surface-on-island);
      }
    `,
  ],
  standalone: true,
  imports: [RouterModule, AdminMenuItemComponent, IslandComponent],
})
export class AdminNavView {}
