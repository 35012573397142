import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SelectValue } from "@metranpage/components";
import * as _ from "lodash-es";
import { Subscription } from "rxjs";
import { Promocode, PromocodeCreateData } from "../../models/promocode";
import { Tariff } from "../../models/tariff";
import { AdminPromocodesService } from "../../services/promocodes/promocodes.service";
import { AdminTariffsService } from "../../services/tariffs/tariffs.service";

type PromocodeType = "tokens" | "subscription" | "discount";

@Component({
  selector: "m-admin-add-promocode-modal",
  templateUrl: "./add-promocode.view.html",
  styleUrls: ["./add-promocode.view.scss"],
})
export class AddPromocodeView implements OnDestroy {
  @Input()
  tariffs: Tariff[] = [];
  @Input()
  set promocode(value: Promocode | null) {
    if (value) {
      this._promocode = value;
      this.createFormWithData();
      return;
    }
    this.createForm();
  }

  get promocode(): Promocode | null {
    return this._promocode;
  }

  @Output()
  onPromocodeSaved = new EventEmitter<PromocodeCreateData>();
  @Output()
  onCancelAddition = new EventEmitter<void>();

  protected promocodeTypes;
  protected promocodeType: PromocodeType = "tokens";
  protected form?: FormGroup;
  private _promocode: Promocode | null = null;

  private sub: Subscription = new Subscription();

  constructor(
    private readonly adminTariffsService: AdminTariffsService,
    private readonly adminPromocodesService: AdminPromocodesService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.promocodeTypes = this.getPromocodeTypes();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  createForm() {
    this.form = this.adminPromocodesService.createPromocodeForm();
  }

  private createFormWithData() {
    if (!this.form) {
      this.createForm();
    }
    if (this.form) {
      const formData = _.omit(this.promocode, ["id"]);
      if (formData.createdDate) {
        formData.createdDate = new Date(formData.createdDate).toISOString().split("T")[0];
      }
      if (formData.expireDate) {
        formData.expireDate = new Date(formData.expireDate).toISOString().split("T")[0];
      }
      if (!formData.tariffId) {
        formData.tariffId = undefined;
      }
      this.detectPromocodeType();
      this.form.patchValue(formData);
    }
  }

  private detectPromocodeType(): void {
    if (!this.promocode) {
      return;
    }
    if (this.promocode.creditsCount || this.promocode.goldCreditsCount) {
      return;
    }

    if (this.promocode.tariffId || this.promocode.subscriptionPeriod) {
      this.onSelectPromocodeType("subscription");
    }

    if (this.promocode.discontForSubscription) {
      this.onSelectPromocodeType("discount");
    }
  }

  managePromocode() {
    const formData = this.form?.getRawValue();

    if (formData.createdDate) {
      formData.createdDate = new Date(formData.createdDate);
    } else {
      formData.createdDate = undefined;
    }
    if (formData.expireDate) {
      formData.expireDate = new Date(formData.expireDate);
    } else {
      formData.expireDate = undefined;
    }
    if (!formData.tariffId) {
      formData.tariffId = undefined;
    }

    formData.creditsCount = +formData.creditsCount || 0;
    formData.goldCreditsCount = +formData.goldCreditsCount || 0;
    formData.tariffId = +formData.tariffId || undefined;
    formData.subscriptionPeriod = +formData.subscriptionPeriod || 0;
    formData.discontForSubscription = +formData.discontForSubscription || 0;

    this.onPromocodeSaved.emit(formData);
  }

  onCancel() {
    this.onCancelAddition.emit();
    this.clearPromocodeState();
  }

  clearPromocodeState(): void {
    this.adminPromocodesService.clearActivePromocodeState();
  }

  protected getOptionsForTariffsSelect() {
    return this.adminTariffsService.getOptionsForTariffsSelect(this.tariffs);
  }

  protected onSelectPromocodeType(value: number | string) {
    this.promocodeType = value as PromocodeType;
    this.resetPromocodeData();
    this.cdr.markForCheck();
  }

  private resetPromocodeData(): void {
    const fieldsToReset = [
      "creditsCount",
      "goldCreditsCount",
      "tariffId",
      "subscriptionPeriod",
      "discontForSubscription",
    ];
    fieldsToReset.map((field) => this.form?.controls[field].reset());
  }

  protected getPromocodeTypes(): SelectValue[] {
    return [
      {
        id: "tokens",
        value: $localize`:@@admin.promocodes.promocode-type.tokens:`,
      },
      {
        id: "subscription",
        value: $localize`:@@admin.promocodes.promocode-type.subscription:`,
      },
      {
        id: "discount",
        value: $localize`:@@admin.promocodes.promocode-type.discount:`,
      },
    ];
  }
}
