<m-ng-island [paddingSize]="24">
  <div class="new-settings">
    <m-button
      icon="plus.svg"
      icon-position="left"
      button-style="primary"
      (onClick)="showAddSettingsModal()"
      i18n="@@admin.mj-available-discord-server-settings.add.button"
    >
      admin.mj-available-discord-server-settings.add.button
    </m-button>
  </div>

  <table>
    <thead>
      <th>ID</th>
      <th>Name</th>
      <th>DiscordServerId</th>
      <th>Is Avalable</th>
      <th></th>
    </thead>

    <tbody>
      <tr *ngFor="let s of settings; trackBy: trackBySettingsId">
        <td>{{s.id}}</td>
        <td>{{s.name}}</td>
        <td>{{s.discordServerId}}</td>
        <td>
          <div class="cell-wrapper">
            <m-switch [isChecked]="s.isAvailable" (onToggle)="onChangeAvailable(s)" />
          </div>
        </td>

        <td>
          <div class="controls">
            <m-icon
              class="settings-icon"
              src="gear.svg"
              size="s"
              color="--text-text-80"
              (click)="showSettingsDetails(s)"
            />

            <m-icon
              class="delete-icon"
              src="block-delete.svg"
              size="s"
              color="--color-error"
              (click)="showSettingsDeleteModal(s)"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</m-ng-island>

<m-admin-add-image-generation-mj-available-discord-server-settings-modal
  *ngIf="isAddSettingsModalVisible"
  (add)="createSettings($event)"
  (save)="saveSettings($event)"
  (close)="closeAddSettingsModal()"
/>

<m-modal *ngIf="settingsForDelete" position="center" (close)="closeSettingsDeleteModal()">
  <div class="delete-settings-modal">
    <span>
      <span i18n="@@admin.mj-available-discord-server-settings.delete-confirmation">
        admin.mj-available-discord-server-settings.delete-confirmation
      </span>

      <span *ngIf="settingsForDelete" class="settings-name">
        «
        <span class="settings-name-accent">{{settingsForDelete.name}}</span>
        »
      </span>
      ?
    </span>

    <div class="buttons">
      <m-button
        button-style="secondary"
        size="full-width"
        (onClick)="closeSettingsDeleteModal()"
        i18n="@@admin.common.cancel"
      >
        admin.common.cancel
      </m-button>

      <m-button button-style="primary" (onClick)="deleteSettings()" i18n="@@admin.common.delete">
        admin.common.delete
      </m-button>
    </div>
  </div>
</m-modal>
