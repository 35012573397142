import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { CompanyImageGenerationRestrictedWordsSettings } from "../../models/company";

export type State = {
  restrictedWords: CompanyImageGenerationRestrictedWordsSettings | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyImageGenerationRestrictedWordsSettingsStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      restrictedWords: undefined,
    };
  }

  getRestrictedWordsObservable() {
    return this.getFieldObservable("restrictedWords");
  }

  setRestrictedWords(restrictedWords: CompanyImageGenerationRestrictedWordsSettings) {
    this.update((state) => ({ ...state, restrictedWords }));
  }
}
