import { Inject, Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { CompanyStore } from "@metranpage/company";
import { filterUndefined } from "@metranpage/core";
import { ConfigService } from "@metranpage/core-interfaces";
import { UserService } from "@metranpage/user";
import { UserStore } from "@metranpage/user-data";
import { Observable, filter, map, switchMap, take, tap } from "rxjs";

export type UserActionMessage = {
  name: string;
  header: string;
  text: string;
  button: string;
  backgroundUrl?: string;
  navigateTo?: string;
  navigateToUrl?: string;
  queryParams?: Params;
};

@Injectable({
  providedIn: "root",
})
export class WelcomeModalService {
  userActionMessages$!: Observable<UserActionMessage[]>;

  protected knowledgeBaseUrl?: string;

  constructor(
    readonly userStore: UserStore,
    readonly userService: UserService,
    @Inject("ConfigService")
    readonly configService: ConfigService,
    readonly companyStore: CompanyStore,
  ) {
    this.userActionMessages$ = userStore.getUserObservable().pipe(
      filter((u) => !!u),
      take(1),
      switchMap(() => this.companyStore.getCompanyObservable()),
      tap((company) => {
        this.knowledgeBaseUrl = company?.knowledgeBaseUrl;
      }),
      filterUndefined(),
      switchMap(() => userService.getUserActions()),
      map((actions) => {
        if (!configService.getConfig().company.flags.showUpdatesNews) {
          return [];
        }

        const actionsToDisplay = [
          // {
          //   name: "update-news-202404",
          //   header: $localize`:@@welcome.update-news-202404.header:`,
          //   text: $localize`:@@welcome.update-news-202404.text:`,
          //   button: $localize`:@@welcome.update-news-202404.button:`,
          //   backgroundUrl: "/assets/img/modal-bg-monetization.png",
          //   navigateTo: "/payments/subscription",
          //   companyUuid: ""
          // },
          // {
          //   name: "update-ig-202405",
          //   header: $localize`:@@welcome.update-news-ig.header:`,
          //   text: $localize`:@@welcome.update-news-ig.text:`,
          //   button: $localize`:@@welcome.update-news-ig.button:`,
          //   backgroundUrl: "/assets/img/modal-bg-image-generation.png",
          //   navigateTo: "/image-generation",
          //   companyUuid: ""
          // },
          // {
          //   name: "update-ig-202407",
          //   header: $localize`:@@welcome.update-news-ig-2.header:`,
          //   text: $localize`:@@welcome.update-news-ig-2.text:`,
          //   button: $localize`:@@welcome.update-news-ig-2.button:`,
          //   backgroundUrl: "/assets/img/modal-bg-image-generation-2.png",
          //   navigateTo: "/image-generation",
          //   companyUuid: ""
          // },
          // {
          //   name: "update-ig-2024-08",
          //   header: $localize`:@@welcome.update-news-ig-3.header:`,
          //   text: $localize`:@@welcome.update-news-ig-3.text:`,
          //   button: $localize`:@@welcome.update-news-ig-3.button:`,
          //   backgroundUrl: "/assets/img/modal-bg-image-generation-3.png",
          //   navigateTo: "/image-generation",
          //   companyUuid: ""
          // },
          // {
          //   name: "webinar-1-view",
          //   header: $localize`:@@welcome.webinar-1.header:`,
          //   text: "",
          //   button: $localize`:@@welcome.webinar-1.button:`,
          //   backgroundUrl: "/assets/img/welcome-webinar-1.png",
          //   navigateToUrl: "https://t.me/mtp_vebinars_bot",
          //   companyUuid: ""
          // },
          // {
          //   name: "update-ig-mj-2024-11",
          //   header: $localize`:@@welcome.update-news-ig-4.header:`,
          //   text: $localize`:@@welcome.update-news-ig-4.text:`,
          //   button: $localize`:@@welcome.update-news-ig-4.button:`,
          //   backgroundUrl: "/assets/img/modal-bg-image-generation-4.png",
          //   navigateTo: "/image-generation",
          //   companyUuid: ""
          // },
          {
            name: "update-cover-editor-stylization-2025-02",
            header: $localize`:@@welcome.update-ce-25-02.header:`,
            text: $localize`:@@welcome.update-ce-25-02.text:`,
            button: $localize`:@@welcome.update-ce-25-02.button:`,
            backgroundUrl: "/assets/img/modal-bg-image-generation-6.png",
            navigateToUrl: `${this.knowledgeBaseUrl}text-stylize/`,
            companyUuid: "187e8e3c-d419-4df1-9a53-8164021af6a5",
          },
        ];
        const result: UserActionMessage[] = actions
          ? actionsToDisplay
              .filter((v) => {
                if (!v.companyUuid) {
                  return true;
                }
                return v.companyUuid === configService.getConfig().company.companyUuid;
              })
              .filter((v) => !actions.some((a) => a.name === v.name))
          : actionsToDisplay.filter((v) => v.companyUuid === configService.getConfig().company.companyUuid);

        return result;
      }),
    );
  }
}
