import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ImageObject, ObjectsAlignment } from "@metranpage/book-data";
import { InfoBlockData } from "@metranpage/components";
import {
  ImageGenerationDataService,
  ImageGenerationMode,
  ImageGenerationPrices,
  ImageGenerationService,
  UpscaleSettingsDto,
} from "@metranpage/image-generation";
import { PricingService } from "@metranpage/pricing";
import { UserBalance, UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";
import { CoverService } from "../../services/cover/cover.service";

export type CoverObjectUpscaleData = {
  imageObject: ImageObject;
  upscaleSettings: UpscaleSettingsDto;
};

@Component({
  selector: "m-cover-image-object-settings",
  templateUrl: "./cover-image-object-settings.component.html",
  styleUrls: ["./cover-image-object-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverImageObjectSettingsComponent implements OnChanges {
  @Input() currentObject!: ImageObject;

  @Input()
  prices?: ImageGenerationPrices;
  @Input()
  balance?: UserBalance;

  @Output() align = new EventEmitter<ObjectsAlignment>();
  @Output() removeBackground = new EventEmitter<ImageObject>();
  @Output() upscale = new EventEmitter<CoverObjectUpscaleData>();

  protected formUpscale!: FormGroup;

  protected megapixels = 0;

  protected hasPremium = true;

  protected isUpscalePriceLoading = false;

  private sub: Subscription = new Subscription();

  constructor(
    private readonly coverService: CoverService,
    private readonly imageGenerationService: ImageGenerationService,
    private readonly imageGenerationDataService: ImageGenerationDataService,
    private readonly userStore: UserStore,
    private readonly pricingService: PricingService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.sub.add(
      userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        if (!activeSubscription) {
          this.hasPremium = false;
          return;
        }

        this.hasPremium = this.pricingService.hasPaidTariff(activeSubscription);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentObject) {
      this.updateMegapixels();
      this.updateUpscalePrice();
      this.initUpscaleForm();
    }
  }

  onAlign(alignment: ObjectsAlignment) {
    this.align.emit(alignment);
  }

  onRemoveBackground() {
    this.removeBackground.emit(this.currentObject);
  }

  protected isRemoveBackgroundDisable() {
    return false;
  }

  getPrice(mode: ImageGenerationMode) {
    return this.coverService.getPrice(this.prices, mode);
  }

  onUpscale() {
    const formData = this.formUpscale.getRawValue();
    const data: UpscaleSettingsDto = {
      generatedImageId: this.currentObject.generatedImageId,
      imageGenerationStylizationId: this.currentObject.imageGenerationStylizationId,
      generatedImageStylizationId: this.currentObject.generatedImageStylizationId,
      imageUrl: this.currentObject.imageUrl,
      creativityStrength: formData.creativityStrength,
      upscaleMultiplier: formData.upscaleMultiplier,
      width: this.currentObject.width,
      height: this.currentObject.height,
    };

    this.upscale.emit({
      imageObject: this.currentObject,
      upscaleSettings: data,
    });
  }

  private updateMegapixels() {
    this.megapixels = 0;

    if (!this.currentObject.width || !this.currentObject.height) {
      return;
    }

    this.megapixels = this.imageGenerationService.getImageMegapixel(
      this.currentObject.width,
      this.currentObject.height,
    );
  }

  async updateUpscalePrice() {
    if (!this.prices || !this.currentObject || !this.currentObject.width || !this.currentObject.height) {
      return;
    }

    this.isUpscalePriceLoading = true;
    this.prices.upscale = 0;
    const upscalePrice = await this.imageGenerationService.getUpscalePrice(
      this.currentObject.width,
      this.currentObject.height,
    );
    this.isUpscalePriceLoading = false;
    this.prices.upscale = upscalePrice;

    this.cdr.markForCheck();
  }

  private initUpscaleForm() {
    let creativityStrength = 5;
    if (this.currentObject?.imageGenerationStylizationId || this.currentObject?.generatedImageStylizationId) {
      creativityStrength = 10;
    }

    this.formUpscale = new FormGroup({
      creativityStrength: new FormControl(creativityStrength, [Validators.required]),
      upscaleMultiplier: new FormControl(1.5, [Validators.required]),
    });
  }

  protected isUpscaleAvailable() {
    return !this.isMegapixelLimitReach("upscale");
  }

  protected isUpscaleDisable() {
    return !this.currentObject || !this.prices?.upscale || this.isMegapixelLimitReach("upscale");
  }

  protected getUpscaleErrors() {
    const errors: InfoBlockData[] = [];

    if (this.isMegapixelLimitReach("upscale")) {
      errors.push({
        textData: [{ text: $localize`:@@image-generation.generation.variant-image.upscale.error.megapixel-limit:` }],
      });
    }

    return errors;
  }

  private isMegapixelLimitReach(mode: "upscale" | "unzoom") {
    return this.imageGenerationService.isMegapixelLimitReach(this.megapixels, mode);
  }

  getUpscaleStrengthPoints() {
    return this.imageGenerationDataService.getUpscaleStrengthPoints();
  }
}
