import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { I18nService } from "@metranpage/i18n";
import * as _ from "lodash-es";
import {
  ImageGenerationStylizationDataDto,
  ImageGenerationStylizationStyle,
  ImageGenerationStylizationStyleDto,
  ImageGenerationStylizationStylesData,
} from "../../models/image-generation-stylization/image-generation-stylization";
import { ImageGenerationStylizationApi } from "./image-generation-stylization.api";

@Injectable({
  providedIn: "root",
})
export class ImageGenerationStylizationService {
  protected stylizationStyles: ImageGenerationStylizationStyle[] = [];

  constructor(
    private readonly imageGenerationStylizationApi: ImageGenerationStylizationApi,
    private readonly i18nService: I18nService,
  ) {}

  getImageGenerationStylizationStyles(): ImageGenerationStylizationStyle[] {
    return this.stylizationStyles;
  }

  async loadImageGenerationStylizationStyles(): Promise<ImageGenerationStylizationStyle[]> {
    const stylesWithLocalizations = await this.imageGenerationStylizationApi.getImageGenerationStylizationStyles();
    this.stylizationStyles = stylesWithLocalizations.map((s) => this.updateStylizationStyleLocalization(s));
    return this.stylizationStyles;
  }

  updateStylizationStyleLocalization(data: ImageGenerationStylizationStyleDto) {
    const locale = this.i18nService.getLocale();
    let localeData = data?.localization?.find((l) => l.lang === locale);
    if (!localeData) {
      localeData =
        data?.localization?.length > 0
          ? data.localization[0]
          : {
              id: 0,
              value: "",
              lang: "",
            };
    }

    const styleData = _.omit(data, [
      "localization",
      "prompt",
      "negativePrompt",
      "model",
      "presetStyle",
      "styleReference",
      "styleWeight",
      "imagesCount",
    ]);
    const style: ImageGenerationStylizationStyle = {
      ...styleData,
      title: localeData.value,
    };
    return style;
  }

  isStylizationStyleHasElements(stylizationStyleId: number) {
    const stylizationStyles = this.getImageGenerationStylizationStyles();
    const stylizationStyle = stylizationStyles.find((s) => s.id === stylizationStyleId);
    return !!stylizationStyle?.elements && stylizationStyle.elements.length > 0;
  }

  isStylizationStyleHasRemoveBackground(stylizationStyleId: number) {
    const stylizationStyles = this.getImageGenerationStylizationStyles();
    const stylizationStyle = stylizationStyles.find((s) => s.id === stylizationStyleId);
    return stylizationStyle?.processingImagesMode === "remove-background";
  }

  getStylizationStylePaymentData(stylizationStyleId: number) {
    const stylizationStyles = this.getImageGenerationStylizationStyles();
    const stylizationStyle = stylizationStyles.find((s) => s.id === stylizationStyleId);
    return {
      hasElements: !!stylizationStyle?.elements && stylizationStyle.elements.length > 0,
      hasAdditionalRemoveBackground: stylizationStyle?.processingImagesMode === "remove-background",
    };
  }

  async stylizationImageGeneration(
    data: ImageGenerationStylizationDataDto,
    file: File,
  ): Promise<{ status: GeneralResultStatus; generationId?: number }> {
    try {
      const imageGenerationStylization = await this.imageGenerationStylizationApi.stylizationImageGeneration(
        data,
        file,
      );
      return { status: "success", generationId: imageGenerationStylization?.id };
    } catch (errorResponse: any) {
      return { status: "error" };
    }
  }

  async getImageGenerationStylizationStylesData(bookId: number): Promise<ImageGenerationStylizationStylesData> {
    return await this.imageGenerationStylizationApi.getImageGenerationStylizationStylesData(bookId);
  }
}
