import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { BalanceData } from "@metranpage/user-payment-data";
import {
  AdvancedImageGenerationDataDto,
  BasicImageGenerationDataDto,
  CreativeImageGenerationDataDto,
  FaceSwapImageGenerationDataDto,
  ImageGeneration,
  ImageGenerationAdvancedStyleDto,
  ImageGenerationBasicStyleDto,
  ImageGenerationCreativeStyleDto,
  ImageGenerationCreditCheckData,
  ImageGenerationPrices,
  ImageGenerationRestrictedWords,
  ImageGenerationTechniqueDto,
  ImageGenerationsData,
  LastImageGenerationsDataDto,
  UnzoomSettings,
  UpscaleSettingsDto,
} from "../models/image-generation";

@Injectable({
  providedIn: "root",
})
export class ImageGenerationApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getImageGenerationById(id: number) {
    return this.get<ImageGeneration | undefined>(`image-generation/${id}`);
  }

  async loadImageGenerationsPaginated(page: number) {
    return this.get<ImageGenerationsData>("image-generation/paginated", {
      page,
    });
  }

  async loadImageGenerations() {
    return this.get<ImageGeneration[]>("image-generation/all");
  }

  async basicImageGeneration(data: BasicImageGenerationDataDto) {
    return this.post("image-generation/basic-image-generation", data);
  }

  async advancedImageGeneration(data: AdvancedImageGenerationDataDto) {
    return this.post("image-generation/advanced-image-generation", data);
  }

  async creativeImageGeneration(data: CreativeImageGenerationDataDto) {
    return this.post("image-generation/creative-image-generation", data);
  }

  async faceSwapImageGeneration(data: FaceSwapImageGenerationDataDto) {
    return this.post("image-generation/face-swap-image-generation", data);
  }

  async deleteImageGeneration(id: number) {
    return this.delete(`image-generation/${id}`);
  }

  async loadPrices() {
    return this.get<ImageGenerationPrices>("image-generation/get-prices");
  }

  async getUpscalePrice(w: number, h: number) {
    return this.get<number>(`image-generation/get-upscale-price?w=${w}&h=${h}`);
  }

  async getUpscalePriceByGeneratedImageId(generatedImageId: number) {
    return this.get<number>(`image-generation/get-upscale-price/${generatedImageId}`);
  }

  async hasEnoughCredits(data: ImageGenerationCreditCheckData) {
    return this.post<BalanceData>("image-generation/check-credits", data);
  }

  async getImageGenerationBasicStyles() {
    return this.get<ImageGenerationBasicStyleDto[]>("image-generation/styles-basic");
  }

  async getImageGenerationAdvancedStyles() {
    return this.get<ImageGenerationAdvancedStyleDto[]>("image-generation/styles-advanced");
  }

  async getImageGenerationCreativeStyles() {
    return this.get<ImageGenerationCreativeStyleDto[]>("image-generation/styles-creative");
  }

  async getImageGenerationTechniques() {
    return this.get<ImageGenerationTechniqueDto[]>("image-generation/techniques");
  }

  async upscaleGeneratedImage(data: UpscaleSettingsDto) {
    return this.post<ImageGeneration | undefined>("image-generation/upscale", data);
  }

  async upscaleBookCoverImage(data: UpscaleSettingsDto) {
    return this.post<ImageGeneration | undefined>("image-generation/upscale-book-cover-image", data);
  }

  async unzoomGeneratedImage(data: UnzoomSettings) {
    return this.post<ImageGeneration | undefined>("image-generation/unzoom", data);
  }

  async getVariantGenerations(rootGenerationId: number, rootGeneratedImageId: number) {
    return this.get<ImageGeneration[]>(
      `image-generation/variant-generation/${rootGenerationId}/${rootGeneratedImageId}`,
    );
  }

  async getLastImageGenerations() {
    return this.get<LastImageGenerationsDataDto>("image-generation/last-image-generations");
  }

  async loadRestrictedWords() {
    return this.get<ImageGenerationRestrictedWords>("image-generation/get-restricted-words");
  }
}
