import { Component, HostBinding, Input } from "@angular/core";
import { SelectedImage } from "./selected-image.model";

@Component({
  selector: "m-preview-generated-image",
  template: `
<img *ngIf="selectedImage.hasImage" [src]="selectedImage.generatedImageUrl" class="preview-image" />

<m-generating-image-card
  type="text"
  *ngIf="selectedImage.imageGeneration.status === 'created' && !selectedImage.hasImage"
  [text]="getProcessingText()"
  [imageGeneration]="selectedImage.imageGeneration"
  [isWidthLimited]="false"
/>

<m-generation-error-image-card
  *ngIf="selectedImage.imageGeneration.status === 'error' && !selectedImage.hasImage"
  [imageGeneration]="selectedImage.imageGeneration"
  [isWidthLimited]="false"
/>
`,
  styles: [
    `
:host {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* https://stackoverflow.com/a/31972181 */
  min-width: 0;
  border-radius: var(--border-radius-m);
}

img {
  max-width: 100%;
  max-height: 100%;
}
`,
  ],
})
export class PreviewGeneratedImageView {
  @Input()
  selectedImage!: SelectedImage;

  @HostBinding("style.aspect-ratio")
  protected get aspectRatio() {
    return `${this.selectedImage.width}/${this.selectedImage.height}`;
  }

  protected getProcessingText() {
    let text = "";
    if (this.selectedImage.imageGeneration.generationMode === "unzoom") {
      text = $localize`:@@image-generation.generation.unzoom.processing:`;
    }
    if (
      this.selectedImage.imageGeneration.generationMode === "upscale" ||
      this.selectedImage.imageGeneration.generationMode === "upscale-book-cover-image"
    ) {
      text = $localize`:@@image-generation.generation.upscale.processing:`;
    }
    return text;
  }
}
