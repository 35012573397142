<div class="arrow-button-previous" [ngClass]="{disabled: !hasPrevious()}" (click)="onPreviousClick()">
  <m-ng-icon src="button-arrow-right-20.svg" [size]="20" />
</div>

<div class="position">
  <span class="current-position">{{ current + 1 }}</span>
  /
  <span>{{ total }}</span>
</div>

<div class="arrow-button-next" [ngClass]="{disabled: !hasNext()}" (click)="onNextClick()">
  <m-ng-icon src="button-arrow-right-20.svg" [size]="20" />
</div>
