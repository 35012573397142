import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { InfoBlockData } from "@metranpage/components";
import { AnalyticsService, FileDropComponent, LoadingService, NotificationsPopUpService } from "@metranpage/core";
import { User } from "@metranpage/user-data";
import { NgxFileDropEntry } from "ngx-file-drop";
import { Subscription } from "rxjs";
import {
  ImageGenerationCharacterReference,
  ImageGenerationCharacterReferenceDataDto,
  ImageGenerationCharacterReferenceType,
} from "../../models/image-generation-character-reference/image-generation-character-reference";
import { ImageGenerationCharacterReferenceService } from "../../services/image-generation-character-reference/image-generation-character-reference.service";
import { ImageGenerationCharacterReferenceStore } from "../../services/image-generation-character-reference/image-generation-character-reference.store";

export type CharacterReferenceModalMode = "select" | "generation";

const reFileExtensions = /(\.)(jpg|jpeg|jfif|pjpeg|pjp|png)$/gm;

@Component({
  selector: "m-character-reference-modal",
  templateUrl: "./character-reference-modal.view.html",
  styleUrls: ["./character-reference-modal.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CharacterReferenceModalView implements OnInit, OnDestroy {
  @Input()
  user?: User;
  @Input()
  selectedTab = 1;
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;
  @Input()
  hasFaceReference = true;
  @Input()
  hasCharacterReference = true;
  @Input()
  mode: CharacterReferenceModalMode = "generation";
  @Input()
  faceSwapPrice?: number;

  @Output()
  generateFaceSwap = new EventEmitter<ImageGenerationCharacterReference>();
  @Output()
  onSelect = new EventEmitter<ImageGenerationCharacterReference>();
  @Output()
  onClose = new EventEmitter<void>();

  @ViewChild("filedrop", { static: false })
  protected fileDropComponent!: FileDropComponent;

  // protected imageUrl?: string;
  // protected isPreviewHovered = false;

  protected faceReferenceFile?: File;

  protected faceReferencePage = 1;
  protected faceReferencePageCount = 1;

  protected characterReferenceFile?: File;

  protected characterReferencePage = 1;
  protected characterReferencePageCount = 1;

  protected faceReference: ImageGenerationCharacterReference[] = [];
  protected characterReference: ImageGenerationCharacterReference[] = [];

  protected characterReferenceForDelete?: ImageGenerationCharacterReference;

  protected characterReferencePreviewFile?: File;

  protected selectedCharacterReference?: ImageGenerationCharacterReference;

  sub: Subscription = new Subscription();

  protected infoBlockData: InfoBlockData[] = [
    { textData: [{ text: $localize`:@@image-generation.character-reference.modal.generation.info:` }] },
  ];

  constructor(
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly imageGenerationCharacterReferenceService: ImageGenerationCharacterReferenceService,
    private readonly imageGenerationCharacterReferenceStore: ImageGenerationCharacterReferenceStore,
    private readonly analytics: AnalyticsService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.listenCharacterReferenceChanges();
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected listenCharacterReferenceChanges() {
    this.sub.add(
      this.imageGenerationCharacterReferenceStore.getFaceReferenceObservable().subscribe((items) => {
        this.faceReference = items.filter((i) => !i.deletedAt);
        this.cdr.markForCheck();
      }),
    );

    this.sub.add(
      this.imageGenerationCharacterReferenceStore.getFaceReferencePageCountObservable().subscribe((pageCount) => {
        this.faceReferencePageCount = pageCount;
      }),
    );

    this.sub.add(
      this.imageGenerationCharacterReferenceStore.getCharacterReferenceObservable().subscribe((items) => {
        this.characterReference = items.filter((i) => !i.deletedAt);
        this.cdr.markForCheck();
      }),
    );

    this.sub.add(
      this.imageGenerationCharacterReferenceStore.getCharacterReferencePageCountObservable().subscribe((pageCount) => {
        this.characterReferencePageCount = pageCount;
      }),
    );
  }

  protected async loadData() {
    await this.loadFaceReference();
    await this.loadCharacterReference();
  }

  protected async loadFaceReference() {
    if (this.faceReferencePage > this.faceReferencePageCount) {
      return;
    }
    await this.imageGenerationCharacterReferenceService.loadCharacterReferencePaginated(this.faceReferencePage, {
      type: "face",
    });
  }

  protected async loadCharacterReference() {
    if (this.characterReferencePage > this.characterReferencePageCount) {
      return;
    }
    await this.imageGenerationCharacterReferenceService.loadCharacterReferencePaginated(this.characterReferencePage, {
      type: "character",
    });
  }

  protected onGenerationClick() {
    if (!this.selectedCharacterReference) {
      return;
    }
    this.generateFaceSwap.emit(this.selectedCharacterReference);
    this.onCloseClick();
  }

  protected selectCharacterReference(imageGenerationCharacterReference: ImageGenerationCharacterReference) {
    if (this.mode === "generation") {
      this.selectedCharacterReference = imageGenerationCharacterReference;
      return;
    }
    this.onSelect.emit(imageGenerationCharacterReference);
  }

  protected showConfiramtionDeleteModal(imageGenerationCharacterReference: ImageGenerationCharacterReference) {
    this.characterReferenceForDelete = imageGenerationCharacterReference;
  }

  protected closeDeleteCharacterReferenceModal() {
    this.characterReferenceForDelete = undefined;
  }

  protected async deleteCharacterReference() {
    if (!this.characterReferenceForDelete) {
      return;
    }

    this.notificationService.closeAll();
    this.loadingService.startLoading({
      fullPage: true,
    });
    const result = await this.imageGenerationCharacterReferenceService.deleteCharacterReference(
      this.characterReferenceForDelete.id,
    );
    this.loadingService.stopLoading();
    if (result !== "success") {
      // this.notificationService.error($localize`:@@image-generation.character-reference.upload.request.error:`);
      return;
    }

    this.characterReferenceForDelete.deletedAt = new Date();
    this.imageGenerationCharacterReferenceStore.updateCharacterReference(this.characterReferenceForDelete);

    await this.checkRemainingItems(this.characterReferenceForDelete.type);

    this.closeDeleteCharacterReferenceModal();
  }

  protected async saveImageFile(file: File) {
    this.hideCharacterReferencePreviewModal();

    let type: ImageGenerationCharacterReferenceType = "character";

    this.faceReferenceFile = undefined;
    this.characterReferenceFile = undefined;

    if (this.selectedTab === 1) {
      this.faceReferenceFile = file;
      type = "face";
    }
    if (this.selectedTab === 2) {
      this.characterReferenceFile = file;
      type = "character";
    }

    await this.createCharacterReference(type);
  }

  protected async createCharacterReference(type: ImageGenerationCharacterReferenceType) {
    // if (!this.isCreateStyleButtonActive()) {
    //   return;
    // }

    this.analytics.event("create-user-character-reference", { type: type });

    let description = $localize`:@@image-generation.face-reference.upload.process-hint:`;
    if (type === "character") {
      description = $localize`:@@image-generation.character-reference.upload.process-hint:`;
    }

    this.notificationService.closeAll();
    this.loadingService.startLoading({
      fullPage: true,
      description: description,
    });
    const data: ImageGenerationCharacterReferenceDataDto = {
      type: type,
    };
    const file = type === "face" ? this.faceReferenceFile : this.characterReferenceFile;
    const result = await this.imageGenerationCharacterReferenceService.createCharacterReference(data, file);
    this.loadingService.stopLoading();
    if (result?.error) {
      if (result.error === "file-limit-error") {
        this.notificationService.error(
          $localize`:@@image-generation.character-reference.upload.request.error.file-limit:`,
        );
        return;
      }
      this.notificationService.error($localize`:@@image-generation.character-reference.upload.request.error:`);
      return;
    }
    this.notificationService.notify({
      content: $localize`:@@image-generation.character-reference.upload.request.success:`,
      type: "success",
    });
    // if (result.userStyleReference) {
    //   this.imageGenerationCharacterReferenceService.onChangeUserStyleReference(result.userStyleReference.id);
    // }

    // this.onCloseClick();
  }

  dropped(files: NgxFileDropEntry[], type: ImageGenerationCharacterReferenceType) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: File) => {
          if (!file.name.match(reFileExtensions)) {
            this.notificationService.error(
              $localize`:@@image-generation.character-reference.upload.request.error.file-extension:`,
            );
            return;
          }

          if (type === "face") {
            this.showCharacterReferencePreviewModal(file);
          }
          if (type === "character") {
            this.saveImageFile(file);
          }
        });
      }
    }
  }

  openFileSelector() {
    this.fileDropComponent.openFileSelector();
  }

  private async checkRemainingItems(type: ImageGenerationCharacterReferenceType) {
    const itemsOnPageMin = 10;
    if (type === "face" && this.faceReference.length < itemsOnPageMin) {
      this.faceReferencePage++;
      await this.loadFaceReference();
    }
    if (type === "character" && this.characterReference.length < itemsOnPageMin) {
      this.characterReferencePage++;
      await this.loadCharacterReference();
    }
  }

  protected onScrolled(type: ImageGenerationCharacterReferenceType) {
    if (type === "face") {
      this.faceReferencePage++;
      this.loadFaceReference();
    }

    if (type === "character") {
      this.characterReferencePage++;
      this.loadCharacterReference();
    }
  }

  protected showCharacterReferencePreviewModal(file: File) {
    this.characterReferencePreviewFile = file;
    this.cdr.markForCheck();
  }

  protected hideCharacterReferencePreviewModal() {
    this.characterReferencePreviewFile = undefined;
    this.cdr.markForCheck();
  }

  trackById(index: number, imageGenerationCharacterReference: ImageGenerationCharacterReference) {
    return imageGenerationCharacterReference.id;
  }

  protected getImageUrl(imageGenerationCharacterReference: ImageGenerationCharacterReference) {
    return this.imageGenerationCharacterReferenceService.getUrlForImage(imageGenerationCharacterReference, true);
  }

  protected selectTab(selectedTab: number) {
    if (this.selectedTab === selectedTab) {
      return;
    }
    this.selectedTab = selectedTab;
  }

  protected async onCloseClick() {
    this.onClose.emit();
  }

  protected onBackDropClick() {
    if (!this.closeOnBackDropClick) {
      return;
    }
    this.onCloseClick();
  }
}
