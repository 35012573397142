import { User } from "@metranpage/user-data";
import { Expose, Type } from "class-transformer";
import { Book } from "../book";
import { ModerationCoverRequest } from "../moderation-cover-request";
import { Color } from "./color";
import { CoverObjectContainer } from "./cover-object-container";

export class BookCover extends CoverObjectContainer {
  @Expose() id!: number;

  @Expose() width!: number;
  @Expose() height!: number;

  @Expose() @Type(() => Color) backgroundColor?: Color;

  @Expose() isPublic!: boolean;
  @Expose() moderationCoverRequest?: ModerationCoverRequest;

  @Expose() book?: Book;
  @Expose() author?: Partial<User>;

  @Expose() likesCount!: number;
  @Expose() isLiked!: boolean;

  @Expose() isFullsizeSaved!: boolean;
}

export type UploadImageResponseDto = {
  name: string;
};

export type UploadGeneratedImageRequestDto = {
  bookId: number;
  generationId: number;
  src: string;
};

export type ApplyTemplateRequestDto = {
  bookId: number;
  templateId: number;
};

export type BookCoverData = { items: BookCover[]; pageCount: number };
