<m-side-panel class="sidebar" sidebar-position="right" sidebar-type="fixed">
  <ng-container>
    <div class="side-panel-right-context">
      <span class="text-block" i18n="@@books.preview.processing-steps">books.preview.processing-steps</span>
      <span class="text-block" i18n="@@books.preview.processing-steps-2">books.preview.processing-steps-2</span>

      <div class="separator-block"></div>

      <m-layout-steps [layoutState]="getLayoutState()"></m-layout-steps>
    </div>

    <div class="buttons">
      <div *ngIf="book && isFinalsReady" class="button-wrapper">
        @if(book.cover) {
        <m-button
          button-style="secondary"
          size="full-width"
          [routerLink]="['/books', book.id, 'cover']"
          i18n="@@books.book.edit-cover-button"
        >
          books.book.edit-cover-button
        </m-button>
        } @else {
        <m-button
          button-style="primary"
          size="full-width"
          [routerLink]="['/books', book.id, 'cover']"
          i18n="@@books.book.add-cover-button"
        >
          books.book.add-cover-button
        </m-button>
        }
      </div>

      <div *ngIf="!isFinalsReady" class="button-wrapper">
        <m-button button-style="primary" size="full-width" (onClick)="onNewBookClick.emit()">
          <div class="premium-wrapper">
            <span i18n="@@books.book.create-button">books.book.create-button</span>
            <m-premium-mark *ngIf="isPremiumLabelVisible" />
          </div>
        </m-button>
      </div>

      <div *ngIf="isFinalsReady && book?.exportPrint" class="button-wrapper">
        <m-button
          button-style="primary"
          size="full-width"
          i18n="@@books.book.download-project-button"
          (onClick)="onDownloadProjectClick.emit()"
        >
          books.book.download-project-button
        </m-button>
      </div>

      <div *ngIf="isFinalsReady && !book?.exportPrint && book?.exportEpub" class="button-wrapper">
        <m-button
          button-style="primary"
          size="full-width"
          i18n="@@books.preview.download-epub"
          (onClick)="onDownloadEpubClick.emit()"
        >
          books.preview.download-epub
        </m-button>
      </div>
    </div>
  </ng-container>
</m-side-panel>
