import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ImageObject, StylizationImageData } from "@metranpage/book-data";
import { Canvas, Point } from "fabric";
import { Observable, Subscription } from "rxjs";
import { ObjectShape } from "../cover/cover.view";

@Component({
  selector: "m-cover-editor-image-stylization-variants-context-menu",
  templateUrl: "./cover-editor-image-stylization-variants-context-menu.view.html",
  styleUrls: ["./cover-editor-image-stylization-variants-context-menu.view.scss"],
})
export class CoverEditorImageStylizationVariantsContextMenuView implements OnChanges, OnDestroy, AfterViewInit {
  @Input()
  currentObject!: ObjectShape;
  @Input()
  wrapperRef?: ElementRef;
  @Input()
  stage?: Canvas;
  @Input()
  currentObjectChanges?: Observable<void>;
  @Input()
  offsetTop = 10;

  @Output()
  changeStylizationImageData = new EventEmitter<StylizationImageData>();

  protected hasStylizationImagesData = false;

  protected stylizationImagesData: StylizationImageData[] = [];

  protected currentUrl?: string;
  protected current = 0;
  protected total = 0;

  protected sub: Subscription = new Subscription();

  constructor(private readonly elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.showControlsForStylizationVariants();

    this.sub.add(
      this.currentObjectChanges?.subscribe(() => {
        this.showControlsForStylizationVariants();
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentObject) {
      this.update();
    }
    this.showControlsForStylizationVariants();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  protected update() {
    if (!this.currentObject || !(this.currentObject instanceof ImageObject)) {
      this.reset();
      return;
    }

    this.stylizationImagesData = this.currentObject.stylizationImagesData || [];

    this.currentUrl = this.currentObject.imageUrl;

    this.total = this.stylizationImagesData.length;
    this.current = this.stylizationImagesData.findIndex((d) => d.imageUrl === this.currentUrl) || 0;
  }

  protected reset() {
    this.stylizationImagesData = [];
    this.currentUrl = undefined;
    this.current = 0;
    this.total = 0;
  }

  protected onPreviousClick() {
    if (!this.hasPrevious()) {
      return;
    }
    this.current--;
    this.changeStylizationImageData.emit(this.stylizationImagesData[this.current]);
  }

  protected onNextClick() {
    if (!this.hasNext()) {
      return;
    }
    this.current++;
    this.changeStylizationImageData.emit(this.stylizationImagesData[this.current]);
  }

  protected hasPrevious(): boolean {
    if (this.currentObject === undefined) {
      return false;
    }
    return this.current - 1 >= 0;
  }

  protected hasNext(): boolean {
    if (this.currentObject === undefined) {
      return false;
    }

    return this.current + 1 < this.stylizationImagesData.length;
  }

  showControlsForStylizationVariants() {
    if (
      !this.currentObject ||
      !(this.currentObject instanceof ImageObject) ||
      !this.currentObject.stylizationImagesData ||
      this.currentObject.stylizationImagesData.length <= 1 ||
      !this.currentObject.isVisible
    ) {
      this.hideStylizationVariantsContextMenu();
      return;
    }

    this.displayStylizationVariantsContextMenu(this.currentObject);
  }

  displayStylizationVariantsContextMenu(object: ObjectShape) {
    this.hasStylizationImagesData = true;

    const contextMenuStyle = this.elementRef.nativeElement.style;
    contextMenuStyle.opacity = "1";
    contextMenuStyle.display = "flex";

    const objectCoords = object.shape!.getCoords();
    const objectTransformedBottomLeft = new Point(objectCoords[3]).transform(this.stage!.viewportTransform);
    const objectTransformedBottomRight = new Point(objectCoords[2]).transform(this.stage!.viewportTransform);
    const objectWidth = objectTransformedBottomRight.x - objectTransformedBottomLeft.x;

    const contextMenuWidth = this.elementRef.nativeElement.offsetWidth;

    contextMenuStyle.left = `${objectTransformedBottomLeft.x + objectWidth / 2 - contextMenuWidth / 2}px`;
    contextMenuStyle.top = `${objectTransformedBottomLeft.y + this.offsetTop}px`;
  }

  hideStylizationVariantsContextMenu() {
    this.hasStylizationImagesData = false;

    const contextMenuStyle = this.elementRef.nativeElement.style;
    contextMenuStyle.opacity = "0";
    contextMenuStyle.display = "none";
    contextMenuStyle.left = "-5000px";
  }
}
