<m-modal-backdrop [closeButtonVisible]="true" [closeOnBackDropClick]="true" (close)="onCloseClick()">
  <div class="container" @fade-scale>
    <div class="header-wrapper">
      <div class="column-group">
        <div class="header">
          <span i18n="@@premium-tariffs-modal.title">premium-tariffs-modal.title</span>
          <div class="premium">
            <span i18n="@@premium-tariffs-modal.premium.title">premium-tariffs-modal.premium.title</span>
            <m-premium-mark />
          </div>
        </div>
        <div class="text">
          <m-localized-text
            class="text"
            text="premium-tariffs-modal.description"
            i18n-text="@@premium-tariffs-modal.description"
          />
        </div>
      </div>
    </div>

    <m-tariff-grid-2
      [tariffs]="(tariffsForUpgrade$ | async) ?? []"
      [currency]="currency"
      cardAppearance="compact"
      [activeSubscription]="activeSubscription"
      [promocodeDiscountData]="promocodeDiscountData"
      (buySubscription)="showPaymentConfirmationModal($event)"
    />
  </div>
</m-modal-backdrop>

@if (selectedTariff) {
  <m-payment-confirmation-modal
    [tariff]="selectedTariff"
    [promocodeDiscountData]="promocodeDiscountData"
    (close)="closePaymentConfirmationModal()"
  />
}

@if (isLoading) {
  <m-loading-spinner class="layout-process-loading" />
}
