<div class="wrapper">
  <div>
    <div class="caption-wrapper">
      <div class="caption-group">
        <span class="caption" i18n="@@cover-editor.stylization.title">cover-editor.stylization.title</span>
        <m-new-mark />

        <span *ngIf="hasTooltip" class="tooltip-host">
          <m-ng-icon src="info.svg" [size]="16" />

          <div class="tooltip">
            <m-localized-text text="cover-editor.stylization.tooltip" i18n-text="@@cover-editor.stylization.tooltip" />
          </div>
        </span>
      </div>

      <m-switch input-style="on-panel" [(ngModel)]="isStylizationCollapsed" [withLabel]="false" />
    </div>
  </div>

  @if (isStylizationCollapsed) {
    <div @slideInOutVertical class="cards">
      <div class="preview-wrapper" [ngClass]="{ active: isStylizationUserStyleActive() }">
        <ng-container *ngTemplateOutlet="userStyleTemplate"></ng-container>
      </div>
      <div
        *ngFor="let item of stylizationStyles; trackBy: trackById"
        class="card"
        [ngClass]="{ active: selectedStylizationStyleId === item.id }"
        (click)="selectStylizationStyleClick(item)"
      >
        <ng-container *ngTemplateOutlet="styleTemplate; context: { style: item }"></ng-container>
      </div>
    </div>

    <div class="buttons">
      <m-button
        button-style="primary"
        size="full-width"
        [is-disabled]="isGenerationDisabled()"
        (onClick)="onGenerateStylizeClick()"
      >
        <span i18n="@@cover-editor.stylization.generate">cover-editor.stylization.generate</span>
        <div class="price">
          <m-ng-icon class="token-icon" src="/assets/img/app-bar-menu/token-simple-{theme}.svg" [size]="20" />
          <span *ngIf="prices">{{ getPrice() }}</span>
        </div>
      </m-button>
    </div>
  }

  <div class="border"></div>
</div>

<ng-template #userStyleTemplate>
  @if (stylizationUserStyleImageUrl) {
    <div class="image-wrapper">
      <m-skeleton-image
        [url]="stylizationUserStyleImageUrl"
        width="66"
        height="66"
        unit="px"
        animationStyle="gradient"
        backgroudColor="var(--surface-on-island)"
        imgObjectFit="fill"
      />
    </div>

    <div class="title" i18n="@@cover-editor.stylization.custom-style.title">
      cover-editor.stylization.custom-style.title
    </div>

    <div class="controls">
      @if (!isStylizationUserStyleActive()) {
        <div
          class="button"
          i18n="@@cover-editor.stylization.modal.card.select-button"
          (click)="selectStylizationUserStyle()"
        >
          cover-editor.stylization.modal.card.select-button
        </div>

        <div class="divider"></div>
      }

      <div
        class="button"
        i18n="@@cover-editor.stylization.modal.card.change-button"
        (click)="onShowSelectStylizationStylesModalClick()"
      >
        cover-editor.stylization.modal.card.change-button
      </div>
    </div>
  } @else {
    <div class="no-image-reference" (click)="onShowSelectStylizationStylesModalClick()">
      <m-ng-icon src="plus.svg" [size]="44" />
    </div>
  }
</ng-template>

<ng-template #styleTemplate let-style="style">
  <div class="title">{{ style.title }}</div>

  <div class="image-wrapper">
    <m-skeleton-image [url]="style.image" width="66" height="66" unit="px" imgObjectFit="contain" />
  </div>
</ng-template>
