import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL, FILES_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import { ImageGenerationStylizationUserStyleData } from "../../models/image-generation-stylization-user-style/image-generation-stylization-user-style";
import { CreateImageGenerationStylizationUserStyleResponse } from "./image-generation-stylization-user-style.service";

export type ImageGenerationStylizationUserStyleFilter = {};

@Injectable({
  providedIn: "root",
})
export class ImageGenerationStylizationUserStyleApi extends Api {
  constructor(
    http: HttpClient,
    @Inject(BASE_URL) baseUrl: string,
    @Inject(FILES_URL) private filesUrl: string,
  ) {
    super(http, baseUrl);
  }

  async getImageGenerationStylizationUserStylesPaginated(
    page: number,
    filters: ImageGenerationStylizationUserStyleFilter,
  ) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);
    return this.get<ImageGenerationStylizationUserStyleData>("image-generation-stylization-user-style/paginated", {
      page,
      ...clearedFilters,
    });
  }

  async createImageGenerationStylizationUserStyle(file: File) {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post<CreateImageGenerationStylizationUserStyleResponse>(
      "image-generation-stylization-user-style",
      formData,
      {
        headers,
      },
    );
  }

  async deleteImageGenerationStylizationUserStyle(id: number) {
    return this.delete(`image-generation-stylization-user-style/${id}`);
  }

  getUrlForFile(file: { vid: string }) {
    return `${this.filesUrl}/${file.vid}`;
  }
}
