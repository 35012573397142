import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { LoadingService, NotificationsPopUpService } from "@metranpage/core";
import { takeUntil } from "rxjs";
import {
  ImageGenerationMjAvailableDiscordServerSettings,
  ImageGenerationMjAvailableDiscordServerSettingsDto,
  ImageGenerationMjAvailableDiscordServerSettingsSaveData,
} from "../../models/image-generation-mj-available-discord-server";
import { AdminCompanyImageGenerationMjAvailableDiscordServerSettingsService } from "../../services/image-generation-mj-available-discord-server-settings/companies-image-generation-mj-available-discord-server-settings.service";
import { AdminCompanyImageGenerationMjAvailableDiscordServerSettingsStore } from "../../services/image-generation-mj-available-discord-server-settings/companies-image-generation-mj-available-discord-server-settings.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-image-generation-mj-available-discord-server-settings-page",
  templateUrl: "./image-generation-mj-available-discord-server-settings.page.html",
  styleUrls: ["./image-generation-mj-available-discord-server-settings.page.scss"],
})
export class AdminImageGenerationMjAvailableDiscordServerSettingsPage extends AdminBasePage implements OnInit {
  settings: ImageGenerationMjAvailableDiscordServerSettings[] = [];

  protected isAddSettingsModalVisible = false;
  protected settingsForDelete?: ImageGenerationMjAvailableDiscordServerSettings = undefined;

  constructor(
    private readonly service: AdminCompanyImageGenerationMjAvailableDiscordServerSettingsService,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly store: AdminCompanyImageGenerationMjAvailableDiscordServerSettingsStore,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();

    this.addSub(
      this.store
        .getAvailableDiscordServerSettingsObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((settings) => {
          this.settings = settings;
          this.cdr.markForCheck();
        }),
    );
  }

  async ngOnInit() {
    await this.service.loadImageGenerationMjAvailableDiscordServerSettings();
  }

  trackBySettingsId(index: number, mjAvailableDiscordServer: ImageGenerationMjAvailableDiscordServerSettings) {
    return mjAvailableDiscordServer.id;
  }

  protected async showAddSettingsModal() {
    this.isAddSettingsModalVisible = true;
  }

  protected async closeAddSettingsModal() {
    this.isAddSettingsModalVisible = false;
  }

  protected showSettingsDetails(settings: ImageGenerationMjAvailableDiscordServerSettings) {
    this.store.setActiveAvailableDiscordServerSettings(settings);
    this.showAddSettingsModal();
  }

  protected showSettingsDeleteModal(settings: ImageGenerationMjAvailableDiscordServerSettings) {
    this.settingsForDelete = settings;
  }

  protected closeSettingsDeleteModal() {
    this.settingsForDelete = undefined;
  }

  protected async createSettings(data: ImageGenerationMjAvailableDiscordServerSettingsDto) {
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.createImageGenerationMjAvailableDiscordServerSettings(data);
    if (result.status !== "success") {
      this.notificationService.error($localize`:@@admin.mj-available-discord-server-settings.error.cant-create:`);
    }
    this.loadingService.stopLoading();
  }

  protected async saveSettings(data: ImageGenerationMjAvailableDiscordServerSettingsSaveData) {
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.updateImageGenerationMjAvailableDiscordServerSettings(data.id, data.data);
    if (result.status !== "success") {
      this.notificationService.error($localize`:@@admin.mj-available-discord-server-settings.error.cant-update:`);
    }
    this.loadingService.stopLoading();
  }

  protected async onChangeAvailable(settings: ImageGenerationMjAvailableDiscordServerSettings) {
    this.loadingService.startLoading({ fullPage: true });
    const updateData: ImageGenerationMjAvailableDiscordServerSettingsDto = {
      name: settings.name,
      discordServerId: settings.discordServerId,
      isAvailable: !settings.isAvailable,
    };
    const result = await this.service.updateImageGenerationMjAvailableDiscordServerSettings(settings.id, updateData);
    if (result.status !== "success") {
      this.notificationService.error($localize`:@@admin.mj-available-discord-server-settings.error.cant-update:`);
    }
    this.loadingService.stopLoading();
  }

  protected async deleteSettings() {
    if (!this.settingsForDelete) {
      return;
    }
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.deleteImageGenerationMjAvailableDiscordServerSettings(this.settingsForDelete.id);
    if (result.status !== "success") {
      this.notificationService.error($localize`:@@admin.mj-available-discord-server-settings.error.cant-delete:`);
    }
    this.loadingService.stopLoading();
  }
}
