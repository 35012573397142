import { Expose } from "class-transformer";
import { CoverObject } from "./cover-object";
import { CoverObjectType } from "./enums";

export class ImageObject extends CoverObject {
  @Expose() imageUrl?: string;
  @Expose() fileId?: string;
  @Expose() isBackgroundRemoved?: boolean;
  @Expose() needRescale?: boolean;
  @Expose() generatedImageId?: number;
  @Expose() imageGenerationStylizationId?: number;
  @Expose() generatedImageStylizationId?: number;
  @Expose() stylizationImagesData?: StylizationImageData[];

  constructor() {
    super();
    this.__type = CoverObjectType.Image;
  }

  override getDefaultName(): string {
    return "Image";
  }
}

export type StylizationImageData = {
  id: number;
  imageUrl: string;
};
