<div class="input-container" [ngClass]="getCssClassList()" (click)="onFieldClick()">
  <div class="selected-option">
    <div class="name-group">
      @if(!selectedOption) {
      <ng-container *ngTemplateOutlet="newCardTemplate" />
      } @else {
      <img class="icon" [src]="selectedOption.icon" />
      <span>{{ selectedOption.value }}</span>
      }
    </div>

    <m-icon class="icon-arrow" [ngClass]="getArrowIconClassList()" src="dropdown-arrow-down-28.svg" size="original" />
  </div>
</div>

<div *ngIf="isMenuOpened" class="options-dropdown" [ngStyle]="{'height.px': optionsDropdownHeight}">
  <m-ng-scrollbars>
    <div class="options-wrapper">
      @if(selectedOption !== undefined) {
      <div class="option" (click)="onSelect(undefined)">
        <ng-container *ngTemplateOutlet="newCardTemplate" />
      </div>
      }

      <div class="option" *ngFor="let option of options; trackBy: trackByFn" (click)="onSelect(option)">
        <img class="icon" [src]="option.icon" />
        <span>{{ option.value }}</span>
      </div>
    </div>
  </m-ng-scrollbars>
</div>

<ng-template #newCardTemplate>
  <m-ng-icon class="icon" src="plus.svg" [size]="28" />
  <span i18n="@@payment-confirmation-modal.payment-method.new-card">
    payment-confirmation-modal.payment-method.new-card
  </span>
</ng-template>
