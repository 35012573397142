import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { Observable, Subject, takeUntil } from "rxjs";
import { CompanyImageGenerationRestrictedWordsSettings } from "../../models/company";
import { AdminCompanyImageGenerationRestrictedWordsSettingsService } from "../../services/image-generation-restricted-words-settings/companies-image-generation-restricted-words-settings.service";
import { AdminCompanyImageGenerationRestrictedWordsSettingsStore } from "../../services/image-generation-restricted-words-settings/companies-image-generation-restricted-words-settings.store";

@Component({
  selector: "m-admin-image-generation-restricted-words-settings-page",
  templateUrl: "./image-generation-restricted-words-settings.page.html",
  styleUrls: ["./image-generation-restricted-words-settings.page.scss"],
})
export class AdminCompanyImageGenerationRestrictedWordsSettingsPage implements AfterViewInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  protected settings$: Observable<CompanyImageGenerationRestrictedWordsSettings | undefined> | undefined;

  readonly form: FormGroup;

  constructor(
    private readonly service: AdminCompanyImageGenerationRestrictedWordsSettingsService,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly store: AdminCompanyImageGenerationRestrictedWordsSettingsStore,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.form = new FormBuilder().group({
      ru: new FormControl<string>("", { nonNullable: true, validators: [] }),
      en: new FormControl<string>("", { nonNullable: true, validators: [] }),
    });
  }

  async ngAfterViewInit() {
    await this.service.loadImageGenerationRestrictedWordsSettings();
    this.settings$ = this.store.getRestrictedWordsObservable();
    this.settings$!.pipe(takeUntil(this.destroyed$)).subscribe((settings) => {
      this.form.setValue({
        ru: this.convertWordsToString(settings?.ru),
        en: this.convertWordsToString(settings?.en),
      });
    });
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const data: CompanyImageGenerationRestrictedWordsSettings = {
      ru: this.converStringTotWords(this.form.value.ru),
      en: this.converStringTotWords(this.form.value.en),
    };

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.updateImageGenerationRestrictedWordsSettings(data);
    this.loadingService.stopLoading();

    if (result.status === "success") {
      // do nothing
    } else {
      // this.error.emit(result.error);
      this.notificationService.error("Cannot set settings");
      console.error("Cannot set settings");
    }
  }

  isDisabled() {
    return !this.form.valid;
  }

  private convertWordsToString(words: string[] | undefined): string {
    if (!words) {
      return "";
    }
    return words.join("\n");
  }

  private converStringTotWords(text: string): string[] {
    return text.replaceAll("<br>", "\n").split("\n");
  }
}
