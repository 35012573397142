import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { ImageGenerationStylizationUserStyle } from "../../models/image-generation-stylization-user-style/image-generation-stylization-user-style";

export type ImageGenerationStylizationUserStyleState = {
  stylizationUserStyles: ImageGenerationStylizationUserStyle[];
  stylizationUserStylesPageCount: number;
};

@Injectable({
  providedIn: "root",
})
export class ImageGenerationStylizationUserStyleStore extends Store<ImageGenerationStylizationUserStyleState> {
  protected override getInitialState(): ImageGenerationStylizationUserStyleState {
    return {
      stylizationUserStyles: [],
      stylizationUserStylesPageCount: 1,
    };
  }

  getStylizationUserStylesObservable() {
    return this.getFieldObservable("stylizationUserStyles");
  }

  getStylizationUserStyles() {
    return this.getField("stylizationUserStyles");
  }

  setStylizationUserStyles(stylizationUserStyles: ImageGenerationStylizationUserStyle[]) {
    this.update((state) => ({ ...state, stylizationUserStyles }));
  }

  getStylizationUserStylesPageCountObservable() {
    return this.getFieldObservable("stylizationUserStylesPageCount");
  }

  setStylizationUserStylesPageCount(count: number) {
    this.update((state) => ({ ...state, stylizationUserStylesPageCount: count }));
  }

  addStylizationUserStyle(stylizationUserStyle: ImageGenerationStylizationUserStyle) {
    this.update((state) => {
      const storeStylizationUserStyle = state.stylizationUserStyles.find((s) => s.id === stylizationUserStyle.id);
      if (storeStylizationUserStyle) {
        return state;
      }

      return {
        ...state,
        stylizationUserStyles: [stylizationUserStyle].concat(state.stylizationUserStyles),
      };
    });
  }

  addStylizationUserStylesToStart(items: ImageGenerationStylizationUserStyle[]) {
    this.update((state) => {
      const newStylizationUserStyles: ImageGenerationStylizationUserStyle[] = [];
      for (const item of items) {
        const storeStylizationUserStyle = state.stylizationUserStyles.find((s) => s.id === item.id);
        if (storeStylizationUserStyle) {
          continue;
        }
        newStylizationUserStyles.push(item);
      }

      return {
        ...state,
        stylizationUserStyles: newStylizationUserStyles.concat(state.stylizationUserStyles),
      };
    });
  }

  addStylizationUserStylesToEnd(items: ImageGenerationStylizationUserStyle[]) {
    this.update((state) => {
      const newStylizationUserStyles: ImageGenerationStylizationUserStyle[] = [];
      for (const item of items) {
        const storeStylizationUserStyle = state.stylizationUserStyles.find((s) => s.id === item.id);
        if (storeStylizationUserStyle) {
          continue;
        }
        newStylizationUserStyles.push(item);
      }

      return {
        ...state,
        stylizationUserStyles: state.stylizationUserStyles.concat(newStylizationUserStyles),
      };
    });
  }

  updateStylizationUserStyle(updatedStylizationUserStyle: ImageGenerationStylizationUserStyle) {
    const items = this.getStylizationUserStyles();
    const storeStylizationUserStyle = items.find((i) => i.id === updatedStylizationUserStyle.id);
    if (storeStylizationUserStyle) {
      this.update((state) => {
        const updatedStylizationUserStyles = state.stylizationUserStyles.map((s) => {
          if (s.id === updatedStylizationUserStyle.id) {
            return updatedStylizationUserStyle;
          }
          return s;
        });

        return {
          ...state,
          stylizationUserStyles: updatedStylizationUserStyles,
        };
      });
    } else {
      this.addStylizationUserStyle(updatedStylizationUserStyle);
    }
  }

  deleteStylizationUserStyle(id: number) {
    const items = this.getStylizationUserStyles();
    this.update((state) => {
      const updatedStylizationUserStyles = items.filter((i) => i.id !== id);
      return {
        ...state,
        stylizationUserStyles: updatedStylizationUserStyles,
      };
    });
  }
}
